import CONFIG from '../../config';
import { dmeApi } from '../../api/index.js';
import {
  transformDeliveryPacketResponse,
  transformDeliveryTicketResponse,
} from './dme.adapter.js';

const dmeEndpoints = dmeApi
  .enhanceEndpoints({
    addTagTypes: ['DeliveryTicket', 'DeliveryPacket'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getDeliveryTicket: builder.query({
        query: ({ productOrderId, deliveryTicketId }) =>
          CONFIG.entity.getDeliveryTicket(productOrderId, deliveryTicketId),
        transformResponse: transformDeliveryTicketResponse,
        providesTags: (result, error, arg) => [
          { type: 'DeliveryTicket', id: arg },
        ],
      }),
      getRequiredDocument: builder.query({
        query: (productOrderId) =>
          CONFIG.entity.getRequiredDocument(productOrderId),
        providesTags: (result, error, arg) => [
          { type: 'GetRequiredDocument', id: arg },
        ],
      }),
      updateRequiredDocument: builder.mutation({
        query: (data) => ({
          url: CONFIG.entity.getRequiredDocument(data?.productOrderId),
          method: 'PATCH',
          body: data,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: 'UpdateRequiredDocument', id: arg.productOrderId },
        ],
      }),
      updateDeliveryTicket: builder.mutation({
        query: ({ productOrderId, deliveryTicketId, data }) => ({
          url: CONFIG.entity.getDeliveryTicket(
            productOrderId,
            deliveryTicketId,
          ),
          method: 'PATCH',
          body: data,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: 'DeliveryTicket', id: arg.productOrderId },
        ],
      }),
      getDeliveryPacket: builder.query({
        query: (deliveryPacketId) =>
          CONFIG.entity.getDeliveryPacket(deliveryPacketId),
        transformResponse: transformDeliveryPacketResponse,
        providesTags: (result, error, arg) => [
          { type: 'DeliveryPacket', id: arg },
        ],
      }),
      shareDeliveryPacket: builder.mutation({
        query: (data) => ({
          url: CONFIG.entity.shareDeliveryPacketPdf(data?.productOrderId),
          method: 'POST',
          body: data,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: 'DeliveryPacket', id: arg.productOrderId },
        ],
      }),
      shareDeliveryTicket: builder.mutation({
        query: (data) => ({
          url: CONFIG.entity.shareDeliveryTicketPdf(data?.productOrderId),
          method: 'POST',
          body: data,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: 'DeliveryPacket', id: arg.productOrderId },
        ],
      }),
      verifyUser: builder.mutation({
        query: (data) => ({
          url: CONFIG.entity.verifyUser(data?.productOrderId),
          method: 'POST',
          body: data,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: 'DeliveryTicket', id: arg.productOrderId },
          { type: 'DeliveryPacket', id: arg.productOrderId },
        ],
      }),
      verifyStaff: builder.query({
        query: ({ contactId, productOrderId }) =>
          CONFIG.entity.verifyStaff(contactId, productOrderId),
      }),
    }),
  });

export const {
  useGetDeliveryTicketQuery,
  useGetRequiredDocumentQuery,
  useUpdateRequiredDocumentMutation,
  useUpdateDeliveryTicketMutation,
  useShareDeliveryPacketMutation,
  useShareDeliveryTicketMutation,
  useGetDeliveryPacketQuery,
  useVerifyUserMutation,
  useVerifyStaffQuery,
  useLazyVerifyStaffQuery,
  useLazyGetRequiredDocumentQuery,
  useLazyGetDeliveryTicketQuery,
  useLazyGetDeliveryPacketQuery,
} = dmeEndpoints;
