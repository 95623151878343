import { DayList } from 'constants/patientInfo';
import moment from 'moment';

/*
Takes a date object and returns in 'ddd, MMM D' format. 'Wed, Oct 8'
 */
export const dateFormatShort = (date) => {
  if (!date) return null;

  return moment(date).format('ddd, MMM D');
};

/*
Takes a date object and returns in 'dddd, MMMM D' format. 'Wednesday, October 8'
 */
export const dateFormatFull = (date) => {
  return moment(date).format('dddd, MMMM D');
};

/**
 * Convert date and time in UTC format to a javascript date object
 *
 * @param {string} date : Date and time in utc format.
 * @returns {object} javascript date object.
 */
export const convertUTCToDateObject = (date) => {
  if (!date) return null;
  let mm = moment.utc(date);
  return new Date(mm.format('MM/DD/YYYY'));
};

export const extractDateFromDateObject = (date) => {
  // 2021-03-09T08:00:00Z

  if (!date) return null;
  const dateInfo = date.split('T')[0];

  return moment(`${dateInfo}T00:00:00`).toDate();
};

export const extractTimeFromDateObject = (date) => {
  if (!date) return null;
  const timeInfo = date.split('T')[1].substring(0, 5);

  return `${timeInfo}:00`;
};

export const getReportDate = (date) => {
  if (!date) return null;
  const rawDate = date.split('T')[0];
  return moment(`${rawDate}T00:00:00`).local('es').format('Do MMM YYYY');
};

export const convertDateAndTimeToUTC = (date, time) => {
  const momentDate = moment(date).format('DD/MM/YYYY');
  const momentTime = time.split('T')[1]?.substring(0, 8) || time;
  return moment
    .utc(momentDate + ' ' + momentTime, 'DD/MM/YYYY HH:mm:ss')
    .format();
};
/*
If number is < 10, prepend 0, e.g. 9 => 09
 */
const dateToTwoDigit = (number) => {
  return number < 10 ? '0' + number : number;
};

export function extractTimeFromDateString(dateTime) {
  if (!dateTime) return null;

  const dateTimeWithOutTimezone = dateTime.replace('Z', '');

  return moment(dateTimeWithOutTimezone).format('hh:mm a');
}

/**
 * Convert a time in 24 hour format to 12 hour format with am/pm.
 * eg: 13:40 --> 1:40.
 *
 * @param time - The time in 24 hour format.
 * @returns string in 12 hour time format with AM/PM
 */
export function convertTo12HourTimeFormatWithAmPm(time) {
  if (!time) return null;

  return moment(time, 'HH:mm:ss').format('hh:mm a');
}

/**
 * Convert a date object to standard date string 'YYYY-MM-DD'
 *
 * @param {object} dateObj : Date object
 */
export const toStandardDateFormat = (dateObj) => {
  return moment(dateObj).format('YYYY-MM-DD');
};

/**
 * Convert a date to format 'DD/MM/YYYY' 
 *
 * @param {object} date : Date
 */
export const formatDateToDDMMYYYYFormat = (date) => {
  return moment(date).format('DD/MM/YYYY');
};

/**
 * Convert 24 hour format to 12 hour format.
 *
 * @param {string} time : Timr format in 24 hour (19:00)
 * @param {string} Time in 12 hour format.
 */
export function convertTo12HourTimeFormat(time) {
  if (!time) return null;

  const timeParts = time.split(':');
  const hour = parseInt(timeParts[0]);

  if (hour < 12) return time + 'AM';

  if (hour === 12) return time + 'PM';

  return `${hour - 12}:${timeParts[1].substring(0, 2)}PM`;
}

/**
 * Convert 12 hour format to 24 hour.
 *
 * @param {string} time: Time in 24 hour format.
 */
export function convertTo24HourTimeFormat(time) {
  if (!time) return null;
  const hour = moment(time).hour();
  const min = moment(time).minutes();
  const startTimeOfDay = time.match(/AM|PM|am|pm/i)[0].toUpperCase();

  if (startTimeOfDay === 'AM' && hour < 12) return `${hour}:${min}`;

  if (startTimeOfDay === 'AM' && hour === 12) return `${hour - 12}:${min}`;

  if (hour === 12) return `${hour}:${min}`;

  return `${hour + 12}:${min}`;
}

/**
 * Calculates end time on the basis of start time and duration.
 *
 * @param {string} startTime : The start time in format (HH:MM(AM/PM))
 * @param {integer} duration : Duration to add.
 * @returns {string} calculatedTime error
 */
function addTime(startTime, duration) {
  startTime = startTime.replace(/\s/g, '');

  // NOTE: Extract hour, minute and time of day.
  const startHour = parseInt(startTime.split(':')[0]) % 12;
  const startMin = parseInt(startTime.split(':')[1].substring(0, 2));
  const startTimeOfDay = startTime.match(/AM|PM|am|pm/i)[0].toUpperCase();

  // NOTE: Convert to 24 hours
  const startTimeIn24HourMin =
    startTimeOfDay === 'AM'
      ? startHour * 60 + startMin
      : (startHour + 12) * 60 + startMin;
  const durationInMin = duration;

  const endTimeIn24HourMin = startTimeIn24HourMin + durationInMin;

  // NOTE: Convert back to HH:MM format
  const endHours = parseInt((endTimeIn24HourMin / 60) % 12);
  const endMin = parseInt(
    endTimeIn24HourMin - parseInt(endTimeIn24HourMin / 60) * 60,
  );

  const amOrPMTIme = (endTimeIn24HourMin / 60) % 24;

  return `${endHours === 0 ? '12' : endHours} : ${endMin < 10 ? `0${endMin}` : endMin}${
    endHours === 0 && endMin === 0
      ? startTimeOfDay === 'AM'
        ? 'PM'
        : 'AM'
      : amOrPMTIme > 12
        ? 'PM'
        : 'AM'
  }`.replace(/\s/g, '');
}

/**
 * Convert a date object to slash date string 'MM/DD/YYYY'
 *
 * @param {object} dateObj : Date object
 */
export const toSlashFormat = (dateObj) => {
  return moment(dateObj).format('MM/DD/YYYY');
};

/**
 * Check patient is over 18 years
 *
 * @param {object} dateObj :
 */
export const isUnderNineteen = (date) => {
  const yearsDiff = moment().diff(moment(date, 'MM-DD-YYYY'), 'years');

  if (yearsDiff < 19) {
    return true;
  }

  return false;
};

/**
 * Check if the patient is under predefined minimum age
 *
 * @param {object} data
 * @returns boolean
 */
export const isUnderMinimumAge = (date, minimumAge) => {
  if (!minimumAge) return false;

  const yearsDiff = moment().diff(moment(date, 'MM-DD-YYYY'), 'years');
  if (yearsDiff < minimumAge) {
    return true;
  }

  return false;
};

/**
 * Check if the patient is above predefined minimum age
 *
 * @param {object} data
 * @returns boolean
 */
export const isAboveMaximumAge = (date, maximumAge) => {
  if (!maximumAge) return false;

  const yearsDiff = moment().diff(moment(date, 'MM-DD-YYYY'), 'years');

  if (yearsDiff > maximumAge) {
    return true;
  }

  return false;
};

/**
 * Check patient is under 18 years
 *
 * @param {object} dateObj :
 */
export const isUnderEighteen = (date) => {
  const yearsDiff = moment().diff(moment(date, 'MM-DD-YYYY'), 'years');

  if (yearsDiff < 18) {
    return true;
  }

  return false;
};

/**
 * Check patient is under 18 years
 *
 * @param {object} dateObj :
 */
export const checkAgeEligibility = (date, age) => {
  const yearsDiff = moment().diff(moment(date, 'MM-DD-YYYY'), 'years');
  if (yearsDiff >= age) {
    return true;
  }
  return false;
};

/**
 *
 * @param {string} date 'MM/DD/YYYY' Date to be compared with
 */
const YearDiffFromNow = (date) => {
  const diff = moment().diff(moment(date, 'MM/DD/YYYY'));
  const duration = moment.duration(diff);
  const diffYear = duration.years();
  return diffYear;
};

/**
 * Takes a date object and returns in 'D MM' format. '8 Oct'
 *
 * @param {object} date
 */
export function toDayAndMonth(date) {
  return moment(date).format('D MMM');
}

export function isLeapYear(year) {
  if (!year) return false;
  if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
    return true;
  }
  return false;
}

export function getDifferenceBetweenDates(dateString1, dateString2) {
  const firstDate = moment(dateString1.split('T')[0]);

  const secondDate = moment(dateString2.split('T')[0]);

  return secondDate.diff(firstDate, 'days');
}

export const setDateTimeLocale = (languageCode) => moment.locale(languageCode);

export const getMinDateForCalendar = (dates) => {
  const smallestDate = dates.sort((a, b) => a - b)[0];
  const dateToConvert = !!smallestDate ? smallestDate : new Date();

  // React date is not inclusive of the min date so need to reduce date by one second
  return moment(dateToConvert).add(-1, 'seconds').toDate();
};

//function to append 0 before single digit minute and second
export const getTimeFromMinuteAndSeconds = (minute, second) => {
  let formattedSecond = second,
    formattedMinute = minute;

  if (second.toString().length === 1) formattedSecond = `0${second}`;

  if (minute.toString().length === 1) formattedMinute = `0${minute}`;

  return `${formattedMinute}:${formattedSecond}`;
};

export const getDaysInMonth = (birthMonth, birthYear) => {
  const TwentyNineDayMonths = ['2'];
  const ThirtyDayMonths = ['4', '6', '9', '11'];
  if (TwentyNineDayMonths.includes(birthMonth)) {
    if (!isLeapYear(birthYear)) {
      return DayList.slice(0, 28);
    }
    return DayList.slice(0, 29);
  } else if (ThirtyDayMonths.includes(birthMonth)) {
    return DayList.slice(0, 30);
  }
  return DayList;
};

function getAgeFromDate(birthDay, birthMonth, birthYear) {
  const today = new Date();
  const age =
    today.getFullYear() -
    birthYear -
    (today.getMonth() < birthMonth ||
      (today.getMonth() === birthMonth && today.getDate() < birthDay));
  return age;
}

/**
 * Formats a birth date into a string in the format "YYYY-MM-DD".
 *
 * @param {number} birthDay - The day of the birth date. Example: 22
 * @param {number} birthMonth - The month of the birth date. Example: 4
 * @param {number} birthYear - The year of the birth date. Example: 1990
 * @returns {string} The formatted birth date string. Example 1990-4-22
 */
export const formatBirthDate = (birthDay, birthMonth, birthYear) => {
  // Pad single-digit day and month with leading zero if necessary
  const day = birthDay < 10 ? '0' + birthDay : birthDay;
  const month = birthMonth < 10 ? '0' + birthMonth : birthMonth;

  // Concatenate the components to form the date string
  const dateString = `${birthYear}-${month}-${day}`;

  return dateString;
};

export const getDisplayDate = (date = new Date()) =>
  new Date(date).toLocaleDateString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

// Desired format: Sun, January 7, 2024 at 8:10 PM
export const formatDateAndTimeToLongMonthShortDay = (date = new Date()) => {
  const dateObj = new Date(date);

  const options = {
    weekday: 'short',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  };
  return new Intl.DateTimeFormat('en-US', options).format(dateObj);
};

export const formatDateToShortMonthNumericDay = (date = new Date()) => {
  const dateObj = new Date(date);

  const options = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  };

  // Use toLocaleDateString for desired format with ordinal indicator
  return dateObj.toLocaleDateString('en-US', options);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  addTime,
  dateFormatShort,
  dateFormatFull,
  toStandardDateFormat,
  convertUTCToDateObject,
  convertTo12HourTimeFormat,
  convertTo24HourTimeFormat,
  dateToTwoDigit,
  toSlashFormat,
  YearDiffFromNow,
  extractTimeFromDateString,
  isUnderNineteen,
  isUnderEighteen,
  checkAgeEligibility,
  isUnderMinimumAge,
  extractDateFromDateObject,
  isLeapYear,
  getMinDateForCalendar,
  isAboveMaximumAge,
  getAgeFromDate,
  formatBirthDate,
  getDisplayDate,
};
