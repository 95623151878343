import { getString } from 'util/lang';
import { supplierStandards } from '../../../constants/supplierStandard';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import LineBreakPdfView from '../../common/LineBreak/LineBreakPdfView';
import FormHeaderPdfView from '../../common/FormHeader/FormHeaderPdfView';
import PharmacyInfoPdfView from '../../common/PharmaInfo/PharmaInfoPdfView';
import { printFontFamily } from 'constants/dme';

const LABEL_PREFIX = 'dme.deliveryPacketForm.supplierStandard';

const SupplierStandardPdfView = ({ organizationDetail }) => {
  const { organizationName, organizationContactPhone, address } =
    organizationDetail;

  return (
    <View style={styles.standardWrap}>
      <View style={{ marginTop: 12 }} />
      <FormHeaderPdfView text={getString(`${LABEL_PREFIX}.title`)} />

      <PharmacyInfoPdfView
        name={organizationName}
        address={address}
        contact={organizationContactPhone}
      />

      <Text style={{ ...styles.p, marginTop: 15 }}>
        {getString(`${LABEL_PREFIX}.note`)}
      </Text>
      <View style={styles.standardUl}>
        {supplierStandards.map((supplierStandard, key) => (
          <View key={key} style={styles.standardLi}>
            <Text style={styles.p}>{key + 1}. </Text>
            <Text style={styles.p}>{supplierStandard}</Text>
          </View>
        ))}
      </View>
      <LineBreakPdfView />
      <View style={styles.footer}>
        <Text style={{ ...styles.p, fontWeight: 600, color: '#323130' }}>
          {getString(`${LABEL_PREFIX}.organization.name`)}
        </Text>
        <Text style={styles.p}>
          {getString(`${LABEL_PREFIX}.organization.label`)}
        </Text>
        <Text style={styles.p}>
          {getString(`${LABEL_PREFIX}.organization.address`)}
        </Text>
        <Text style={{ ...styles.p, fontWeight: 600, color: '#323130' }}>
          {getString(`${LABEL_PREFIX}.organization.designation`)}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  standardWrap: {
    display: 'flex',
    flexDirection: 'column',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  h3: {
    fontSize: 9,
    fontWeight: 700,
    fontFamily: printFontFamily,
    marginBottom: 5,
    marginTop: 13,
  },
  p: {
    fontSize: 9,
    fontWeight: 400,
    fontFamily: printFontFamily,
    color: '#323130',
    marginBottom: 4,
  },
  standardUl: {
    marginTop: 15,
    marginRight: 15,
  },
  standardLi: {
    display: 'flex',
    flexDirection: 'row',
  },
});

export default SupplierStandardPdfView;
