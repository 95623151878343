import { getString } from '../../../../../../util/lang';
import AlertMessage from '../../../../common/AlertMessage';
import PaymentType from '../../../PaymentInfo/presentation/PatientInsurance';
import InsuranceInput from '../../../PaymentInfo/presentation/InsuranceInput';
import NoInsuranceForm from '../../../PaymentInfo/presentation/NoInsuranceForm';
import PayAtTimeOfService from '../../../PaymentInfo/presentation/PayAtTimeOfServiceInfoBox';
import React from 'react';
import cls from 'classnames';

const InsurancePayment = (props) => {
  const {
    paymentType,
    insuranceType,
    errors,
    isValidateInsuranceEligibilitySet,
    localizedWebContents,
    enabledPaymentOptions,
    availableInsuranceTypes,
    isInsurancePayment,
    payerlist = [],
    patientInfo,
    insuranceEditParam,
    insuranceInfo,
    isInsuranceEligibilitySuccess,
    isInsuranceEligible,
    selectedPayer,
    isNoInsurancePayment,
    noInsuranceInfo,
    isPayAtTimeOfServicePayment, 
    organizationObj,
    setFieldError,
    onInsuranceTypeChange,
    selectedServices,
    insuranceValidationPayload,
    validateInsuranceEligibilityOfAppointment,
    previousValidationPayload,
    onInsuranceInfoChange,
    isInsuranceValidationLoading,
    onPaymentTypeChange,
    inputClassName = '',
  } = props;

  return (
    <div className={cls('ml-7x pt-3x mt-3x', inputClassName)}>
      {organizationObj?.isPrepaidByOrganization ? (
        <AlertMessage
          type="info"
          message={getString('prepaidByOrganization', organizationObj.name)}
          isVisible={true}
          dataqa="prepaid-by-org-alert"
        />
      ) : (
        <>
          <PaymentType
            onChange={onPaymentTypeChange}
            value={paymentType}
            insuranceType={insuranceType}
            onInsuranceTypeChange={onInsuranceTypeChange}
            errors={errors}
            isValidateInsuranceEligibilitySet={
              isValidateInsuranceEligibilitySet
            }
            localizedWebContents={localizedWebContents}
            isInsuranceEnabled={enabledPaymentOptions?.insurance}
            noInsuranceEnabled={enabledPaymentOptions?.noInsurance}
            payAtTimeOfServiceEnabled={
              enabledPaymentOptions?.payAtTimeOfService
            }
            availableInsuranceTypes={availableInsuranceTypes}
          />

          {isInsurancePayment && insuranceType && (
            <InsuranceInput
              onChange={onInsuranceInfoChange}
              payerlist={payerlist}
              insuranceInfo={insuranceInfo}
              insuranceType={insuranceType}
              patientInfo={patientInfo}
              errors={errors}
              setFieldError={setFieldError}
              isInsuranceValidationLoading={isInsuranceValidationLoading}
              insuranceEditParam={insuranceEditParam}
              isInsuranceEligibilitySuccess={isInsuranceEligibilitySuccess}
              isValidateInsuranceEligibilitySet={
                isValidateInsuranceEligibilitySet
              }
              isInsuranceEligible={isInsuranceEligible}
              insuranceValidationPayload={insuranceValidationPayload}
              validateInsuranceEligibilityOfAppointment={
                validateInsuranceEligibilityOfAppointment
              }
              previousValidationPayload={previousValidationPayload}
              selectedPayer={selectedPayer}
              selectedServices={selectedServices}
            />
          )}
          {isNoInsurancePayment && (
            <NoInsuranceForm
              onChange={onInsuranceInfoChange}
              noInsuranceInfo={noInsuranceInfo}
              errors={errors}
            />
          )}

          {isPayAtTimeOfServicePayment && (
            <PayAtTimeOfService selections={selectedServices} />
          )}
        </>
      )}
    </div>
  );
};

export default InsurancePayment;
