import React from 'react';
import DatePicker from 'react-datepicker';
import { getString } from 'util/lang';

const DateInput = (props) => {
  const {
    id,
    value,
    required = false,
    disabled = false,
    handleDateChange,
    hasError = false,
    isVisible = true,
    solo = false,
    label,
    fullWidth = false,
    className,
    wrapperClassName,
    extraComponent,
  } = props;
  const displayStyle = isVisible
    ? { display: 'block', width: '100%' }
    : { display: 'none' };

  // Disallow keyboard input
  const disableAction = (e) => {
    e.preventDefault();
  };

  return (
    <div
      className={`form-group${hasError ? ' has-error' : ''} ${wrapperClassName ? wrapperClassName : ''} calendar calendar-v2 mb-0x separate-label`}
      style={displayStyle}
    >
      <form
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <label className={'d-block mb-2x h5 text-demibold'} htmlFor={id}>
          {label}
        </label>
        <div className="row">
          <div className={`col-${fullWidth ? '12' : '6'}-md`}>
            <DatePicker
              id={id}
              name={id}
              selected={value && new Date(value)}
              maxDate={new Date()}
              className={`form-control ${className ? className : ''} ${solo ? 'solo' : ''} ${fullWidth ? 'w-full' : ''}`}
              onChange={(date) => handleDateChange(id, date)}
              onChangeRaw={(e) => disableAction(e)}
              disabledKeyboardNavigation={true}
              placeholder={getString('enterResponseText')}
              wrapperClassName="w-full"
              disabled={disabled}
            />
            {!!extraComponent && extraComponent}
          </div>
        </div>
      </form>
    </div>
  );
};

export default DateInput;
