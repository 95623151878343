import { getDisplayDate } from 'util/DateAndTime';
import { adaptOrders } from './orders.adapter';
import { joinValues } from 'util/string';

/**
 * Extracts the effective date from a list of order items.
 * Returns delivery date if exist, else shipping date is returned. if none exist, then today's date is returned
 * If multiple date exists, earliest one is preferred in each individual type.
 *
 * @param {Array} orderItems - The list of order items.
 * @returns {string} The effective date in the format "MM/DD/YYYY".
 */
const extractEffectiveDate = (orderItems = []) => {
  const { deliveryDates, shippingDates } = orderItems.reduce(
    (acc, orderItem) => {
      const { deliveryDate, shippingDate } = orderItem;

      if (deliveryDate) acc.deliveryDates.push(deliveryDate);
      if (shippingDate) acc.shippingDates.push(shippingDate);

      return acc;
    },
    { deliveryDates: [], shippingDates: [] },
  );

  if (deliveryDates.length) {
    // Sort the delivery dates to find the earliest one
    const sortedDeliveryDates = deliveryDates.sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime(),
    );
    return getDisplayDate(sortedDeliveryDates[0]); // Return the earliest date in the desired display format
  } else if (shippingDates.length) {
    // Sort the shipping dates to find the earliest one
    const sortedShippingDates = shippingDates.sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime(),
    );
    return getDisplayDate(sortedShippingDates[0]); // Return the earliest date in the desired display format
  }

  return getDisplayDate(); // If no dates are found, return today's date
};

export const adaptResponse = (response) => {
  const { deliveryTicket, productOrder, orderItems, contact, deliveryInfo } =
    response || {};

  const {
    homeAssessmentData,
    questionnaireData,
    documentsData,
    settingAndTestingData,
    consent,
    individualResponsibleSignature,
    setupSignatureData,
    proofOfDeliveryData,
  } = JSON.parse(deliveryTicket.deliveryTicketFormData || '{}');

  const {
    contactFirstName,
    contactMiddleName,
    contactLastName,
    contactBirthDate,
    contactMobilePhone,
  } = contact;

  const orderItemData = adaptOrders(orderItems);

  const {
    deliveryCity,
    deliveryCounty,
    deliveryState,
    deliveryStreetName,
    deliveryZipCode,
  } = deliveryInfo || {};

  return {
    patientData: {
      name: joinValues([contactFirstName, contactMiddleName, contactLastName]),
      dob: contactBirthDate,
      phone: contactMobilePhone,
    },
    orderItemData,
    homeAssessmentData,
    questionnaireData,
    documentsData,
    settingAndTestingData,
    consent,
    individualResponsibleSignature,
    setupSignatureData,
    proofOfDeliveryData,
    productOrder,
    deliveryInfo: {
      ...deliveryInfo,
      deliveryDate:
        deliveryInfo?.deliveryDate &&
        getDisplayDate(deliveryInfo?.deliveryDate),
      deliveryAddress: joinValues(
        [
          deliveryStreetName,
          deliveryCity,
          deliveryState,
          deliveryCounty,
          deliveryZipCode,
        ],
        ', ',
      ),
    },
  };
};
