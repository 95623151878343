import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { toast } from 'util/toast';
import { getString } from 'util/lang';
import {
  useGetDeliveryPacketQuery,
  useShareDeliveryPacketMutation,
} from 'redux/dme/dme.api';
import { useAppSettings } from 'context';
import { useContactId } from '../hooks/useContactId';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import { adaptOrdersForRequestBody } from '../adapters/orders.adapter';

import { ConfirmModal } from 'components/common/ConfirmModal';
import FullPageLoader from 'components/common/FullPageLoader';
import DmeHeader from '../presentation/common/DmeHeader/DmeHeader';
import ActionButtons from '../presentation/common/ActionButtons/ActionButtons';
import { NoticeMessage } from '../presentation/common/NoticeMessage/NoticeMessage';
import AuthorizationForm from '../presentation/deliveryPacket/AuthorizationForm/AuthorizationForm';
import EducationBooklet from '../presentation/deliveryPacket/EducationBooklet/EducationBooklet';
import SupplierStandard from '../presentation/deliveryPacket/SupplierStandard/SupplierStandard';
import OperatingInstruction from '../presentation/deliveryPacket/OperatingInstruction/OperatingInstruction';
import BillOfRightsAndResponsibilities from '../presentation/deliveryPacket/BillOfRightsAndResponsibilities';
import NoticeOfPrivacyPractices from '../presentation/deliveryPacket/NoticeOfPrivacyPractices/NoticeOfPrivacyPractices';
import GeneralHomeSafetyInformation from '../presentation/deliveryPacket/GeneralHomeSafetyInformation/GeneralHomeSafetyInformation';

import { removeAllEmptyFieldValuesForObject } from 'util/objectUtils';
import { joinValues } from 'util/string';
import { getErrorMessage } from 'util/errorMessageHandler';
import DeliveryPacketPdfView from './DeliveryPacketPdfView';
import { downloadFile, generatePdfBlob } from 'util/printPdf';
import {
  BeneficiaryRelation,
  BeneficiaryRelationValue,
} from 'constants/productOrder';

const LABEL_PREFIX = 'dme.deliveryPacketForm';

const DeliveryPacketPage = ({ isEdit }) => {
  const params = useParams();
  const productOrderId = params?.productOrderId;

  const {
    organizationName,
    organizationContactPhone,
    organizationContactEmail,
    billingAddress,
    billingCity,
    billingState,
    billingZipCode,
    logoUrl,
  } = useAppSettings();

  const organizationDetail = {
    organizationName,
    organizationContactPhone,
    address: joinValues(
      [billingAddress, billingCity, billingState, billingZipCode],
      ', ',
    ),
  };
  const nav = useNavigationHelper();

  const {
    data: deliveryPacketData,
    isError,
    error,
    isLoading: isGetDeliveryPacketLoading,
  } = useGetDeliveryPacketQuery(productOrderId);

  const [shareDeliveryPacketPdf, { isLoading: isSharePdfLoading }] =
    useShareDeliveryPacketMutation(productOrderId);

  const [resendPdfModal, setResendPdfModal] = useState(false);
  const [isAlreadyShared, setIsAlreadyShared] = useState(false);
  const [deliveryPacketGenerated, setDeliveryPacketGenerated] = useState(false);
  const [modifiedDeliveryPacketData, setModifiedDeliveryPacketData] = useState({
    tncSignature: '',
    tncDate: '',
    tncAddress: '',
    tncReasonUnableToSign: '',
    tncFullName: '',
    tncRelation: BeneficiaryRelation.find(
      (data) => data.value === BeneficiaryRelationValue.SELF,
    )?.value,
  });
  const [formErrors, setFormErrors] = useState({});

  const inputChangeHandler = (key, value) => {
    setModifiedDeliveryPacketData((prev) => ({
      ...prev,
      [key]: value,
    }));
    setFormErrors((prev) => ({
      ...prev,
      [key]: '',
    }));
  };

  useEffect(() => {
    if (!deliveryPacketData) return;

    setIsAlreadyShared(
      deliveryPacketData.orderItemData?.every(
        (item) => item?.deliveryPacketSharedAt,
      ),
    );
    setDeliveryPacketGenerated(
      deliveryPacketData.orderItemData?.every(
        (item) => item?.deliveryPacketGenerated,
      ),
    );

    setModifiedDeliveryPacketData((prev) => ({
      ...prev,
      ...removeAllEmptyFieldValuesForObject(deliveryPacketData),
    }));
  }, [deliveryPacketData]);

  const handlePrint = async () => {
    try {
      const blob = await generatePdfBlob(
        <DeliveryPacketPdfView
          productOrderId={productOrderId}
          deliveryPacketData={modifiedDeliveryPacketData}
          logoUrl={logoUrl}
          organizationName={organizationName}
          organizationContactPhone={organizationContactPhone}
          billingAddress={billingAddress}
          billingCity={billingCity}
          billingState={billingCity}
          billingZipCode={billingZipCode}
        />,
      );
      downloadFile(blob, 'Delivery-packet.pdf');
    } catch (error) {
      toast.error({
        title: '',
        message: `Error downloading Delivery-packet.pdf: ${error.message}`,
      });
    }
  };

  const handleSharePdf = async () => {
    try {
      const { orderItemData } = deliveryPacketData;

      const updatedDeliveryData = {
        productOrderId: productOrderId,
        orderItems: adaptOrdersForRequestBody(orderItemData),
      };

      if (isAlreadyShared && !resendPdfModal) {
        setResendPdfModal(true);
        return;
      }

      await shareDeliveryPacketPdf(updatedDeliveryData).unwrap();

      toast.success({
        title: '',
        message: getString(
          'dmeDeliveryPdfSharedSuccess',
          getString(`${LABEL_PREFIX}.title`),
        ),
      });
      nav.dme.deliveryPacket.view(productOrderId);
    } catch (error) {
      toast.error({
        title: '',
        message: getString(
          'dmeDeliveryPdfSharedFail',
          getString(`${LABEL_PREFIX}.title`),
        ),
      });
    }
  };

  if (isError) {
    return (
      <NoticeMessage
        title={getString(`${LABEL_PREFIX}.title`)}
        message={getErrorMessage(
          error.status,
          getString(`${LABEL_PREFIX}.title`),
        )}
        contactEmail={organizationContactEmail}
        contactPhone={organizationContactPhone}
      />
    );
  }

  if (isGetDeliveryPacketLoading) {
    return <FullPageLoader />;
  }

  return (
    <div className="delivery-packet--container">
      <DmeHeader
        isEdit={isEdit}
        headerText={getString(`${LABEL_PREFIX}.title`)}
        handlePrint={handlePrint}
        handleSharePdf={deliveryPacketGenerated && handleSharePdf}
        isSharePdfLoading={isSharePdfLoading}
      />
      <div className="group-container">
        <AuthorizationForm
          isEdit={false}
          organizationDetail={organizationDetail}
          authorizationFormData={modifiedDeliveryPacketData}
          formErrors={formErrors}
          inputChangeHandler={inputChangeHandler}
        />
        <BillOfRightsAndResponsibilities
          organizationDetail={organizationDetail}
        />

        <NoticeOfPrivacyPractices
          noticeOfPrivacyPracticesData={{
            effectiveDate: deliveryPacketData?.effectiveDate,
            organizationDetail: organizationDetail,
          }}
        />

        <EducationBooklet
          educationBookletData={{
            organizationDetail: organizationDetail,
            clinicDuration: modifiedDeliveryPacketData?.clinicDuration,
          }}
        />

        <GeneralHomeSafetyInformation organizationDetail={organizationDetail} />

        <SupplierStandard />
        <OperatingInstruction />

        <ActionButtons
          handlePrint={handlePrint}
          handleSave={false}
          isEnabled={!formErrors?.hasError}
        />
        {resendPdfModal && (
          <ConfirmModal
            show={resendPdfModal}
            onCancel={() => {
              setResendPdfModal(false);
              return;
            }}
            onConfirm={() => {
              handleSharePdf();
              setResendPdfModal(false);
            }}
            headerContent={getString(
              'dmeDeliveryResendTitle',
              getString(`${LABEL_PREFIX}.title`),
            )}
            bodyContent={
              <div className="mb-8x">
                {getString(
                  'dmeDeliveryResendContent',
                  getString(`${LABEL_PREFIX}.title`),
                )}
              </div>
            }
            cancelText={getString('no')}
            confirmText={getString('yes')}
          />
        )}
      </div>
    </div>
  );
};

export const ViewDeliveryPacketPage = () => {
  const { isStaff } = useContactId();
  return <DeliveryPacketPage isEdit={isStaff} />;
};
export const EditDeliveryPacketPage = () => (
  <DeliveryPacketPage isEdit={true} />
);
