import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { getString } from 'util/lang';
import FormHeaderPdfView from '../../common/FormHeader/FormHeaderPdfView';
import PharmacyInfoPdfView from '../../common/PharmaInfo/PharmaInfoPdfView';
import { printFontFamily } from 'constants/dme';

const LABEL_PREFIX = 'dme.deliveryPacketForm.educationBooklet';

const EducationBookletPdfView = ({ organizationDetail, clinicDuration }) => {
  const { organizationName, organizationContactPhone, address } =
    organizationDetail;

  const contents = JSON.parse(
    getString(`${LABEL_PREFIX}.contents`, {
      companyName: organizationName,
      companyAddress: address,
      hoursOfOperation: `${clinicDuration}`,
      companyPhone: organizationContactPhone,
    }),
  );

  return (
    <View style={styles.container}>
      <View style={{ marginTop: 12 }} />
      <FormHeaderPdfView text={getString(`${LABEL_PREFIX}.title`)} />
      <PharmacyInfoPdfView
        name={organizationName}
        address={address}
        contact={organizationContactPhone}
      />

      <Text
        style={{
          ...styles.h3,
          marginTop: 20,
          fontWeight: 700,
        }}
      >
        {getString(`${LABEL_PREFIX}.description`)}
      </Text>

      <View>
        {contents.map(({ title, description }, idx) => (
          <View key={idx}>
            <Text style={styles.h3}>{title}</Text>
            <Text style={styles.p}>{description}</Text>
          </View>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  h3: {
    fontSize: 9,
    fontWeight: 700,
    fontFamily: printFontFamily,
    marginBottom: 5,
    marginTop: 13,
  },
  p: {
    fontSize: 9,
    fontWeight: 400,
    fontFamily: printFontFamily,
    color: '#323130',
  },
});

export default EducationBookletPdfView;
