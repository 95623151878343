import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';

import { getString } from 'util/lang';
import { iconCheckboxChecked, iconCheckboxUnchecked } from 'assets/images';
import DMESignaturePdfView from '../../common/DMESignature/DMESignaturePdfView';
import { BeneficiaryRelation } from 'constants/productOrder';

const LABEL_PREFIX = 'dme.deliveryTicket.proofOfAcknowledgement';

const ProofOfDeliveryAcknowledgementPdfView = ({
  proofOfDeliveryData,
  consent,
}) => {
  return (
    <View style={styles.container}>
      <View style={styles.headerWrap}>
        <Text style={styles.headerText}>
          {getString(`${LABEL_PREFIX}.title`)}
        </Text>
      </View>
      <Text style={styles.text}>
        {getString(`${LABEL_PREFIX}.description`)}
      </Text>
      <View style={styles.listWrap}>
        <View style={styles.list}>
          <View style={{ marginLeft: 10, marginBottom: 4, marginRight: 4 }}>
            {proofOfDeliveryData?.checklist?.find(
              (data) => data.key === 'equipmentProperlyFitted',
            )?.checked ? (
              <Image
                src={iconCheckboxChecked}
                alt="checkbox-checked"
                style={styles.img}
              />
            ) : (
              <Image
                src={iconCheckboxUnchecked}
                alt="checkbox-checked"
                style={styles.img}
              />
            )}
          </View>
          <Text style={styles.text}>
            {getString(`${LABEL_PREFIX}.listFirst`)}
          </Text>
        </View>
        <View style={styles.list}>
          <View style={{ marginLeft: 10, marginBottom: 4, marginRight: 4 }}>
            {proofOfDeliveryData?.checklist?.find(
              (data) => data.key === 'receivedWrittenInstruction',
            )?.checked ? (
              <Image
                src={iconCheckboxChecked}
                alt="checkbox-checked"
                style={styles.img}
              />
            ) : (
              <Image
                src={iconCheckboxUnchecked}
                alt="checkbox-checked"
                style={styles.img}
              />
            )}
          </View>
          <Text style={styles.text}>
            {getString(`${LABEL_PREFIX}.listSecond`)}
          </Text>
        </View>
        <View style={styles.list}>
          <View style={{ marginLeft: 10, marginBottom: 4, marginRight: 4 }}>
            {proofOfDeliveryData?.checklist?.find(
              (data) => data.key === 'understandReturnsPolicy',
            )?.checked ? (
              <Image
                src={iconCheckboxChecked}
                alt="checkbox-checked"
                style={styles.img}
              />
            ) : (
              <Image
                src={iconCheckboxUnchecked}
                alt="checkbox-checked"
                style={styles.img}
              />
            )}
          </View>
          <Text style={styles.text}>
            {getString(`${LABEL_PREFIX}.listThird`)}
          </Text>
        </View>
      </View>
      <View style={styles.recipientDetails}>
        <Text style={styles.recipientText}>
          {getString(`${LABEL_PREFIX}.recipientName`)}:{' '}
          <Text style={styles.recipientName}>
            {proofOfDeliveryData?.recipientName}
          </Text>
        </Text>
        <Text style={styles.recipientText}>
          {getString(`${LABEL_PREFIX}.relationshipToBeneficiary.label`)}:{' '}
          <Text style={styles.recipientName}>
            {BeneficiaryRelation.find(
              (data) =>
                data.value === proofOfDeliveryData?.relationshipToBeneficiary,
            )?.label ?? ''}
          </Text>
        </Text>
        <Text style={styles.recipientText}>
          {getString(`${LABEL_PREFIX}.reasonBeneficiaryIsUnableToSign.label`)}:{' '}
          <Text style={styles.recipientName}>
            {proofOfDeliveryData?.reasonBeneficiaryIsUnableToSign}
          </Text>
        </Text>
      </View>
      <View break></View>
      <DMESignaturePdfView
        title={getString(`${LABEL_PREFIX}.signature`)}
        style={{
          marginTop: 15,
        }}
        signature={consent?.signature}
        signatureImage={consent?.proofOfDeliverySignature}
        signedDate={consent?.signedDate}
        showDate={true}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 15,
  },
  headerWrap: {
    padding: '8px 11px 8px 11px',
    border: '1px solid #E1DFDD',
    backgroundColor: '#FAF9F8',
    marginBottom: 8,
  },
  headerText: {
    fontSize: 13,
    fontWeight: 600,
    color: '#323130',
    textAlign: 'center',
  },
  listWrap: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 4,
  },
  list: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    fontSize: 9,
    fontWeight: 400,
    color: '#323130',
    marginBottom: 4,
  },
  recipientText: {
    fontSize: 9,
    fontWeight: 700,
    marginBottom: 4,
  },
  recipientName: {
    fontSize: 9,
    fontWeight: 600,
    color: '#323130',
  },
  img: {
    height: 13,
    width: 13,
  },
  recipientDetails: {
    marginTop: 8,
  },
});

export default ProofOfDeliveryAcknowledgementPdfView;
