import TextArea from 'components/common/Textarea/LabelledTextArea';
import SelectBox from 'components/common/SelectBox/LabelledSelectBox';
import FormInput from 'components/common/FormInput/LabelledFormInput';

import { getString } from 'util/lang';
import { FormSignature } from '../Signature/FormSignature';
import {
  BeneficiaryRelation,
  BeneficiaryRelationValue,
} from 'constants/productOrder';
import FormHeader from '../../common/FormHeader/FormHeader';
import PharmacyInfo from '../../common/PharmaInfo/PharmacyInfo';
import { authorizationFields } from '../../../validation/AuthorizationValidation';

const LABEL_PREFIX = 'dme.deliveryPacketForm.authorizationForm';

const AuthorizationForm = (props) => {
  const {
    organizationDetail,
    authorizationFormData,
    inputChangeHandler,
    isEdit,
    formErrors,
  } = props;

  const addAuthorization = JSON.parse(
    getString('dme.deliveryPacketForm.authorizationForm.addAuthorization', {
      companyName: organizationDetail?.organizationName,
      phoneNumber: organizationDetail?.organizationContactPhone,
    }),
  );

  return (
    <div className="form-break-before">
      <FormHeader text={getString(`${LABEL_PREFIX}.title`)} />
      <PharmacyInfo className="mt-6x" />

      <div className="delivery-packet--authorization authorization-info row">
        {addAuthorization?.map((item, index) => {
          return (
            <div className="col-12-md" key={index}>
              <h4>{item.title}</h4>
              <p>
                <span
                  dangerouslySetInnerHTML={{ __html: `${item.description}` }}
                />{' '}
                <b>{item.meta}</b>
              </p>
            </div>
          );
        })}
      </div>

      <FormSignature
        deliveryPacketSignature={authorizationFormData.tncFullName}
        deliveryPacketBeneficiarySignature={authorizationFormData.tncSignature}
        deliveryPacketSignatureDate={authorizationFormData.tncDate}
        inputChangeHandler={inputChangeHandler}
        isEdit={isEdit}
        formErrors={formErrors}
      />

      <div className="row mt-6x">
        <div className="col-4-md solo">
          <SelectBox
            label={getString(`${LABEL_PREFIX}.fields.relationship.label`)}
            id={authorizationFields.tncRelation}
            onChange={(e) =>
              inputChangeHandler(
                [authorizationFields.tncRelation],
                parseInt(e.target.value),
              )
            }
            isDisabled={!isEdit}
            value={authorizationFormData?.tncRelation || ''}
            options={BeneficiaryRelation}
            defaultValue={getString(
              `${LABEL_PREFIX}.fields.relationship.placeholder`,
            )}
            fullWidth
            solo
          />
        </div>
        {authorizationFormData?.tncRelation !==
          BeneficiaryRelationValue.SELF && (
          <div className="col-8-md">
            <FormInput
              label={getString(`${LABEL_PREFIX}.fields.address.label`)}
              id={authorizationFields.tncAddress}
              onChange={inputChangeHandler}
              disableInput={!isEdit}
              value={authorizationFormData?.tncAddress || ''}
              hasError={false}
              defaultPlaceHolder={getString(
                `${LABEL_PREFIX}.fields.address.placeholder`,
              )}
              fullWidth
              solo
            />
          </div>
        )}
      </div>
      {authorizationFormData.tncRelation !== BeneficiaryRelationValue.SELF && (
        <div className="row mt-6x">
          <div className="col-12-md">
            <TextArea
              label={getString(`${LABEL_PREFIX}.fields.reason.label`)}
              id={authorizationFields.tncReasonUnableToSign}
              onChange={inputChangeHandler}
              disableInput={!isEdit}
              value={authorizationFormData?.tncReasonUnableToSign || ''}
              placeholder={getString(
                `${LABEL_PREFIX}.fields.reason.placeholder`,
              )}
              hasError={false}
              style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
              solo
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AuthorizationForm;
