import { Document, Page, StyleSheet, Font } from '@react-pdf/renderer';

import DmeHeaderPdfView from '../presentation/common/DmeHeader/DmeHeaderPdfView';
import EducationBookletPdfView from '../presentation/deliveryPacket/EducationBooklet/EducationBookletPdfView';
import SupplierStandardPdfView from '../presentation/deliveryPacket/SupplierStandard/SupplierStandardPdfView';
import AuthorizationFormPdfView from '../presentation/deliveryPacket/AuthorizationForm/AuthorizationFormPdfView';
import OperatingInstructionPdfView from '../presentation/deliveryPacket/OperatingInstruction/OperatingInstructionPdfView';
import NoticeOfPrivacyPracticesPdfView from '../presentation/deliveryPacket/NoticeOfPrivacyPractices/NoticeOfPrivacyPracticesPdfView';
import GeneralHomeSafetyInformationPdfView from '../presentation/deliveryPacket/GeneralHomeSafetyInformation/GeneralHomeSafetyInformationPdfView';
import BillOfRightsAndResponsibilitiesPdfView from '../presentation/deliveryPacket/BillOfRightsAndResponsibilities/BillOfRightsAndResponsibilitiesPdfView';

import { getString } from 'util/lang';
import { joinValues } from 'util/string';
import { contactMasking } from 'util/contactFormat';
import {
  printFontBoldCDN,
  printFontFamily,
  printFontRegularCDN,
} from 'constants/dme';

const LABEL_PREFIX = 'dme.deliveryPacketForm';

const DeliveryPacketPdfView = ({
  deliveryPacketData,
  logoUrl,
  organizationName,
  organizationContactPhone,
  billingAddress,
  billingCity,
  billingState,
  billingZipCode,
}) => {
  Font.register({
    family: printFontFamily,
    fonts: [
      {
        src: printFontRegularCDN,
        fontWeight: 400,
      },
      {
        src: printFontBoldCDN,
        fontWeight: 700,
      },
    ],
  });

  const organizationDetail = {
    organizationName,
    organizationContactPhone: contactMasking(organizationContactPhone),
    address: joinValues(
      [billingAddress, billingCity, billingState, billingZipCode],
      ', ',
    ),
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <DmeHeaderPdfView
          logoUrl={logoUrl}
          organizationName={organizationName}
          title={getString(`${LABEL_PREFIX}.title`)}
        />
        <AuthorizationFormPdfView
          organizationDetail={organizationDetail}
          authorizationFormData={deliveryPacketData}
        />
        <BillOfRightsAndResponsibilitiesPdfView
          organizationDetail={organizationDetail}
        />
        <NoticeOfPrivacyPracticesPdfView
          organizationDetail={organizationDetail}
          effectiveDate={deliveryPacketData?.effectiveDate}
        />
        <EducationBookletPdfView
          organizationDetail={organizationDetail}
          clinicDuration={deliveryPacketData?.clinicDuration}
        />
        <GeneralHomeSafetyInformationPdfView
          organizationDetail={organizationDetail}
        />
        <SupplierStandardPdfView organizationDetail={organizationDetail} />
        <OperatingInstructionPdfView />
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    display: 'block',
    height: '100%',
    width: '100%',
    padding: '20px',
  },
});

export default DeliveryPacketPdfView;
