import React, { createRef } from 'react';

import * as Regex from 'util/regex';
import { getString } from 'util/lang';
import objectUtils from 'util/objectUtils';
import { compareStrings } from 'util/string';
import DateTimeUtils from 'util/DateAndTime';
import { replaceArrayElementByIndex } from 'util/array';
import * as toast from 'util/toast';
import { dashMobileFormat, limitChars } from 'util/TextFormat';
import { memoize } from 'util/memoize-one';
import GroupAddress from '../../GroupAppointment/presentation/address';
import GroupPatientBasicInfo from '../presentation/GroupPatientBasicInfo';
import GroupContact from '../../GroupAppointment/presentation/GroupContact';
import GroupPayment from '../../GroupAppointment/presentation/GroupPayment/GroupInsurancePayment';
import { IntialAppStateCovid } from '../../../../../constants/app';

import {
  RequiredInsuranceFields,
  PaymentOptions,
  NoInsuranceInfoKeys,
  acceptanceInfoKeys,
  InsuranceInfoKeys,
  SubscriberRelationshipCode,
  AddressFilledTypeOptions,
  PatientAddressInfoKeys,
  ConsentedByOptions,
  ConsentedBy,
} from 'constants/patientInfo';
import { CovidPageNames } from 'constants/pages';
import regexPattern from 'constants/regexPattern';
import PAYMENT_BOOLEAN from 'constants/showPaymentBoolean';

import FormErrors from '../../../../common/FormErrors';
import LinkToPage from '../../../../common/LinkToPage';
import AlertMessage from '../../../common/AlertMessage';
import BackToPage from 'components/COVID/common/BackToPage/BackToPage';
import SignatureInput from '../../../../common/SignatureInput';
import SkeletonLoadingContainer from '../../../../common/SkeletonLoadingContainer';

import PaymentType from '../../PaymentInfo/presentation/PatientInsurance';
import InsuranceInput from '../../PaymentInfo/presentation/InsuranceInput';
import NoInsuranceForm from '../../PaymentInfo/presentation/NoInsuranceForm';
import PatientAddressInfo from '../presentation/PatientAddressInfo';
import TermsAndConditions from '../../PaymentInfo/presentation/TermsAndConditions';
import PatientGuardianInfo from '../presentation/PatientGuardianInfo';
import VerifyPatientRecord from '../presentation/VerifyPatientRecord';
import PayAtTimeOfService from '../../PaymentInfo/presentation/PayAtTimeOfServiceInfoBox';
import AddressConfirmationModal from '../presentation/AddressConfirmationModal';
import AddressVerificationModal from '../presentation/AddressVerificationModal';
import Contact from '../../GroupAppointment/presentation/contact';

import { NoInsuranceDetailsValidation } from '../../PaymentInfo/container/NoInsuranceDetailsValidation';
import {
  ValidatePatientInfo,
  InsuranceDetailsValidation,
  validateGuardianInfo,
} from './PatientInfoValidation';
import ActivityIndicator from 'components/common/ActivityIndicator';

import { validateInsuranceEligibilityForMultiple } from 'services/insuranceEligibility';
import { useAddressSearch } from 'hooks/useAddressSearch';
import PatientPhysicianInfo from '../presentation/PatientPhysicianInfo';
import SelectBox from 'components/common/SelectBox';
import { ServicesInfoContainer } from 'components/COVID/common/ServicesInfo';
import { ELIGIBILITY_VERIFICATION_STATUS } from 'constants/insuranceEligibility';

import styles from './GroupPatientInfo.module.scss';
import checkMultiserviceRxDetails from 'util/rxDetails';

class GroupPatientInfo extends React.Component {
  constructor(props) {
    super(props);
    this.mainContentRef = createRef();
    this.state = {
      currentPatientIndex: this.props.details.currentPatientIndex,
      patientInfo: {},
      acceptanceInfo: {},
      noInsuranceInfo: {},
      insuranceInfo: {},
      paymentType: null,
      insuranceType: null,
      errors: {},
      insuranceInfoFilled: false,
      checkingEligibility: false,
      showNotifySuccess: false,
      showAlert: false,
      allowShot: true,
      isSignatureCanvasOpen: false,
      isAboveMaximumAge: false,
      isUnderMinimumAge: false,
      isUnderEighteen: false,
      isUnderNineteen: false,
      isAgeChecked: false,
      showMergeModal: false,
      patientId: null,
      isPatientRecordVerifying: false,
      isInsuranceEligible: false,
      insuranceEditParam: [],
      isInsuranceEligibilitySuccess: true,
      previousValidationPayload: {},
      isInsuranceValidationLoading: false,
      isInsuranceTypeShared: true,
      address: {
        homeAddress: '',
        city: '',
        state: '',
        zipCode: '',
        county: '',
      },
      isAddressConfirmed: false,
      isAddressConfirmationModalOpen: false,
      isAddressVerificationModalOpen: false,
      addressSuggestions: [],
      typeOfServiceCodes: [],
      // isEmailConsent,
      phicureResponse: [],
      consentedBy:
        this.props.details?.commonAppointmentDetails[
          this.props.details.currentPatientIndex
        ]?.consentedBy ?? ConsentedBy.PATIENT,
      organizationName: this.props.details.appSettings.organizationName,
    };

    this.topRef = React.createRef();
  }

  /**
   * When component mounts, obtain state from parent and set it to component state.
   * If state keys are unavailable in parent, default state set from constructor is used
   */
  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      insuranceInfo,
      patientInfo,
      noInsuranceInfo,
      acceptanceInfo,
      commonAppointmentDetails,
      appLanguageCode,
      counties,
      states,
      phicureResponse,
      currentPatientIndex,
    } = this.props.details;

    const { selectedClinic, selectedServices } = this.props;
    const {
      company,
      memberID,
      groupNumber,
      coverageID,
      paymentType,
      insuranceType,
    } = commonAppointmentDetails[currentPatientIndex] ?? {};
    const { patientId } = this.props.details.patientRecordInfo[
      currentPatientIndex
    ] ?? {
      isRecordVerified: false,
      isExistingPatient: false,
      patientId: null,
      firstName: '',
      lastName: '',
      middleName: '',
      birthMonth: '',
      birthDay: '',
      birthYear: '',
      mobileNo: '',
      email: '',
    };
    const currentPatient = patientInfo[currentPatientIndex] ?? {
      primaryLanguage: '153940037',
    };
    let stateLabel = '',
      countyLabel = '';

    if (this.props.isFollowup && currentPatient.state) {
      stateLabel = currentPatient?.state;
      currentPatient.state = states?.find(
        (state) => state.label === stateLabel,
      )?.value;
    } else {
      if (!currentPatient.state)
        currentPatient.state = selectedClinic?._smvs_state_value;
      stateLabel = states?.find(
        (state) => state.value === currentPatient.state,
      )?.label;
    }

    let filteredCountyOptions = counties?.filter(
      (county) => county.stateId === currentPatient.state,
    );

    if (this.props.isFollowup && currentPatient.county) {
      countyLabel = currentPatient?.county;
      currentPatient.county = filteredCountyOptions?.find(
        (county) => county.label === countyLabel,
      )?.value;
    } else {
      countyLabel = filteredCountyOptions?.find(
        (county) => county.value === currentPatient.county,
      )?.label;
      if (!currentPatient.county)
        currentPatient.county = selectedClinic?._smvs_county_value;
    }

    const insuranceInfoFilled =
      this.checkInsuranceFieldFilled({
        company,
        memberID,
        groupNumber,
        coverageID,
      }) || false;

    const { birthDay, birthMonth, birthYear } = currentPatient;

    if (birthDay && birthMonth && birthYear) {
      const birthDate = `${birthMonth}-${birthDay}-${birthYear}`;
      const isUnderMinimumAge = selectedServices.some(({ subService }) =>
        DateTimeUtils.isUnderMinimumAge(
          birthDate,
          subService.minAgeRequirement,
        ),
      );
      const isAboveMaximumAge = selectedServices.some(({ subService }) =>
        DateTimeUtils.isAboveMaximumAge(
          birthDate,
          subService.maxAgeRequirement,
        ),
      );

      const isUnderEighteen = DateTimeUtils.isUnderEighteen(birthDate);
      const isUnderNineteen = DateTimeUtils.isUnderNineteen(birthDate);
      const consentedBy = isUnderNineteen
        ? ConsentedBy.GUARDIAN
        : ConsentedBy.PATIENT;
      this.setState({
        isUnderMinimumAge,
        isAboveMaximumAge,
        isUnderEighteen,
        isUnderNineteen,
        acceptanceInfo: acceptanceInfo[currentPatientIndex] ?? {},
        consentedBy,
      });
    }
    this.setState({
      patientInfo: {
        ...currentPatient,
        addressFilledType:
          currentPatient.addressFilledType || AddressFilledTypeOptions.Manual,
        iisConsent: !this.props.getAppSettings().isIISConsentEnabled
          ? false
          : true,
      },
      address: {
        homeAddress: currentPatient.homeAddress,
        city: currentPatient.city,
        state: stateLabel || '',
        zipCode: currentPatient.zipCode,
        county: countyLabel || '',
      },
      countyOptions: filteredCountyOptions,
      stateOptions: states,
      insuranceInfo: insuranceInfo[currentPatientIndex] ?? {},
      phicureResponse: phicureResponse[currentPatientIndex] ?? [],
      typeOfServiceCodes: selectedServices.map(
        ({ service }) => service.typeOfServiceCode,
      ),
      noInsuranceInfo: noInsuranceInfo[currentPatientIndex] ?? {},
      acceptanceInfo: acceptanceInfo[currentPatientIndex] ?? {},
      paymentType: this.getSelectedPaymentType(
        paymentType,
        insuranceInfo.paymentType,
        selectedServices.some(
          ({ subService }) => subService.isInsuranceEnabled,
        ),
        selectedServices.every(
          ({ subService }) => subService.isNoInsuranceEnabled,
        ),
        selectedServices.every(
          ({ service }) => service.isPayAtTimeOfService,
        ),
        selectedClinic?.smvs_insurance,
        selectedClinic?.smvs_no_insurance,
        selectedClinic?.smvs_pay_at_the_time_of_service,
      ),
      insuranceType,
      insuranceInfoFilled,
      appLanguageCode,
      isAgeChecked: true,
      patientId,
      isInsuranceEligible: false,
      insuranceEditParam: [],
    });
  }

  /**
   * Get the default or selected payment type.
   *
   * @param {number} paymentTypeInAppointment
   * @param {number} userSelectedPaymentType
   * @param {number} insuranceStatusInSelectedVaccine
   *
   * @returns : selected payment type
   */
  getSelectedPaymentType(
    paymentTypeInAppointment,
    userSelectedPaymentType,
    insuranceStatusInSelectedVaccine,
    noInsuranceStatusInSelectedVaccine,
    payAtTimeOfServiceStatusInSelectedVaccine,
    insuranceStatusInClinic,
    noInsuranceStatusInClinic,
    payAtTimeOfServiceStatusInClinic,
  ) {
    if (paymentTypeInAppointment) {
      return paymentTypeInAppointment;
    }

    if (userSelectedPaymentType) {
      return userSelectedPaymentType;
    }

    if (insuranceStatusInSelectedVaccine && insuranceStatusInClinic !== false) {
      return PaymentOptions.Insurance;
    }

    if (
      noInsuranceStatusInSelectedVaccine &&
      noInsuranceStatusInClinic !== false
    ) {
      return PaymentOptions.NoInsurance;
    }

    if (
      payAtTimeOfServiceStatusInSelectedVaccine &&
      payAtTimeOfServiceStatusInClinic !== false
    ) {
      return PaymentOptions.PayAtTimeOfService;
    }

    const { noInsuranceEnabled } = this.props.getAppSettings();

    if (noInsuranceEnabled) {
      return PaymentOptions.NoInsurance;
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.details.appLanguageCode !==
        this.props.details.appLanguageCode &&
      !objectUtils.isEmpty(this.state.errors)
    ) {
      this.checkFormValidity(true);
    }
  }

  /**
   * @param {{bool}} val: Show or hide signature canvas
   */
  setIsSignatureCanvasOpen = (val) => {
    this.setState({
      isSignatureCanvasOpen: val,
    });
  };

  closeAddressConfirmationModal = () => {
    this.setState({
      isAddressConfirmationModalOpen: false,
    });
  };

  openAddressConfirmationModal = () => {
    this.setState({
      isAddressConfirmationModalOpen: true,
    });
  };

  closeAddressVerificationModal = () => {
    this.setState({
      isAddressVerificationModalOpen: false,
    });
  };

  openAddressVerificationModal = () => {
    this.setState({
      isAddressVerificationModalOpen: true,
    });
  };

  /**
   *  Handling Error For File Upload
   */
  setFieldError = (key, value) => {
    this.setState({
      errors: { ...this.state.errors, [key]: value },
    });
  };
  /**
   * Handle payment type change
   * @param {event} e : Input change event
   */
  onPaymentTypeChange = (e) => {
    const val = e.target.value;

    const emptyInsuranceInfo = this.resetInsuranceInfo();
    const emptyNoInsuranceInfo = this.resetNoInsuranceInfo();
    const { errors, insuranceInfo, noInsuranceInfo } = this.state;

    const insuranceError = this.resetErrorBulk(Object.keys(insuranceInfo));
    const noInsuranceError = this.resetErrorBulk(Object.keys(noInsuranceInfo));
    const newErrors = {
      ...errors,
      ...insuranceError,
      ...noInsuranceError,
    };

    if (val) {
      this.setState(
        {
          paymentType: val,
          insuranceInfo: emptyInsuranceInfo,
          noInsuranceInfo: emptyNoInsuranceInfo,
          errors: newErrors,
        },
        () => {
          const { pageComplete } = this.props;
          if (pageComplete[CovidPageNames.PatientInfo]) {
            this.checkFormValidity(true);
          }
        },
      );
    }
  };

  /**
   * Handle payment type change
   * @param {event} e : Input change event
   */
  onInsuranceTypeChange = (e) => {
    const selectedInsuranceType = e.target.value;

    const emptyInsuranceInfo = this.resetInsuranceInfo();
    const { errors, insuranceInfo } = this.state;

    const insuranceError = this.resetErrorBulk(Object.keys(insuranceInfo));
    const newErrors = {
      ...errors,
      ...insuranceError,
      insuranceType: null,
    };

    if (selectedInsuranceType) {
      this.props.fetchPayerList(selectedInsuranceType);
      this.setState(
        {
          insuranceType: selectedInsuranceType,
          insuranceInfo: emptyInsuranceInfo,
          errors: newErrors,
        },
        () => {
          const { pageComplete } = this.props;
          if (pageComplete[CovidPageNames.PatientInfo]) {
            this.checkFormValidity(true);
          }
        },
      );
    }
  };

  /**
   * Format input if needed, based on the keys
   * If key has an invalid input, returns null
   * @param {string} key
   * @param {string} value
   */
  formatFormInput = (key, value) => {
    switch (key) {
      case 'mobileNo':
        if (!Regex.IsMobileNumber(value)) return;
        value = limitChars(value, 12);
        value = dashMobileFormat(value);
        break;
      case 'physicianPhoneNumber':
        if (!Regex.IsMobileNumber(value)) return;
        value = limitChars(value, 12);
        value = dashMobileFormat(value);
        break;
      case 'birthYear':
        if (isNaN(value)) return;
        break;
      case 'memberID':
        value = value.trimStart();
        value = value.toUpperCase();
        value = value.replace(/ +/g, '');
        value = limitChars(value, 100);
        break;
      case 'groupNumber':
        value = limitChars(value, 100);
        break;
      case 'zipCode':
        if (!Regex.isNumber(value)) return;
        value = limitChars(value, 5);
        break;
      case 'firstName':
        value = value.trimStart();
        value = value.replace(/  +/g, ' ');
        value = limitChars(value, 30);
        break;
      case 'middleName':
        value = value.trimStart();
        value = value.replace(/  +/g, ' ');
        value = limitChars(value, 30);
        break;
      case 'lastName':
        const startTrimed = value.trimStart();
        value = startTrimed.replace(/  +/g, ' ');
        value = limitChars(value, 30);
        break;
      case 'referredBy':
        value = limitChars(value, 100);
        break;
      case 'homeAddress':
        value = limitChars(value, 100);
        break;
      case 'city':
        value = limitChars(value, 100);
        break;
      case 'rxBin':
        if (!Regex.isNumber(value)) return;
        value = limitChars(value, 6);
        break;
      default:
        break;
    }

    return value;
  };

  handleConfirmAddress = () => {
    // trigger handleSubmit after address confirmation
    this.setState(
      {
        isAddressConfirmed: true,
      },
      () => {
        this.handleSubmit();
      },
    );
  };

  handleEditAddress = () => {
    setTimeout(() => {
      const addressSection = document.getElementsByClassName(
        'form-patient-address',
      )[0];
      if (addressSection) {
        window.scrollTo({
          behavior: 'smooth',
          left: 0,
          top: addressSection.offsetTop - 200,
        });
      }
    });
  };

  handleAddressChange = (address) => {
    const { homeAddress, city, zipCode } = address;
    const state = this.props.details.states.find(
      (state) => state.label === address.state,
    );
    const filteredCountyOptions = this.props.details.counties.filter(
      (county) => county.stateCode === address.state,
    );
    const county = filteredCountyOptions.find(
      (county) =>
        county.label === address.county && county.stateCode === address.state,
    );
    this.setState({
      address,
      isAddressConfirmed: true,
      countyOptions: filteredCountyOptions,
      patientInfo: {
        ...this.state.patientInfo,
        city,
        zipCode,
        homeAddress,
        state: state?.value,
        county: county?.value,
        addressFilledType: AddressFilledTypeOptions.Verified,
      },
      errors: this.resetErrorBulk(PatientAddressInfoKeys),
    });
  };

  isAddressChanged = (id, value) => {
    const { patientInfo } = this.state;
    const { homeAddress, city, state, county, zipCode } = patientInfo;
    if (id === 'homeAddress') {
      return homeAddress !== value;
    }
    if (id === 'city') {
      return city !== value;
    }
    if (id === 'state') {
      return state !== value;
    }
    if (id === 'county') {
      return county !== value;
    }
    if (id === 'zipCode') {
      return zipCode !== value;
    }
    return false;
  };

  updateReferenceAddress = (key, value) => {
    const hasAddressChanged = this.isAddressChanged(key, value);

    if (key === 'state') {
      value = this.props.details.states.find(
        (state) => state.value === value,
      )?.label;
    } else if (key === 'county') {
      value = this.props.details.counties.find(
        (county) => county.value === value,
      )?.label;
    }

    this.setState({
      isAddressConfirmed: !hasAddressChanged,
      address: {
        ...this.state.address,
        [key]: value,
      },
    });
  };

  /**
   * Update personal info.
   * For a key value pair,
   * - Format input if specified
   * - Clear error state for the key
   *
   * @param {string} key : The name of the field
   * @param {string} value : New value
   * @param {object} meta : Additional info
   */
  onPatientInfoChange = (key, value, event) => {
    if (key === 'mobileNo' && value.length < 12) {
      const cursor = event.target.selectionStart;
      const element = event.target;
      window.requestAnimationFrame(() => {
        element.selectionStart = cursor;
        element.selectionEnd = cursor;
      });
      value = value.replaceAll('-', '');
    }

    if (key === 'mobileNo' && !Regex.isNumber(value)) {
      return;
    }

    if (key === 'physicianPhoneNumber' && value.length < 12) {
      value = value.replaceAll('-', '');
    }

    if (key === 'physicianPhoneNumber' && !Regex.isNumber(value)) {
      return;
    }

    if (this.state.checkingEligibility) return;

    if (key == null || value == null) return;

    const nameKeys = [
      'firstName',
      'lastName',
      'middleName',
      'guardianFirstName',
      'guardianLastName',
      'guardianMiddleName',
    ];
    if (
      nameKeys.includes(key) &&
      value !== '' &&
      !value.match(regexPattern.nameField)
    )
      return;

    const { patientInfo } = this.state;

    value = this.formatFormInput(key, value);

    // Case: Character Limit:
    // Do not process further if new value equals to old value
    if (this.state.patientInfo[key] === value) return;

    const newChange = { [key]: value };

    const addressKeys = ['homeAddress', 'city', 'state', 'zipCode', 'county'];

    if (addressKeys.includes(key)) {
      this.updateReferenceAddress(key, value);

      const hasAddressChanged = this.isAddressChanged(key, value);

      newChange['addressFilledType'] = hasAddressChanged
        ? AddressFilledTypeOptions.Manual
        : AddressFilledTypeOptions.Verified;
    }
    // email opt out
    // email opt out, isEmailchecked: false, disbaleInput: true
    if (key === 'state') {
      let filteredCountyOptions = this.props.details.counties?.filter(
        (county) => county?.stateId === value,
      );
      this.setState({
        countyOptions: filteredCountyOptions,
      });
    }

    //if (value === null) return;

    // FIXME: Calling this function consecutively to reset email and confirmEmail doesnot reset 2nd function call

    let errors = this.resetError(key);

    if (key === 'phoneOptOut' && value === true) {
      newChange['mobileNo'] = '';
    }

    if (key === 'state') {
      newChange['county'] = '';
    }
    if (key === 'emailNotificationConsent' && value) {
      document.getElementById('email').focus();
      // document.getElementById('confirmEmail').focus();
    }

    if (key === 'emailNotificationConsent' && !value) {
      errors = this.resetErrorBulk(['email', 'confirmEmail']);
    }

    // on date change check patient is over 18 years

    const oldIsUnderNineteen = this.state.isUnderNineteen;

    this.setState(
      {
        patientInfo: { ...patientInfo, ...newChange },
        errors,
      },
      () => {
        const {
          patientInfo: { birthDay, birthMonth, birthYear },
        } = this.state;

        const { selectedServices } = this.props;

        if (birthDay && birthMonth && birthYear) {
          const birthDate = `${birthMonth}-${birthDay}-${birthYear}`;
          const isUnderMinimumAge = selectedServices.some(({ subService }) =>
            DateTimeUtils.isUnderMinimumAge(
              birthDate,
              subService.minAgeRequirement,
            ),
          );
          const isAboveMaximumAge = selectedServices.some(({ subService }) =>
            DateTimeUtils.isAboveMaximumAge(
              birthDate,
              subService.maxAgeRequirement,
            ),
          );
          const isUnderEighteen = DateTimeUtils.isUnderEighteen(birthDate);
          const isUnderNineteen = DateTimeUtils.isUnderNineteen(birthDate);
          // if dob is changed to be above/below 19, re-select this
          const consentedBy =
            oldIsUnderNineteen === isUnderNineteen
              ? this.state.consentedBy
              : isUnderNineteen
                ? ConsentedBy.GUARDIAN
                : ConsentedBy.PATIENT;
          this.setState({
            isUnderMinimumAge,
            isAboveMaximumAge,
            isUnderEighteen,
            isUnderNineteen,
            consentedBy,
          });
        }
        const { pageComplete } = this.props;
        if (pageComplete[CovidPageNames.PatientInfo]) {
          this.checkFormValidity(true);
        }

        // Check if saved record matches new record
        const hasPatientRecordUpdated = this.isPatientInfoUpdated();

        if (hasPatientRecordUpdated) {
          this.props.setDetails({
            pageComplete: {
              ...pageComplete,
              [CovidPageNames.PatientInfo]: false,
            },
          });
        }
      },
    );
  };

  isPatientInfoUpdated = () => {
    const {
      firstName,
      middleName,
      lastName,
      mobileNo,
      email,
      birthDay,
      birthMonth,
      birthYear,
    } = this.state.patientInfo;
    const { patientRecordInfo: globalPatientRecordInfo, currentPatientIndex } =
      this.props.details;
    const currentPatientRecordInfo = globalPatientRecordInfo[
      currentPatientIndex
    ] ?? {
      isRecordVerified: false,
      isExistingPatient: false,
      patientId: null,
      firstName: '',
      lastName: '',
      middleName: '',
      birthMonth: '',
      birthDay: '',
      birthYear: '',
      mobileNo: '',
      email: '',
    };

    if (
      compareStrings(currentPatientRecordInfo.firstName, firstName) &&
      compareStrings(currentPatientRecordInfo.middleName, middleName) &&
      compareStrings(currentPatientRecordInfo.lastName, lastName) &&
      currentPatientRecordInfo.mobileNo === mobileNo &&
      compareStrings(currentPatientRecordInfo.email, email) &&
      currentPatientRecordInfo.birthYear === birthYear &&
      currentPatientRecordInfo.birthMonth === birthMonth &&
      currentPatientRecordInfo.birthDay === birthDay
    ) {
      const updatedPatientRecordInfo = replaceArrayElementByIndex(
        globalPatientRecordInfo,
        currentPatientIndex,
        {
          ...currentPatientRecordInfo,
          isRecordVerified: true,
        },
      );
      this.props.setDetails({
        patientRecordInfo: updatedPatientRecordInfo,
      });

      return false;
    } else {
      const updatedPatientRecordInfo = replaceArrayElementByIndex(
        globalPatientRecordInfo,
        currentPatientIndex,
        {
          ...currentPatientRecordInfo,
          isRecordVerified: false,
        },
      );
      this.props.setDetails({
        patientRecordInfo: updatedPatientRecordInfo,
      });

      return true;
    }
  };

  checkInsuranceFieldFilled = (insuranceDetails) => {
    let insuranceInfoFilled = true;
    Object.keys(insuranceDetails).forEach((key) => {
      if (!RequiredInsuranceFields.includes(key)) return;

      if (!insuranceDetails[key]) {
        insuranceInfoFilled = false;
      }
    });

    return insuranceInfoFilled;
  };

  /**
   * Map payer name to id
   *
   * @param {string} name
   * @param {Array}  payerlist: {label,value,id}
   */
  getCompanyID = (name) => {
    const payerlist = this.props.getPayerList();

    const payer = payerlist.find((company) => company.value === name);

    let id = null;

    if (payer) {
      id = payer.id;
    }

    return id;
  };

  /**
   *   Description: Check if required details are valid
   *  @param  {object} patientData: Patient form inputs that are to be validated
   *  @return {object} errors : object with keys for all field inputs provided and respective error values
   *  @return {bool} isValid : true if all inputs are valid else false
   */
  validatePatientData = (patientData) => {
    let { errors } = ValidatePatientInfo(patientData);

    // only run insurance validation if showPayment is set to true
    const { showPayment } = this.props.getAppSettings();

    const { selectedServices, selectedClinic } = this.props;
    const collectRxDetails = checkMultiserviceRxDetails(selectedServices);

    const validateInsuranceForService =
      selectedServices.some(
        ({ subService }) => subService.isInsuranceEnabled,
      ) ||
      selectedServices.every(
        ({ subService }) => subService.isNoInsuranceEnabled,
      ) ||
      selectedClinic.smvs_insurance !== false ||
      selectedClinic.smvs_no_insurance !== false ||
      selectedClinic.smvs_pay_at_the_time_of_service !== false;

    const { paymentType, noInsuranceInfo, insuranceInfo, insuranceType } =
      this.state;
    const organizationObj = this.props.details.organizationObj;

    if (organizationObj && organizationObj.isPrepaidByOrganization) {
      // do nothing
    } else {
      if (
        this.state.paymentType !== PaymentOptions.PayAtTimeOfService &&
        validateInsuranceForService &&
        showPayment === PAYMENT_BOOLEAN.TRUE
      ) {
        //Insurance company input validation
        const { company } = patientData;
        const companyID = this.getCompanyID(company);

        if (
          this.state.paymentType === PaymentOptions.Insurance &&
          company !== '' &&
          selectedClinic.smvs_insurance !== false
        ) {
          if (companyID === null) {
            errors = {
              ...errors,
              company: getString('insuranceValidationMessageNotOnList'),
            };
          }

          const { errors: insuranceValidationError } =
            InsuranceDetailsValidation(
              { ...insuranceInfo, paymentType, insuranceType },
              collectRxDetails, 
            );
          errors = {
            ...errors,
            ...insuranceValidationError,
          };
        }

        if (
          paymentType &&
          paymentType === PaymentOptions.NoInsurance &&
          selectedClinic.smvs_no_insurance !== false
        ) {
          const { errors: noInsuranceErrors } =
            this.validateNoInsuranceDetails();

          if (!noInsuranceInfo.noInsuranceAttempted) {
            errors = {
              ...errors,
              ...noInsuranceErrors,
            };
          }
        }
      }
    }

    const { emailError, isEmailValidWithConfirmEmail } =
      this.validateEmailWithConfirmEmail(
        patientData.email,
        patientData.confirmEmail,
        patientData.emailNotificationConsent,
      );
    if (!isEmailValidWithConfirmEmail) {
      errors = {
        ...errors,
        ...emailError,
      };
    }

    return { errors };
  };

  validateNoInsuranceDetails = () => {
    const { noInsuranceIdNumber, noInsuranceIdType, noInsuranceState } =
      this.state.noInsuranceInfo;
    const noInsuranceDetailsObj = {
      noInsuranceIdNumber,
      noInsuranceIdType,
      noInsuranceState,
    };
    const { errors } = NoInsuranceDetailsValidation(noInsuranceDetailsObj);
    return {
      errors,
    };
  };

  /**
   * Animation to error
   */
  animateToFormError = () => {
    setTimeout(() => {
      const errorField = document.getElementsByClassName('form-error')[0];

      if (errorField) {
        window.scrollTo({
          behavior: 'smooth',
          left: 0,
          top: errorField.offsetTop - 200,
        });
      }
    });
  };

  /**
   * Clear error for input field with that key
   */
  resetError = (key) => {
    const newError = { ...this.state.errors };
    newError[key] = null;
    return newError;
  };

  resetErrorBulk = (keyList) => {
    const newError = { ...this.state.errors };
    keyList.forEach((key) => {
      newError[key] = null;
    });
    return newError;
  };

  handleSubmit = async (isAutoTrigger) => {
    const { isAddressConfirmed, address } = this.state;
    const { addressFilledType } = this.state.patientInfo;
    const {
      patientRecordInfo: globalPatientRecordInfo,
      isGroupAppointment,
      patientInfo: globalPatientInfo,
      currentPatientIndex,
    } = this.props.details;
    const suggest = this.props.suggest;
    const currentPatientRecordInfo =
      globalPatientRecordInfo[currentPatientIndex];

    const isFormInvalid = await this.checkFormValidity(isAutoTrigger);

    if (isFormInvalid) return;

    if (!currentPatientRecordInfo.isRecordVerified) {
      try {
        this.setState({
          isPatientRecordVerifying: true,
        });
        if (
          !isAddressConfirmed &&
          addressFilledType === AddressFilledTypeOptions.Manual
        ) {
          const suggestions = await suggest(address);
          if (!suggestions || suggestions?.length === 0) {
            this.setState({
              addressSuggestions: [],
            });
            this.openAddressConfirmationModal();
            return;
          } else {
            this.setState({ addressSuggestions: suggestions });
            this.openAddressVerificationModal();
            return;
          }
        }
        if (
          isGroupAppointment &&
          this.props.details.currentPatientIndex <
            this.props.details.patientInfo.length - 1
        ) {
          this.props.setDetails({
            currentPatientIndex: this.props.details.currentPatientIndex + 1,
          });
          this.props.navigateGroupNavbar();
          if (
            this.props.details.currentPatientIndex ===
            this.props.details.patientInfo.length
          ) {
            this.gotoNextPage();
          }
        } else {
          this.gotoNextPage();
        }
      } catch (error) {
        console.log(error);
        this.gotoNextPage();
      } finally {
        this.setState({
          isPatientRecordVerifying: false,
        });
      }
    } else {
      if (
        isGroupAppointment &&
        this.props.details.currentPatientIndex <
          this.props.details.patientInfo.length - 1
      ) {
        this.props.setDetails({
          currentPatientIndex: this.props.details.currentPatientIndex + 1,
        });
        this.props.navigateGroupNavbar();
      } else {
        this.gotoNextPage();
      }
    }
  };

  /**
   *  TODO: check function should not have side effects that save data
   *
   * Check if form data is valid,
   * If isValid is true, proceeds to the confirm and submit page
   * If isValid is false, errors are set, doesnt route to next page and returns
   */
  checkFormValidity = async (isAutoTrigger) => {
    const {
      patientInfo,
      insuranceInfo,
      acceptanceInfo,
      paymentType,
      noInsuranceInfo,
      insuranceType,
      consentedBy,
      phicureResponse,
    } = this.state;

    let allDetails = { ...patientInfo, ...acceptanceInfo, paymentType };

    let errors = {};
    if (this.state.isUnderNineteen) {
      const { errors: guardianInfoErrors } = validateGuardianInfo(patientInfo);
      errors = { ...guardianInfoErrors };
    }

    const { errors: patientInfoErrors } = this.validatePatientData({
      ...allDetails,
    });
    errors = { ...errors, ...patientInfoErrors };

    const errorList = Object.keys(errors).filter((key) => errors[key]);

    this.setState({
      errors,
    });
    if (errorList.length > 0) {
      this.setState({
        errors,
      });
      this.props.setSelfAndNextPageIncomplete();
      if (!isAutoTrigger) {
        this.animateToFormError();
      }
      return true;
    }
    const isInsurancePayment = paymentType === PaymentOptions.Insurance;

    const insuranceData = isInsurancePayment ? insuranceInfo : noInsuranceInfo;

    const { selectedServices, selectedClinic } = this.props;
    const { organizationObj } = this.props.details;
    const isPaidByOrganization = organizationObj?.isPrepaidByOrganization;

    const enabledPaymentOptions = this.getEnabledPaymentOptions(
      selectedServices,
      selectedClinic,
    );

    const isPaymentEnabled =
      enabledPaymentOptions.insurance ||
      enabledPaymentOptions.noInsurance ||
      enabledPaymentOptions.payAtTimeOfService;

    const paymentTypeValue =
      isPaymentEnabled && !isPaidByOrganization ? paymentType : null;
    const insuranceTypeValue = isInsurancePayment ? insuranceType : null;

    const commonAppointmentDetails = {
      ...acceptanceInfo,
      ...insuranceData,
      paymentType: paymentTypeValue,
      insuranceType: insuranceTypeValue,
      consentedBy,
    };

    const appointmentDetails = selectedServices.map(({ subService }, idx) => {
      if (!enabledPaymentOptions.insurance || subService.isInsuranceEnabled) {
        return {
          ...acceptanceInfo,
          ...insuranceData,
          paymentType: paymentTypeValue,
          insuranceType: insuranceTypeValue,
          consentedBy,
        };
      }

      // empty
      return {
        ...acceptanceInfo,
        paymentType: null,
        insuranceType: null,
        consentedBy,
      };
    });

    // Mapping Internal State  with the App state which is in details
    const { details } = this.props;
    const { currentPatientIndex } = details;
    const globalPatientInfo = replaceArrayElementByIndex(
      details.patientInfo,
      currentPatientIndex,
      patientInfo,
    );

    const globalNoInsuranceInfo = replaceArrayElementByIndex(
      details.noInsuranceInfo,
      currentPatientIndex,
      noInsuranceInfo,
    );

    const globalInsuranceInfo = replaceArrayElementByIndex(
      details.insuranceInfo,
      currentPatientIndex,
      insuranceInfo,
    );

    const globalCommonAppointmentDetails = replaceArrayElementByIndex(
      details.commonAppointmentDetails,
      currentPatientIndex,
      commonAppointmentDetails,
    );

    const globalPhicureResponse = replaceArrayElementByIndex(
      details.phicureResponse,
      currentPatientIndex,
      phicureResponse,
    );

    const globalAcceptanceInfo = replaceArrayElementByIndex(
      details.acceptanceInfo,
      currentPatientIndex,
      acceptanceInfo,
    );

    this.props.setDetails({
      patientInfo: globalPatientInfo,
      commonAppointmentDetails: globalCommonAppointmentDetails,
      appointmentDetails,
      noInsuranceInfo: globalNoInsuranceInfo,
      acceptanceInfo: globalAcceptanceInfo,
      insuranceInfo: globalInsuranceInfo,
      phicureResponse: globalPhicureResponse,
    });
  };

  saveAndMergePatientData = (isMergeAllowed) => {
    const { patientInfo } = this.state;

    const patientRecord = {
      firstName: patientInfo.firstName,
      lastName: patientInfo.lastName,
      middleName: patientInfo.middleName,
      birthMonth: patientInfo.birthMonth,
      birthDay: patientInfo.birthDay,
      birthYear: patientInfo.birthYear,
      mobileNo: patientInfo.mobileNo,
      email: patientInfo.email,
    };

    const { patientRecordInfo: globalPatientRecordInfo, currentPatientIndex } =
      this.props.details;

    if (isMergeAllowed) {
      const updatedPatientRecordInfo = replaceArrayElementByIndex(
        globalPatientRecordInfo,
        currentPatientIndex,
        {
          isExistingPatient: true,
          patientId: this.state.patientId,
          isRecordVerified: true,
          ...patientRecord,
        },
      );
      this.props.setDetails({
        patientRecordInfo: updatedPatientRecordInfo,
      });
    } else {
      const updatedPatientRecordInfo = replaceArrayElementByIndex(
        globalPatientRecordInfo,
        currentPatientIndex,
        {
          ...this.props.details.patientRecordInfo[currentPatientIndex],
          isExistingPatient: false,
          patientId: null,
          isRecordVerified: true,
        },
      );
      this.props.setDetails({
        patientRecordInfo: updatedPatientRecordInfo,
      });
    }
    this.gotoNextPage();
  };

  gotoNextPage = () => {
    this.props.toNextPage();
    this.props.setThisPageCompleteStatus(true);
  };

  /**
   * Check if email and confirmEmail Id field value matches.
   *
   * @param {string} email : Email id of patient
   * @param {string} confirmEmail : Email id to confirm the email id.
   */
  validateEmailWithConfirmEmail = (email, confirmEmail) => {
    if (email !== confirmEmail && !!email) {
      return {
        emailError: {
          confirmEmail: getString('emailMatchRequired'),
        },
        isEmailValidWithConfirmEmail: false,
      };
    } else {
      return {
        emailError: { confirmEmail: null },
        isEmailValidWithConfirmEmail: true,
      };
    }
  };

  validateEmailonBlur = () => {
    const errorset = {};

    if (
      !!this.state.patientInfo.emailNotificationConsent &&
      !this.state.patientInfo.email
    ) {
      errorset.email = getString('emailRequired2');
    }

    if (
      !!this.state.patientInfo.emailNotificationConsent &&
      !!this.state.patientInfo.email &&
      !Regex.isEmail(this.state.patientInfo.email)
    ) {
      errorset.email = getString('validEmailRequired');
    }

    if (
      !!this.state.patientInfo.emailNotificationConsent &&
      !this.state.patientInfo.confirmEmail
    ) {
      errorset.confirmEmail = getString('confirmEmailRequired');
    }

    if (
      !!this.state.patientInfo.emailNotificationConsent &&
      !!Regex.isEmail(this.state.patientInfo.email) &&
      !!Regex.isEmail(this.state.patientInfo.confirmEmail) &&
      this.state.patientInfo.email === this.state.patientInfo.confirmEmail
    ) {
      errorset.confirmEmail = null;
      errorset.email = null;
    }

    this.setState({
      errors: {
        ...this.state.errors,
        ...errorset,
      },
    });
  };

  validateConfirmEmailonBlur = () => {
    const errorset = {};

    if (
      !!this.state.patientInfo.emailNotificationConsent &&
      !this.state.patientInfo.confirmEmail
    ) {
      errorset.confirmEmail = getString('confirmEmailRequired');
    }

    if (
      !!this.state.patientInfo.emailNotificationConsent &&
      !!this.state.patientInfo.confirmEmail &&
      !Regex.isEmail(this.state.patientInfo.confirmEmail)
    ) {
      errorset.confirmEmail = getString('validConfirmEmailRequired');
    }

    if (
      !!this.state.patientInfo.emailNotificationConsent &&
      !!Regex.isEmail(this.state.patientInfo.email) &&
      !!Regex.isEmail(this.state.patientInfo.confirmEmail) &&
      this.state.patientInfo.email !== this.state.patientInfo.confirmEmail
    ) {
      errorset.confirmEmail = getString('emailMatchRequired');
    }

    if (
      !!this.state.patientInfo.emailNotificationConsent &&
      !!Regex.isEmail(this.state.patientInfo.email) &&
      !!Regex.isEmail(this.state.patientInfo.confirmEmail) &&
      this.state.patientInfo.email === this.state.patientInfo.confirmEmail
    ) {
      errorset.confirmEmail = null;
      errorset.email = null;
    }

    this.setState({
      errors: {
        ...this.state.errors,
        ...errorset,
      },
    });
  };

  onAcceptanceInfoChange = (key, value) => {
    if (this.state.checkingEligibility) return;

    if (key == null || value == null) return;

    const { acceptanceInfo } = this.state;
    value = this.formatFormInput(key, value);

    const errors = this.resetError(key);
    this.setState(
      {
        acceptanceInfo: {
          ...acceptanceInfo,
          acceptedTerms: {
            ...acceptanceInfo.acceptedTerms,
            [key]: value,
          },
        },
        errors,
      },
      () => {
        const { pageComplete } = this.props;
        if (pageComplete[CovidPageNames.PatientInfo]) {
          this.checkFormValidity(true);
        }
      },
    );
  };

  /**
   * Update Insurance info.
   * For a key value pair,
   * - Format input if specified
   * - Clear error state for the key
   *
   * @param {string} key : The name of the field
   * @param {string} value : New value
   */
  onInsuranceInfoChange = (key, value) => {
    if (key == null || value == null) return;

    const { noInsuranceInfo, insuranceInfo, paymentType } = this.state;
    value = this.formatFormInput(key, value);
    const isInsurancePayment = paymentType === PaymentOptions.Insurance;
    const insuranceData = isInsurancePayment ? insuranceInfo : noInsuranceInfo;
    const insuranceDetails = { ...insuranceData, [key]: value };

    const insuranceInfoFilled =
      this.checkInsuranceFieldFilled(insuranceDetails);

    let errors;

    if (key === 'company') {
      errors = this.resetErrorBulk([
        'otherInsuranceCompany',
        'insurancePlanType',
        key,
        'rxBin',
        'rxPCN',
        'rxGroup',
        'suffix',
      ]);
    } else if (
      key === 'subscriberRelationship' &&
      value !== SubscriberRelationshipCode.Self
    ) {
      errors = this.resetErrorBulk([
        'cardHolderFirstName',
        key,
        'cardHolderLastName',
      ]);
    } else {
      errors = this.resetError(key);
    }

    this.setState(
      {
        noInsuranceInfo: !isInsurancePayment
          ? insuranceDetails
          : noInsuranceInfo,
        insuranceInfo: isInsurancePayment ? insuranceDetails : insuranceInfo,
        errors,
        insuranceInfoFilled,
      },
      () => {
        const { pageComplete } = this.props;
        if (pageComplete[CovidPageNames.PatientInfo]) {
          this.checkFormValidity(true);
        }
      },
    );

    if (key === 'noInsuranceAttempted' && value === true) {
      const insuranceInfo = {
        company: '',
        memberID: '',
        groupNumber: '',
        memberIDPrefix: '',
        memberIDSuffix: '',
        subscriberRelationship: '',
        cardHolderFirstName: '',
        cardHolderLastName: '',
        cardHolderMiddleName: '',
        insuranceCardFront: '',
        insuranceCardBack: '',
        coverageID: '',
        rxBin: '',
        rxPCN: '',
        rxGroup: '',
        medicareID: '',
        medicareIssuer: '',
        insuranceType: '',
        paymentType: PaymentOptions.Insurance,
      };

      const remainingErrors = this.resetErrorBulk(NoInsuranceInfoKeys);
      this.setState({
        remainingErrors,
        commonAppointmentDetails: {
          ...this.state.commonAppointmentDetails,
          ...insuranceInfo,
        },
      });
    }

    this.props.setDetails({
      pageComplete: {
        ...this.props.pageComplete,
        [CovidPageNames.PatientInfo]: false,
      },
    });
  };

  onSaveSignature = (image, compressed) => {
    this.setState(
      {
        acceptanceInfo: {
          ...this.state.acceptanceInfo,
          signatureUrl: image,
          compressedSignature: compressed === undefined ? null : compressed,
        },
      },
      () => {
        this.checkFormValidity(true);
      },
    );
  };

  resetInsuranceInfo = () => {
    const emptyInsuranceInfo = {};

    // reset all keys if no specific keys are specified
    InsuranceInfoKeys.forEach((key) => {
      emptyInsuranceInfo[key] = '';
    });

    return emptyInsuranceInfo;
  };

  resetNoInsuranceInfo = () => {
    const emptyNoInsuranceInfo = {};

    NoInsuranceInfoKeys.forEach((key) => {
      if (key === 'noInsuranceState') {
        emptyNoInsuranceInfo[key] = 'WA';
        return;
      }
      emptyNoInsuranceInfo[key] = '';
    });
    return emptyNoInsuranceInfo;
  };

  /**
   *
   * Checks to see if all the terms and conditions are accepted
   * Returns true if all terms are checked
   * Returns false if all terms are not checked
   *
   * @param {*} acceptedTermsList
   * @returns {boolean}
   */
  validateTermsAndConditions = (acceptedTermsList) => {
    if (!acceptedTermsList) return false;

    let areTermsAccepted = true;
    const { termsAndConditionsAcceptance } =
      this.props.details.localizedWebContents[0];
    termsAndConditionsAcceptance.forEach((terms) => {
      if (!acceptedTermsList[terms.id]) {
        areTermsAccepted = false;
      }
    });

    return areTermsAccepted;
  };

  getPayerForPayerId = (payerlist, companyId) => {
    if (!companyId) return;
    const selectedPayer = payerlist.find(({ guid }) => guid === companyId);
    return selectedPayer;
  };

  /**
   * Map payer guid to id
   *
   */
  getCompanyIDFromGUID = (id_val) => {
    const payerlist = this.props.getPayerList();

    const payer = payerlist.find((company) => company.guid === id_val);

    let id = null;

    if (payer) {
      id = payer.id;
    }

    return id;
  };

  mapAppointmentToInsuranceEligibilityValidationParams = () => {
    const {
      firstName,
      middleName,
      lastName,
      birthMonth,
      birthDay,
      birthYear,
      homeAddress,
      city,
      state,
      zipCode,
      gender,
    } = this.state.patientInfo;

    const {
      company,
      memberID,
      memberIDPrefix,
      memberIDSuffix,
      subscriberRelationship,
      cardHolderFirstName,
      cardHolderLastName,
    } = this.state.insuranceInfo;

    const companyID = this.getCompanyIDFromGUID(company);

    const genderID = gender === '1' ? 'M' : gender === '2' ? 'F' : 'U';

    let bMonth =
      birthMonth?.toString().length === 1 ? `0${birthMonth}` : birthMonth;
    let bDay = birthDay?.toString().length === 1 ? `0${birthDay}` : birthDay;
    const param = {
      payerEligibilityID: companyID,
      patientLastName: lastName,
      patientMiddleName: middleName,
      patientFirstName: firstName,
      patientAddress: homeAddress,
      patientCity: city,
      patientState: state,
      patientZip: zipCode,
      patientDateOfBirth: `${bMonth}/${bDay}/${birthYear}`,
      patientDateofService: DateTimeUtils.toSlashFormat(Date.now()),
      payerID: companyID,
      // dont allow if memberID is missing
      patientPolicyNumber: memberID
        ? memberIDPrefix + memberID + memberIDSuffix
        : '',
      patientGender: genderID,
      insuredRelationship: Object.keys(SubscriberRelationshipCode).find(
        (key) =>
          SubscriberRelationshipCode[key] === parseInt(subscriberRelationship),
      ),
    };

    if (param.insuredRelationship !== 'Self') {
      param['insuredFirstName'] = cardHolderFirstName;
      param['insuredLastName'] = cardHolderLastName;
    }

    return param;
  };

  validateInsuranceEligibilityOfAppointment = async () => {
    const { insuranceCoverageList: globalInsuranceCoverageList } =
      this.props.details;

    this.setState({ isInsuranceValidationLoading: true });

    const params = this.mapAppointmentToInsuranceEligibilityValidationParams();
    this.setState({ previousValidationPayload: params });

    try {
      const eligibilityRequestPayloads = this.props.selectedServices.map(
        ({ service, subService }) => {
          if (!subService.isInsuranceEnabled) {
            return null;
          }
          return { ...params, typeOfServiceCode: service.typeOfServiceCode };
        },
      );

      const finalResponse = await validateInsuranceEligibilityForMultiple(
        eligibilityRequestPayloads,
      );

      const insuranceVerifiedArray = finalResponse.map(
        (item) => item?.isEligible,
      );
      const insuranceVerifiedStatus = finalResponse.every(
        (item) => item?.isEligible,
      )
        ? ELIGIBILITY_VERIFICATION_STATUS.ALL_VALID
        : finalResponse.some((item) => item?.isEligible)
          ? ELIGIBILITY_VERIFICATION_STATUS.ONE_VALID
          : ELIGIBILITY_VERIFICATION_STATUS.ALL_INVALID;

      //get a unique list of editInfo of each response, so that there won't be duplicate values
      const editInfoArray = finalResponse
        .map((item) => item?.editInfo ?? [])
        .flat();

      this.props.setInsuranceCoverageList(
        replaceArrayElementByIndex(
          globalInsuranceCoverageList,
          this.state.currentPatientIndex,
          insuranceVerifiedArray,
        ),
      );

      const phicureResponse = finalResponse.map((item) => {
        return item?.phicureInsuredInfo
          ? {
              payload: item?.phicureInsuredInfo?.encryptedData,
            }
          : null;
      });

      this.setState({
        isInsuranceEligible: insuranceVerifiedStatus,
        insuranceEditParam: editInfoArray,
        isInsuranceEligibilitySuccess: true,
        phicureResponse,
      });
    } catch (e) {
      this.setState({ isInsuranceEligibilitySuccess: false });
      toast.error({
        title: '',
        message: getString('eligibilityAPIErrorMessage'),
      });
    } finally {
      this.setState({ isInsuranceValidationLoading: false });
    }
  };

  getEnabledPaymentOptions = memoize((selectedServices, selectedClinic) => {
    const enabledPaymentOptions = {
      insurance:
        selectedServices.some(
          ({ subService }) => subService.isInsuranceEnabled,
        ) && selectedClinic.insurance !== false,
      noInsurance:
        selectedServices.every(
          ({ subService }) => subService.isNoInsuranceEnabled,
        ) && selectedClinic.noInsurance !== false,
      payAtTimeOfService:
        selectedServices.every(
          ({ service }) => service.isOutOfPocketEnabled,
        ),
    };

    return enabledPaymentOptions;
  });

  // Update global and Local State for group selection component
  onGroupAddressSelection = (option) => {
    const requiredFields = {
      county: option.county,
      state: option.state,
      zipCode: option.zipCode,
      city: option.city,
      homeAddress: option.homeAddress,
    };

    const selectedPatientAdressFrom = this.props.details.patientInfo.find(
      (p) =>
        p.county === option.county &&
        p.state === option.state &&
        p.zipCode === option.zipCode &&
        p.city === option.city &&
        p.homeAddress === option.homeAddress,
    );

    this.setState({
      address: {
        ...this.state.address,
        ...requiredFields,
      },
      errors: {
        ...this.state.errors,
        county: null,
        state: null,
        zipCode: null,
        city: null,
        homeAddress: null,
      },
      patientInfo: {
        ...this.state.patientInfo,
        ...requiredFields,
        addressFilledType:
          selectedPatientAdressFrom?.addressFilledType ||
          AddressFilledTypeOptions.Manual,
      },
    });
  };

  onGroupPaymentSelection = (option, discardPreviousState = {}) => {
    const InitialInsuranceInfoState = {
      ...IntialAppStateCovid.insuranceInfo[0],
    }; //initialize state with basic information
    const newInsuranceInfo = discardPreviousState?.insuranceInfo
      ? { ...InitialInsuranceInfoState, ...option.insuranceInfo }
      : {
          ...InitialInsuranceInfoState,
          ...this.state.insuranceInfo,
          ...option.insuranceInfo,
        };

    this.setState({
      paymentType: option.paymentType,
      insuranceInfo: newInsuranceInfo,
      insuranceType: option.insuranceType,
    });
  };

  onGroupContactSelection = (option) => {
    const { patientInfo: globalPatientInfo } = this.props.details;
    const patient = globalPatientInfo.find((patient) => {
      return (
        patient.mobileNo === option.mobileNo &&
        patient.email === option.email &&
        patient.confirmEmail === option.email
      );
    });
    const { patientInfo, errors } = this.state;
    if (patient) {
      this.setState({
        patientInfo: {
          ...patientInfo,
          mobileNo: option.mobileNo,
          email: option.email,
          confirmEmail: option.email,
          iisConsent: patient.iisConsent,
          smsNotificationConsent: patient.smsNotificationConsent,
          emailNotificationConsent: patient.emailNotificationConsent,
          marketingNotificationConsent: patient.marketingNotificationConsent,
        },
        errors: {
          ...errors,
          mobileNo: null,
          email: null,
          confirmEmail: null,
          iisConsent: null,
          smsNotificationConsent: null,
          emailNotificationConsent: null,
          marketingNotificationConsent: null,
        },
      });
    } else {
      this.setState({
        patientInfo: {
          ...patientInfo,
          mobileNo: option.mobileNo,
          email: option.email,
          confirmEmail: option.email,
        },
      });
    }
  };

  getInsuranceShareableProp = () => {
    let insuranceShareableObject = [];
    const {
      commonAppointmentDetails,
      patientInfo,
      insuranceInfo,
      noInsuranceInfo,
    } = this.props.details;
    const payerlist = this.props.getPayerList();
    insuranceShareableObject = Array(this.state.currentPatientIndex)
      .fill(0)
      .reduce((acc, p, idx) => {
        if (
          commonAppointmentDetails[idx].paymentType === PaymentOptions.Insurance
        ) {
          const obj = {
            paymentType: commonAppointmentDetails[idx].paymentType,

            patientFirstName: patientInfo[idx].firstName,
            patientLastName: patientInfo[idx].lastName,
            patientMiddleName: patientInfo[idx].middleName,
            insuranceInfo: insuranceInfo[idx],
            noInsuranceInfo: noInsuranceInfo[idx],
            company: this.getPayerForPayerId(
              payerlist,
              insuranceInfo[idx].company,
            ),
            paymentMethod: 'Insurance',
            insuranceType: commonAppointmentDetails[idx].insuranceType,
          };
          acc.push(obj);
        }
        return acc;
      }, []);
    return insuranceShareableObject;
  };

  onGroupInsuranceInfoChange = (key, value) => {
    this.onInsuranceInfoChange(key, value);
  };

  render() {
    const { getPayerList, details, primarySelection, selectedClinic } =
      this.props;
    const { patientInfo: globalPatientInfo } = details;

    if (!details.firstPageLoaded) return null;

    const {
      localizedWebContents,
      isLocalizedWebContentsFetching,
      organizationObj,
      loading,
      availableInsuranceTypes,
    } = details;

    const payerlist = getPayerList();

    const { showPayment } = this.props.getAppSettings();

    const {
      paymentType,
      insuranceType,
      patientInfo,
      errors,
      checkingEligibility,
      isSignatureCanvasOpen,
      isUnderMinimumAge,
      isAboveMaximumAge,
      isUnderEighteen,
      isUnderNineteen,
      noInsuranceInfo,
      insuranceInfo,
      acceptanceInfo,
      isAgeChecked,
      showMergeModal,
      isPatientRecordVerifying,
      isInsuranceEligible,
      insuranceEditParam,
      isInsuranceEligibilitySuccess,
      isAddressConfirmationModalOpen,
      isAddressVerificationModalOpen,
    } = this.state;

    const { selectedServices } = this.props;

    const iisConsentValidated = this.props.getAppSettings().isIISConsentEnabled
      ? patientInfo?.iisConsent
      : true;

    const selectedPayer = this.getPayerForPayerId(
      payerlist,
      insuranceInfo.company,
    );

    // Conditions for showing payment options on radio button
    const enabledPaymentOptions = this.getEnabledPaymentOptions(
      selectedServices,
      selectedClinic,
    );
    const isInsurancePayment =
      paymentType &&
      paymentType === PaymentOptions.Insurance &&
      enabledPaymentOptions.insurance;

    const isNoInsurancePayment =
      paymentType &&
      paymentType === PaymentOptions.NoInsurance &&
      enabledPaymentOptions.noInsurance;

    const isPayAtTimeOfServicePayment =
      paymentType &&
      paymentType === PaymentOptions.PayAtTimeOfService &&
      enabledPaymentOptions.payAtTimeOfService;

    const showPaymentContainer =
      enabledPaymentOptions.insurance ||
      enabledPaymentOptions.noInsurance ||
      enabledPaymentOptions.payAtTimeOfService;

    const errorSet = {};
    acceptanceInfoKeys.forEach((key) => {
      if (errors[key]) {
        errorSet[key] = errors[key];
      }
    });

    const areTermsAccepted = this.validateTermsAndConditions(
      acceptanceInfo.acceptedTerms,
    );
    const showTermsAndConditions = localizedWebContents.some(
      (content) =>
        content.termsAndCondition || content.termsAndConditionsAcceptance,
    );
    const isValidateInsuranceEligibilitySet =
      this.props.getAppSettings().isValidateInsuranceEligibilitySet &&
      !!selectedPayer?.enableEligibility;

    // TODO: memoize this check somehow (maybe convert to functional comp, or use a dirty flag instead of this check)
    const isEligiblityCheckInfoChanged =
      JSON.stringify(
        this.mapAppointmentToInsuranceEligibilityValidationParams(),
      ) !== JSON.stringify(this.state.previousValidationPayload);

    const isInsuranceEligibilityValid =
      !this.state.isInsuranceValidationLoading &&
      (isValidateInsuranceEligibilitySet &&
      paymentType &&
      paymentType === PaymentOptions.Insurance &&
      selectedPayer?.label !== 'Other'
        ? this.state.isInsuranceEligible !==
            ELIGIBILITY_VERIFICATION_STATUS.ALL_INVALID &&
          !isEligiblityCheckInfoChanged
        : true);

    const insuranceShareableProp = this.getInsuranceShareableProp();

    return (
      <>
        {/* <BackToPage text={getString('backToEligibilityAndHealthScreeningPage')} onClick={this.props.goBack} /> */}
        <ServicesInfoContainer
          confirmEditService={this.props.confirmEditService}
          disableEdit={this.props.disableEdit}
        />
        <div className="patient-info">
          <AlertMessage
            type="info"
            message={getString('provideContactInfoAlert')}
            className="mb-5x"
            isVisible="true"
            dataqa="provide-contact-info-alert"
          />
          <GroupPatientBasicInfo
            isInsuranceValidationLoading={
              this.state.isInsuranceValidationLoading
            }
            onChangeInput={this.onPatientInfoChange}
            patientInfo={patientInfo}
            errors={errors}
            isIISConsentEnabled={
              this.props.getAppSettings().isIISConsentEnabled
            }
            validateEmailonBlur={this.validateEmailonBlur}
            validateConfirmEmailonBlur={this.validateConfirmEmailonBlur}
          />

          <h2>Contact Info</h2>
          {this.state.currentPatientIndex === 0 ? (
            <Contact
              onPatientInfoChange={this.onPatientInfoChange}
              patientInfo={patientInfo}
              errors={errors}
              isIISConsentEnabled={
                this.props.getAppSettings().isIISConsentEnabled
              }
              validateEmailonBlur={this.validateEmailonBlur}
              validateConfirmEmailonBlur={this.validateConfirmEmailonBlur}
              clientName={this.state.organizationName}
            />
          ) : (
            <GroupContact
              onPatientInfoChange={this.onPatientInfoChange}
              patientInfo={patientInfo}
              errors={errors}
              isIISConsentEnabled={
                this.props.getAppSettings().isIISConsentEnabled
              }
              validateEmailonBlur={this.validateEmailonBlur}
              validateConfirmEmailonBlur={this.validateConfirmEmailonBlur}
              clientName={this.state.organizationName}
              contactList={this.props.details.patientInfo
                .filter((_, idx) => idx < this.state.currentPatientIndex)
                .map((patient) => ({
                  name: `${patient.firstName} ${patient.middleName} ${patient.lastName}`,
                  email: patient.email,
                  mobileNo: patient.mobileNo,
                }))}
              onGroupContactSelection={this.onGroupContactSelection}
            />
          )}
          <AlertMessage
            type="danger"
            message={primarySelection.subService.minAgeMessage}
            className="my-5x"
            isVisible={isUnderMinimumAge}
            dataqa="is-under-min-age-alert"
          />
          <AlertMessage
            type="danger"
            message={primarySelection.subService.minAgeMessage}
            className="my-5x"
            isVisible={isAboveMaximumAge}
          />
          <section className="section__margin my-5x">
            <h2>{getString('patientAddress')}</h2>
            {this.state.currentPatientIndex === 0 ? (
              <PatientAddressInfo
                isInsuranceValidationLoading={
                  this.state.isInsuranceValidationLoading
                }
                onChangeInput={this.onPatientInfoChange}
                patientInfo={patientInfo}
                errors={errors}
                stateOptions={this.state.stateOptions}
                countyOptions={this.state.countyOptions}
                onAddressSelect={this.handleAddressChange}
              />
            ) : (
              <GroupAddress
                isInsuranceValidationLoading={
                  this.state.isInsuranceValidationLoading
                }
                onChangeInput={this.onPatientInfoChange}
                patientInfo={patientInfo}
                errors={errors}
                // addressList={globalPatientInfo}
                addressList={this.props.details.patientInfo
                  .filter((_, idx) => idx < this.state.currentPatientIndex)
                  .map((patient) => ({
                    name: `${patient.firstName} ${patient.middleName} ${patient.lastName}`,
                    county: patient.county,
                    state: patient.state,
                    city: patient.city,
                    homeAddress: patient.homeAddress,
                    zipCode: patient.zipCode,
                  }))}
                stateOptions={this.props.details.states}
                countyOptions={this.state.countyOptions}
                onAddressSelect={this.handleAddressChange}
                onGroupAddressSelection={this.onGroupAddressSelection}
                currentPatientIndex={this.state.currentPatientIndex}
              />
            )}
          </section>

          {/* Here put the content */}
          <PatientPhysicianInfo
            isInsuranceValidationLoading={
              this.state.isInsuranceValidationLoading
            }
            onChangeInput={this.onPatientInfoChange}
            patientInfo={patientInfo}
            errors={errors}
          />

          {this.state.currentPatientIndex === 0 ||
          insuranceShareableProp.length === 0 ? (
            <>
              {showPaymentContainer && showPayment === PAYMENT_BOOLEAN.TRUE && (
                <section className="form-payment-type section__margin">
                  <h2>{getString('paymentType')}</h2>
                  <div className="patient-form-group border border-radius">
                    {organizationObj?.isPrepaidByOrganization ? (
                      <AlertMessage
                        type="info"
                        message={getString(
                          'prepaidByOrganization',
                          organizationObj.name,
                        )}
                        isVisible={true}
                        dataqa="prepaid-by-org-alert"
                      />
                    ) : (
                      <>
                        <PaymentType
                          onChange={this.onPaymentTypeChange}
                          value={paymentType}
                          insuranceType={insuranceType}
                          onInsuranceTypeChange={this.onInsuranceTypeChange}
                          errors={errors}
                          isValidateInsuranceEligibilitySet={
                            isValidateInsuranceEligibilitySet
                          }
                          localizedWebContents={localizedWebContents}
                          isInsuranceEnabled={enabledPaymentOptions?.insurance}
                          noInsuranceEnabled={
                            enabledPaymentOptions?.noInsurance
                          }
                          payAtTimeOfServiceEnabled={
                            enabledPaymentOptions?.payAtTimeOfService
                          }
                          availableInsuranceTypes={availableInsuranceTypes}
                        />

                        {isInsurancePayment && insuranceType && (
                          <InsuranceInput
                            onChange={this.onInsuranceInfoChange}
                            payerlist={payerlist}
                            insuranceInfo={insuranceInfo}
                            insuranceType={insuranceType}
                            patientInfo={patientInfo}
                            errors={errors}
                            setFieldError={this.setFieldError}
                            isInsuranceValidationLoading={
                              this.state.isInsuranceValidationLoading
                            }
                            insuranceEditParam={insuranceEditParam}
                            isInsuranceEligibilitySuccess={
                              isInsuranceEligibilitySuccess
                            }
                            isValidateInsuranceEligibilitySet={
                              isValidateInsuranceEligibilitySet
                            }
                            isInsuranceEligible={isInsuranceEligible}
                            insuranceValidationPayload={this.mapAppointmentToInsuranceEligibilityValidationParams()}
                            validateInsuranceEligibilityOfAppointment={
                              this.validateInsuranceEligibilityOfAppointment
                            }
                            previousValidationPayload={
                              this.state.previousValidationPayload
                            }
                            selectedPayer={selectedPayer}
                            selectedServices={selectedServices}
                          />
                        )}
                        {isNoInsurancePayment && (
                          <NoInsuranceForm
                            onChange={this.onInsuranceInfoChange}
                            noInsuranceInfo={noInsuranceInfo}
                            errors={errors}
                          />
                        )}
                      </>
                    )}

                    {isPayAtTimeOfServicePayment && (
                      <PayAtTimeOfService selections={selectedServices} />
                    )}
                  </div>
                </section>
              )}
            </>
          ) : (
            <GroupPayment
              showPaymentContainer={showPaymentContainer}
              showPayment={showPayment}
              // Payment Type
              onPaymentTypeChange={this.onPaymentTypeChange}
              insuranceType={insuranceType}
              onInsuranceTypeChange={this.onInsuranceTypeChange}
              isValidateInsuranceEligibilitySet={
                isValidateInsuranceEligibilitySet
              }
              errors={errors}
              localizedWebContents={localizedWebContents}
              enabledPaymentOptions={enabledPaymentOptions}
              availableInsuranceTypes={availableInsuranceTypes}
              paymentType={paymentType}
              // Insurance Props
              isInsurancePayment={isInsurancePayment}
              payerlist={payerlist}
              onInsuranceInfoChange={this.onInsuranceInfoChange}
              insuranceInfo={insuranceInfo}
              patientInfo={patientInfo}
              setFieldError={this.setFieldError}
              isInsuranceValidationLoading={
                this.state.isInsuranceValidationLoading
              }
              insuranceEditParam={insuranceEditParam}
              isInsuranceEligibilitySuccess={isInsuranceEligibilitySuccess}
              isInsuranceEligible={isInsuranceEligible}
              insuranceValidationPayload={this.mapAppointmentToInsuranceEligibilityValidationParams()}
              validateInsuranceEligibilityOfAppointment={
                this.validateInsuranceEligibilityOfAppointment
              }
              previousValidationPayload={this.state.previousValidationPayload}
              selectedPayer={selectedPayer}
              //No Insurance Props
              isNoInsurancePayment={isNoInsurancePayment}
              noInsuranceInfo={noInsuranceInfo}
              // At time of service
              isPayAtTimeOfServicePayment={isPayAtTimeOfServicePayment}
              selectedServices={selectedServices}
              // Group Selector Handlers
              onGroupInsuranceInfoChange={this.onInsuranceInfoChange}
              organizationObj={organizationObj}
              isInsuranceTypeShared={true}
              insuranceShareableProp={insuranceShareableProp}
              onGroupPaymentSelection={this.onGroupPaymentSelection}
            />
          )}

          {showTermsAndConditions && (
            <SkeletonLoadingContainer loading={isLocalizedWebContentsFetching}>
              <TermsAndConditions
                localizedWebContents={localizedWebContents}
                selectedServices={selectedServices}
                onChange={this.onAcceptanceInfoChange}
                value={acceptanceInfo.acceptedTerms ?? {}}
              />
            </SkeletonLoadingContainer>
          )}

          <div className="patient-form-group border-rounded section__margin">
            <SelectBox
              id="consentedBy"
              label="Consented By"
              value={this.state.consentedBy}
              required
              options={ConsentedByOptions}
              onChange={(e) => this.setState({ consentedBy: e.target.value })}
              dataqa="consented-by"
            />
          </div>

          {!isUnderMinimumAge && isUnderNineteen && (
            <PatientGuardianInfo
              onChangeInput={this.onPatientInfoChange}
              patientInfo={patientInfo}
              errors={errors}
            />
          )}
          {isAgeChecked && (
            <SignatureInput
              onDraw={this.onSaveSignature}
              isOpen={isSignatureCanvasOpen}
              onClick={this.setIsSignatureCanvasOpen}
              source={acceptanceInfo.signatureUrl}
              isUnderMinimumAge={isUnderMinimumAge}
              isUnderEighteen={isUnderEighteen}
              isUnderNineteen={isUnderNineteen}
            />
          )}

          <FormErrors errors={errorSet} />
          <AlertMessage
            dataqa="details-confirm-alert"
            type="info"
            message={getString('detailsConfirmAlert')}
            className="my-5x"
            isVisible={true}
          />

          <div className="row">
            <div
              className={`${styles['group-next-button']} col-12-sm order-2-sm`}
            >
              <LinkToPage
                onClick={this.handleSubmit}
                label={
                  isPatientRecordVerifying ? (
                    <ActivityIndicator className="loader--button loader--button--sm" />
                  ) : this.state.currentPatientIndex ===
                    globalPatientInfo.length - 1 ? (
                    getString('continueToConfirmation')
                  ) : (
                    getString('nextPatient')
                  )
                }
                title={getString('continueTitle')}
                enabled={
                  !checkingEligibility &&
                  areTermsAccepted &&
                  !loading &&
                  !isUnderMinimumAge &&
                  !isAboveMaximumAge &&
                  acceptanceInfo.signatureUrl &&
                  !isPatientRecordVerifying &&
                  isInsuranceEligibilityValid &&
                  iisConsentValidated
                }
                loading={loading || isPatientRecordVerifying}
                dataqa="go-to-clinic-timing"
              />
            </div>
          </div>
          {showMergeModal && (
            <VerifyPatientRecord
              saveAndMergePatientData={this.saveAndMergePatientData}
            />
          )}
          {isAddressConfirmationModalOpen && (
            <AddressConfirmationModal
              closeModal={this.closeAddressConfirmationModal}
              address={this.state.address}
              onEditAddress={this.handleEditAddress}
              onConfirmAddress={this.handleConfirmAddress}
            />
          )}

          {isAddressVerificationModalOpen && (
            <AddressVerificationModal
              closeModal={this.closeAddressVerificationModal}
              address={this.state.address}
              suggestions={this.state.addressSuggestions}
              onAddressChange={this.handleAddressChange}
              onConfirmAddress={this.handleConfirmAddress}
            />
          )}
        </div>
      </>
    );
  }
}

function withAddressSuggestionProps(Component) {
  return function WrappedComponent(props) {
    const { suggest } = useAddressSearch();
    return <Component {...props} suggest={suggest} />;
  };
}

export default withAddressSuggestionProps(GroupPatientInfo);
