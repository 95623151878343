import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { getString } from 'util/lang';
import { iconCheckboxChecked, iconCheckboxUnchecked } from 'assets/images';
import YesNoRadioPdfView from '../YesNoButtons/YesNoRadioPdfView';
import {
  settingKeyToQuestionMap,
  testingKeyToQuestionMap,
  initialTestingValues,
  initialSettingValues,
} from './ProductSettingAndTestingForm';

const LABEL_PREFIX = 'dme.deliveryTicket.settingAndTestingForm';

const ProductSettingAndTestingFormPdfView = ({
  settings = initialSettingValues,
  tests = initialTestingValues,
}) => (
  <View style={styles.container}>
    <View style={styles.wrap}>
      <Text style={styles.title}>{getString(`${LABEL_PREFIX}.title`)}</Text>
      <View style={styles.topWrap}>
        {settings?.map((setting) => {
          const { key, checked } = setting;
          const question = settingKeyToQuestionMap[key];
          return (
            <View key={key} style={styles.topQuestions}>
              {checked ? (
                <Image
                  src={iconCheckboxChecked}
                  alt="checkbox-checked"
                  style={styles.img}
                />
              ) : (
                <Image
                  src={iconCheckboxUnchecked}
                  alt="checkbox-unchecked"
                  style={styles.img}
                />
              )}
              <Text style={styles.p}>{question}</Text>
            </View>
          );
        })}
      </View>
      <View style={styles.bottomWrap}>
        {tests?.map((test) => {
          const { key, checked } = test;
          const question = testingKeyToQuestionMap[key];

          return (
            <View key={key} style={styles.bottomQuestions}>
              <Text style={{ ...styles.radioTextWrap, ...styles.p }}>
                {question}
              </Text>
              <View style={styles.bottomQuestion}>
                <YesNoRadioPdfView checked={checked} />
              </View>
            </View>
          );
        })}
      </View>
    </View>
  </View>
);

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 15
  },
  wrap: {
    display: 'flex',
    flexDirection: 'column',
  },
  topWrap: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 4,
  },
  topQuestions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 20,
    marginTop: 12,
  },
  bottomWrap: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 12,
  },
  bottomQuestions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 8,
    marginLeft: -2,
  },
  bottomQuestion: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  radioWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 12,
  },
  radioTextWrap: {
    minWidth: 315,
  },
  radioText: {
    fontSize: 9,
    fontWeight: 400,
    color: '#3B3A39',
    marginLeft: 5,
  },
  title: {
    fontSize: 11,
    fontWeight: 600,
  },
  img: {
    height: 13,
    width: 13,
  },
  p: {
    fontSize: 9,
    fontWeight: 400,
    color: '#3B3A39',
    marginLeft: 5,
  },
});

export default ProductSettingAndTestingFormPdfView;
