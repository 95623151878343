import cls from 'classnames';
import ReactTooltip from 'react-tooltip';
import { FiInfo } from 'vyaguta-icons/fi';
import { useCallback, useMemo, useEffect, useState } from 'react';

import SelectBox from '../../../../common/SelectBox';
import FormInput from '../../../../common/FormInput';
import FormErrors from '../../../../common/FormErrors';
import { arrayMove } from '../../../../../util/array';
import AlertMessage from '../../../common/AlertMessage';
import styles from './InsuranceInput.module.scss';
import { getString } from 'util/lang';

import {
  InsuranceInfoKeys,
  ShowAsRequiredFields,
  SubscriberRelationship,
  SubscriberRelationshipCode,
  InsurancePlanTypeOptions,
  InsuranceType,
  PaymentOptions,
} from '../../../../../constants/patientInfo';
import ImageUploader from 'components/common/ImageUploader';
import checkMultiserviceRxDetails from 'util/rxDetails';

/* Display and take input for the insurance info section */
/**
 *
 * @param {{
 *  insuranceEditParam: string[]}
 * } props
 */
const InsuranceInput = (props) => {
  const {
    onChange,
    insuranceInfo,
    insuranceType,
    errors,
    setFieldError,
    payerlist,
    selectedPayer,
    isInsuranceValidationLoading,

    onInsuranceTypeChange,
    validInsuranceTypeOptions,
    currentInsuranceErrors,
    value,
    isPolicyNumberInvalid,
    selectedServices,
  } = props;

  const [isSubscriberRelationshipSelf, setIsSubscriberRelationshipSelf] =
    useState(true);
  const [sortedPayerList, setSortedPayerList] = useState([]);
  const [selectedCompanyLabel, setSelectedCompanyLabel] = useState(
    selectedPayer?.label,
  );

  const collectRxDetails = checkMultiserviceRxDetails(selectedServices);

  const isMedicareInsurance = useMemo(() => {
    return (
      selectedPayer && selectedPayer.label.toLowerCase().includes('medicare')
    );
  }, [selectedPayer]);

  // Flag that controls whether to show all or only 'Self' option in the insurance plan type dropdown
  const allowAllRelationSelection =
    insuranceType === InsuranceType.Commercial ||
    InsuranceType.MilitaryAndVeterans;

  useEffect(() => {
    const otherOptionIndex = payerlist.findIndex((array) =>
      isOtherInsuranceLabel(array.label),
    );

    // Move the "Other" option as the last selection option
    if (otherOptionIndex >= 0) {
      const sortedPayerList = arrayMove(
        payerlist,
        otherOptionIndex,
        payerlist.length - 1,
      );

      setSortedPayerList(sortedPayerList);

      if (sortedPayerList.length === 1) {
        onChange('company', sortedPayerList[0]?.value);
      }
      return;
    }

    setSortedPayerList(payerlist);
  }, [payerlist]);

  useEffect(() => {
    const selectedCompanyLabel =
      selectedPayer?.label === 'Other' ? selectedPayer.label : '';
    addInsuranceInfoForPharmacyInsurance();
    setSelectedCompanyLabel(selectedCompanyLabel);
  }, [selectedPayer]);

  const addInsuranceInfoForPharmacyInsurance = useCallback(() => {
    if (!!collectRxDetails || isMedicareInsurance) {
      insuranceInfo.rxBin = insuranceInfo?.rxBin || '';
      insuranceInfo.rxPCN = insuranceInfo?.rxPCN || '';
      insuranceInfo.rxGroup = insuranceInfo?.rxGroup || '';
      if (isMedicareInsurance)
        insuranceInfo.medicareID = insuranceInfo?.medicareID || '';
      if (isMedicareInsurance)
        insuranceInfo.medicareIssuer = insuranceInfo?.medicareIssuer || '';
    } else {
      delete insuranceInfo?.medicareID;
      delete insuranceInfo?.rxBin;
      delete insuranceInfo?.rxPCN;
      delete insuranceInfo?.rxGroup;
      delete insuranceInfo?.medicareIssuer;
    }
  }, [selectedPayer, isMedicareInsurance]);
  useEffect(() => {
    // This is done to make sure that "insuranceInfo" has the key "otherInsuranceCompany" when "Other" value is selected,
    // and yup validation can be done for this key.
    if (isOtherInsuranceLabel(selectedCompanyLabel)) {
      insuranceInfo.otherInsuranceCompany =
        insuranceInfo?.otherInsuranceCompany || '';
    }
    // Also, remove the key "otherInsuranceCompany" from "insuranceInfo" when the "Other" value is unselected,
    // so that validation is removed for this key.
    else {
      delete insuranceInfo?.otherInsuranceCompany;
    }

    if (allowAllRelationSelection) {
      insuranceInfo.insurancePlanType = insuranceInfo?.insurancePlanType || '';
    }
    // Also, remove the key "insurancePlanType" from "insuranceInfo" when the insurance type commercial is selected,
    // so that validation is removed for this key.
    else {
      delete insuranceInfo?.insurancePlanType;
    }
  }, [insuranceType, selectedCompanyLabel, allowAllRelationSelection]);

  useEffect(() => {
    if (
      parseInt(insuranceInfo.subscriberRelationship) ===
        SubscriberRelationshipCode.Self ||
      insuranceInfo.subscriberRelationship === ''
    ) {
      delete insuranceInfo.cardHolderFirstName;
      delete insuranceInfo.cardHolderLastName;
      setIsSubscriberRelationshipSelf(true);
    } else {
      insuranceInfo.cardHolderFirstName =
        insuranceInfo?.cardHolderFirstName || '';
      insuranceInfo.cardHolderLastName =
        insuranceInfo?.cardHolderLastName || '';
      setIsSubscriberRelationshipSelf(false);
    }
  }, [insuranceInfo.subscriberRelationship]);

  const isOtherInsuranceLabel = (selectedLabel) => {
    if (!selectedLabel) return false;
    return (
      selectedLabel.toLowerCase() === 'other' ||
      selectedLabel.toLowerCase() === 'others'
    );
  };

  const handleInsuranceCardFrontChange = (value) => {
    insuranceInfo.insuranceCardFront = value;
  };

  const handleInsuranceCardBackChange = (value) => {
    insuranceInfo.insuranceCardBack = value;
  };

  const errorSet = useMemo(() => {
    const tempErrorSet = {};
    InsuranceInfoKeys.forEach((key) => {
      if (errors[key]) {
        tempErrorSet[key] = errors[key];
      }
    });
    return tempErrorSet;
  }, [errors]);

  const isMedicareOrMedicaid = [
    InsuranceType.Medicare,
    InsuranceType.MedicareAdvantage,
    InsuranceType.Medicaid,
  ].includes(insuranceType);

  const filteredSubscriberRelationships = useMemo(() => {
    return isMedicareOrMedicaid
      ? SubscriberRelationship.filter(
          ({ value }) => value === SubscriberRelationshipCode.Self,
        )
      : SubscriberRelationship;
  }, [isMedicareOrMedicaid]);

  useEffect(() => {
    if (
      isMedicareOrMedicaid &&
      insuranceInfo.subscriberRelationship !==
        SubscriberRelationshipCode.Self.toString()
    ) {
      onChange(
        'subscriberRelationship',
        SubscriberRelationshipCode.Self.toString(),
      );
    }
  }, [isMedicareOrMedicaid, insuranceInfo.subscriberRelationship, onChange]);

  return (
    // <section className="form-patient-address section__margin">
    <div>
      <div
        className={cls(
          styles.paymentBorderControl,
          `patient-form-group border-rounded p-0x ${isMedicareInsurance ? 'overflow-visible' : ''}`,
        )}
      >
        <div className={cls('row')}>
          <div className={cls(styles.mainInsuranceInput, 'row')}>
            <div className={`col-12-md fs-mask`}>
              <SelectBox
                label={getString('insuranceType')}
                id="insuranceType"
                defaultValue=""
                onChange={onInsuranceTypeChange}
                options={validInsuranceTypeOptions}
                value={insuranceType}
                hasError={!!currentInsuranceErrors['insuranceType']}
                required={value === PaymentOptions.Insurance}
                isDisabled={isInsuranceValidationLoading}
                inputClassName={styles.insuranceTypeInput}
                dataqa="insurance-type"
              />
            </div>

            <div
              className={`col-${allowAllRelationSelection ? '6' : '12'}-md fs-mask`}
            >
              <SelectBox
                label={getString('insuranceCompany')}
                id="company"
                onChange={(e) => onChange('company', e.target.value)}
                defaultValue=""
                options={sortedPayerList}
                value={insuranceInfo.company}
                hasError={!!errorSet['company']}
                required={ShowAsRequiredFields['company']}
                isDisabled={isInsuranceValidationLoading}
                dataqa="insurance-company"
                inputClassName={cls(styles.borderControl)}
              />
            </div>
            {allowAllRelationSelection && (
              <div className="col-6-md fs-mask">
                <SelectBox
                  label={getString('insurancePlanType')}
                  id="insurancePlanType"
                  onChange={(e) =>
                    onChange('insurancePlanType', e.target.value)
                  }
                  defaultValue=""
                  options={InsurancePlanTypeOptions}
                  value={parseInt(insuranceInfo.insurancePlanType)}
                  hasError={!!errorSet['insurancePlanType']}
                  required={ShowAsRequiredFields['insurancePlanType']}
                  isDisabled={isInsuranceValidationLoading}
                  inputClassName={'border-left border-right-0'}
                />
              </div>
            )}
            <div className="col-12-md fs-mask">
              <SelectBox
                label={getString('subscriberRelationship')}
                id="subscriberRelationship"
                onChange={(e) =>
                  onChange('subscriberRelationship', e.target.value)
                }
                defaultValue=""
                options={filteredSubscriberRelationships}
                value={parseInt(insuranceInfo.subscriberRelationship)}
                hasError={!!errorSet['subscriberRelationship']}
                required={ShowAsRequiredFields['subscriberRelationship']}
                inputClassName={cls(styles.borderControl)}
                isDisabled={isInsuranceValidationLoading}
                dataqa="subscriber-relationship"
              />
            </div>

            <div className="col-12-md fs-mask">
              <FormInput
                label={getString('insuranceCompanyName')}
                id="otherInsuranceCompany"
                name="otherInsuranceCompany"
                onChange={onChange}
                value={insuranceInfo.otherInsuranceCompany}
                hasError={!!errorSet['otherInsuranceCompany']}
                required={ShowAsRequiredFields['otherInsuranceCompany']}
                isVisible={isOtherInsuranceLabel(selectedCompanyLabel)}
                disableInput={isInsuranceValidationLoading}
                dataqa="other-insurance-company"
              />
            </div>

            {!!isMedicareInsurance && (
              <div className="col-12-md hide-border fs-mask subtitle">
                <h4 className="mt-3x">{getString('medicarePartA')}</h4>
              </div>
            )}

            <div
              className={`col-3-md fs-mask ${isMedicareInsurance ? 'border-top' : ''}`}
            >
              <FormInput
                label={getString('memberIdPrefix')}
                id="memberIDPrefix"
                name="memberIDPrefix"
                onChange={onChange}
                value={insuranceInfo.memberIDPrefix}
                hasError={
                  !!errorSet['memberIDPrefix'] ||
                  (isPolicyNumberInvalid && insuranceInfo.memberIDPrefix)
                }
                disableInput={isInsuranceValidationLoading}
                data-qa="member-id-prefix"
              />
            </div>
            <div
              className={`col-6-md fs-mask ${isMedicareInsurance ? 'border-top' : ''}`}
            >
              <FormInput
                label={
                  !isMedicareInsurance
                    ? getString('memberId')
                    : getString('memberIdMedicare')
                }
                id="memberID"
                name="memberID"
                onChange={onChange}
                value={insuranceInfo.memberID}
                hasError={!!errorSet['memberID'] || isPolicyNumberInvalid}
                required={ShowAsRequiredFields['memberID']}
                disableInput={isInsuranceValidationLoading}
                data-qa="member-id"
              />
            </div>
            <div
              className={`col-3-md fs-mask ${isMedicareInsurance ? 'border-top' : ''}`}
            >
              <FormInput
                label={getString('memberIdSuffix')}
                id="memberIDSuffix"
                name="memberIDSuffix"
                onChange={onChange}
                value={insuranceInfo.memberIDSuffix}
                hasError={
                  !!errorSet['memberIDSuffix'] ||
                  (isPolicyNumberInvalid && insuranceInfo.memberIDSuffix)
                }
                disableInput={isInsuranceValidationLoading}
                data-qa="member-id-suffix"
                inputClassName={styles.borderControl}
              />
            </div>
            {!isMedicareInsurance && (
              <div className="col-12-md fs-mask">
                <FormInput
                  label={getString('groupNumber')}
                  id="groupNumber"
                  name="groupNumber"
                  onChange={onChange}
                  value={insuranceInfo.groupNumber}
                  hasError={!!errorSet['groupNumber']}
                  required={ShowAsRequiredFields['groupNumber']}
                  data-qa="member-id-prefix"
                  inputClassName={cls(styles.groupNumber, styles.borderControl)}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {(!!collectRxDetails || isMedicareInsurance) && (
        <div>
          {!isMedicareInsurance && (
            <AlertMessage
              type="info"
              className={'mt-5x mb-5x'}
              message={
                <span>{getString('pharmacyInsuranceExtraInfoText')}</span>
              }
              isVisible={true}
              dataqa="pharmacy-insurance-extra-info-text"
            />
          )}
          {isMedicareInsurance && (
            <div className="fs-mask subtitle">
              <h4>{getString('medicarePartD')}</h4>
            </div>
          )}
          <div className="patient-form-group border-rounded p-0x">
            <div className="row">
              {isMedicareInsurance && (
                <div className="col-12-md fs-mask border-top">
                  <FormInput
                    label={getString('id')}
                    id="medicareID"
                    name="medicareID"
                    type="text"
                    onChange={onChange}
                    value={insuranceInfo.medicareID}
                    hasError={!!errorSet['medicareID']}
                    isVisible={!!isMedicareInsurance}
                    data-qa="medicare-id"
                  />
                </div>
              )}
              <div className="col-12-md fs-mask">
                <FormInput
                  label={getString('rxBin')}
                  id="rxBin"
                  name="rxBin"
                  type="number"
                  onChange={onChange}
                  value={insuranceInfo.rxBin}
                  hasError={!!errorSet['rxBin']}
                  data-qa="pharmacy-insurance-rxbin"
                />
              </div>
              <div className="col-12-md fs-mask">
                <FormInput
                  label={getString('rxPCN')}
                  id="rxPCN"
                  name="rxPCN"
                  onChange={onChange}
                  value={insuranceInfo.rxPCN}
                  hasError={!!errorSet['rxPCN']}
                  data-qa="pharmacy-insurance-rxpcn"
                />
              </div>
              <div className="col-12-md fs-mask">
                <FormInput
                  label={getString('rxGroup')}
                  id="rxGroup"
                  name="rxGroup"
                  onChange={onChange}
                  value={insuranceInfo.rxGroup}
                  hasError={!!errorSet['rxGroup']}
                  data-qa="pharmacy-insurance-rxgroup"
                />
              </div>
              {isMedicareInsurance && (
                <div className="col-12-md fs-mask">
                  <FormInput
                    label={getString('issuer')}
                    id="medicareIssuer"
                    name="medicareIssuer"
                    type="text"
                    onChange={onChange}
                    value={insuranceInfo.medicareIssuer}
                    hasError={!!errorSet['medicareIssuer']}
                    isVisible={!!isMedicareInsurance}
                    data-qa="pharmacy-medicare-issuer"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {!isSubscriberRelationshipSelf && (
        <div>
          <h3 tabIndex="0" className="mt-4x d-flex align-items-center ml-3x">
            {getString('primaryCardHolderHeading')}
            <FiInfo
              data-tip
              data-for="registerTip"
              className="ml-3x cursor-pointer"
            />
            <ReactTooltip id="registerTip" place="top" effect="solid">
              {getString('cardHolderToolTipDetail')}
            </ReactTooltip>
          </h3>
          <div className={`patient-form-group border-rounded p-0x`}>
            <div className="row">
              <div className={`col-4-md fs-mask`}>
                <FormInput
                  label={getString('cardHolderFirstName')}
                  id="cardHolderFirstName"
                  name="cardHolderFirstName"
                  onChange={onChange}
                  value={insuranceInfo.cardHolderFirstName}
                  hasError={!!errorSet['cardHolderFirstName']}
                  required={ShowAsRequiredFields['cardHolderFirstName']}
                  disableInput={isInsuranceValidationLoading}
                  data-qa="card-holder-first-name"
                />
              </div>
              <div className={`col-4-md fs-mask`}>
                <FormInput
                  label={getString('cardHolderMiddleName')}
                  id="cardHolderMiddleName"
                  name="cardHolderMiddleName"
                  onChange={onChange}
                  value={insuranceInfo.cardHolderMiddleName}
                  hasError={!!errorSet['cardHolderMiddleName']}
                  disableInput={isInsuranceValidationLoading}
                  data-qa="card-holder-middle-name"
                />
              </div>
              <div className={`col-4-md fs-mask`}>
                <FormInput
                  label={getString('cardHolderLastName')}
                  id="cardHolderLastName"
                  name="cardHolderLastName"
                  onChange={onChange}
                  value={insuranceInfo.cardHolderLastName}
                  hasError={!!errorSet['cardHolderLastName']}
                  required={ShowAsRequiredFields['cardHolderLastName']}
                  disableInput={isInsuranceValidationLoading}
                  data-qa="card-holder-last-name"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={cls('pl-4x pr-4x pt-4x pb-4x', styles.insuranceImages)}>
        <h4>{getString('insuranceCardHeading')}</h4>
        <p className="insurance-card-sub-header mt-neg-1x">
          {getString('insuranceCardSubHeading')}
        </p>
        <div className="image-uploader-wrapper">
          <div className=" d-flex">
            <div>
              <span className="insurance-card-sub-header">
                {' '}
                {getString('cardLabelFront')}
              </span>
              <ImageUploader
                name="insuranceCardFront"
                previewFile={insuranceInfo.insuranceCardFront}
                getValue={handleInsuranceCardFrontChange}
                onError={setFieldError}
                dataqa="insurance-card-front"
              />
            </div>
            <div className="ml-6x">
              <span className="insurance-card-sub-header">
                {getString('cardLabelBack')}
              </span>
              <ImageUploader
                name="insuranceCardBack"
                previewFile={insuranceInfo.insuranceCardBack}
                getValue={handleInsuranceCardBackChange}
                onError={setFieldError}
                dataqa="insurance-card-back"
              />
            </div>
          </div>
        </div>
      </div>

      <FormErrors errors={errorSet} />
    </div>
  );
};

export default InsuranceInput;
