import * as toast from 'util/toast';
import * as Regex from 'util/regex';
import { getString } from 'util/lang';
import { flushSync } from 'react-dom';
import React, { createRef } from 'react';
import { memoize } from 'util/memoize-one';
import objectUtils from 'util/objectUtils';
import DateTimeUtils from 'util/DateAndTime';
import { limitChars } from 'util/TextFormat';
import styles from './PaymentInfo.module.scss';
import {
  getIterable,
  removeArrayElementByIndex,
  removeElementFromArray,
  replaceArrayElementByIndex,
  unique,
} from 'util/array';
import config from '../../../../../config';

import {
  ConsentedBy,
  PaymentOptions,
  NoInsuranceInfoKeys,
  InsuranceCoverageTypes,
  MaxNoOfInsuranceCoverages,
  SubscriberRelationshipKeys,
  SubscriberRelationshipCode,
  TotalNoOfInsuranceCoverages,
  AvailableInsuranceCoverageIndices,
  InsuranceType,
} from 'constants/patientInfo';
import { CovidPageNames } from 'constants/pages';
import PAYMENT_BOOLEAN from 'constants/showPaymentBoolean';

import FormErrors from '../../../../common/FormErrors';
import LinkToPage from '../../../../common/LinkToPage';
import AlertMessage from '../../../common/AlertMessage';
import BackToPage from 'components/COVID/common/BackToPage/BackToPage';

import PaymentType from '../presentation/PatientInsurance';
import NoInsuranceForm from '../presentation/NoInsuranceForm';
import PayAtTimeOfService from '../presentation/PayAtTimeOfServiceInfoBox';

import { NoInsuranceDetailsValidation } from './NoInsuranceDetailsValidation';
import { InsuranceDetailsValidation } from '../../PatientInformation/container/PatientInfoValidation';

import cls from 'classnames';
import { validateInsuranceEligibilityForMultiple } from 'services/insuranceEligibility';
import { useAddressSearch } from 'hooks/useAddressSearch';
import { ServicesInfoContainer } from 'components/COVID/common/ServicesInfo';
import { InitialInsuranceState } from '../../../../../constants/app';
import { clamp } from '../../../../../util/math';
import Button from '../../../../common/Button';
import { InsurancesList } from '../presentation/InsurancesList';
import PatientBasicInfo from '../../PatientInformation/presentation/PatientBasicInfo';
import regexPattern from '../../../../../constants/regexPattern';
import { verifyPatientRecord } from '../../../../../services/patient';
import VerifyPatientRecord from '../../PatientInformation/presentation/VerifyPatientRecord';
import {
  compareStrings,
  formatMultipleWordsWithAnd,
} from '../../../../../util/string';
import { ignoreCallback } from '../../../../../util/funcUtils';
import ActivityIndicator from '../../../../common/ActivityIndicator';
import { isFalseOrUndefined, isTrueOrNull } from '../../../../../util/boolean';
import { getRandomNDigitDecimalNumber } from '../../../../../util/random';
import { CountDownTimer } from '../presentation/countDown';
import {
  DISABLING_TIMER_VALUE,
  ELIGIBILITY_VERIFICATION_STATUS,
  INSURANCE_ELIGIBILITY_VALIDATION_COUNT,
} from '../../../../../constants/insuranceEligibility';
import checkMultiserviceRxDetails from 'util/rxDetails';

class PaymentInfo extends React.Component {
  constructor(props) {
    super(props);
    this.mainContentRef = createRef();
    this.totalNoOfServicesInNonMultiServiceApt = 1;
    this.totalNoOfServicesInMultiServiceApt = 2;
    this.allSubscriberRelationshipKeys = Object.keys(
      SubscriberRelationshipCode,
    );
    this.validationAlertKeys = {
      dob: 'dob',
      patientName: 'patientName',
      policyNumber: 'policyNumber',
    }; // these errors show a specific alert message on insurance verification fail
    this.insuranceIdLength = 15;
    const insuranceErrors = {
      insuranceDataErrors: [],
      insurancePolicyNumberVerificationErrors: [],
      insurancePatientVerificationErrors: [],
    };
    this.eligibilityRequestCount = 0;
    this.lastEligibilityRequestStartTime = new Date().getTime();

    this.insuranceErrorKeys = Object.keys(insuranceErrors);
    this.patientErrorDisplayValues = {
      [this.validationAlertKeys.patientName]: getString('name'),
      [this.validationAlertKeys.dob]: getString('dob'),
    };
    this.insuranceErrorDisplayValues = {
      [this.validationAlertKeys.policyNumber]: getString('memberIdLabel'),
    };

    const currentPatientIndex = this.props.details.currentPatientIndex;
    const insuranceCoverageList =
      this.props.details.insuranceCoverageList?.[currentPatientIndex] ?? [];
    const commonAppointmentDetails =
      this.props.details?.commonAppointmentDetails?.[currentPatientIndex];
    this.state = {
      currentPatientIndex,
      isFollowup: this.props.isFollowup,
      patientInfo: {},
      noInsuranceInfo: {},
      insuranceInfo: this.getInitialInsuranceInfoState(),
      insuranceType: this.getInitialInsuranceTypeState(),
      errors: { ...insuranceErrors },
      checkingEligibility: false,
      paymentType: null,

      patientId: null,

      isInsuranceEligible: false,
      insuranceEditParam: [],
      previousValidationPayload: {},
      isInsuranceValidationLoading: false,
      typeOfServiceCodes: [],
      showMergeModal: false,

      isUnderMinimumAge: false,
      isAboveMaximumAge: false,
      isUnderEighteen: false,
      isUnderNineteen: false,
      consentedBy: commonAppointmentDetails?.consentedBy ?? ConsentedBy.PATIENT,

      phicureResponse: [],
      insuranceCoverageList: insuranceCoverageList,
      eligibilityDisabledPayerExists: false, // given set of insurance details has a payer with eligibility disabled

      currentInsuranceIndex:
        AvailableInsuranceCoverageIndices.primaryInsuranceIndex,
      isInsuranceInputModalOpen: false,
      savedInsuranceInputState: {},
      totalNoOfInsurances: 1,
      isRecordVerified:
        this.props.details.patientRecordInfo[currentPatientIndex]
          ?.isRecordVerified,
      isRequiredFormDataFilled: [],
      isPatientInfoFilled: true,
      isOutOfPocketRequiredForPayment: false, // out of pocket is required when all insurance
      // verifications fails without specific errors from eligibility api(in editInfo field)
      insuranceWithDuplicateDataExists: false,
      isEligibilityApiError: false,
      startEligibilityRequestLimitingTimer: false,
      eligibilityInfoMessageAfterTimer: false,
    };
    // this.topRef = React.createRef();
  }

  /**
   * When component mounts, obtain state from parent and set it to component state.
   * If state keys are unavailable in parent, default state set from constructor is used
   */
  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      insuranceInfo,
      patientInfo,
      noInsuranceInfo,
      commonAppointmentDetails,
      appLanguageCode,
      phicureResponse,
      currentPatientIndex,
    } = this.props.details;

    const { selectedClinic, selectedServices } = this.props;
    const { paymentType } = commonAppointmentDetails[currentPatientIndex] ?? {};
    const { patientId } =
      this.props.details.patientRecordInfo[currentPatientIndex] ?? {};
    const currentPatient = patientInfo[currentPatientIndex] ?? {};
    const updatedInsuranceInfo = insuranceInfo
      .map((e) => {
        return {
          ...e,
          id: getRandomNDigitDecimalNumber(this.insuranceIdLength),
        };
      })
      .concat(
        this.getInitialInsuranceInfoState(
          TotalNoOfInsuranceCoverages - insuranceInfo.length,
        ),
      ); // maintain a constant length insurance data
    const updatedInsuranceType = updatedInsuranceInfo.map(
      (eachInsuranceInfo) => eachInsuranceInfo?.insuranceType,
    );
    const insuranceLength = updatedInsuranceType?.filter((e) => e).length;

    const isRequiredFormDataFilled = updatedInsuranceType.map(
      (eachInsuranceType, insuranceIndex) => {
        return this.getSingleInsuranceInformationFilledStatus(
          insuranceIndex,
          insuranceInfo[insuranceIndex],
          eachInsuranceType,
        );
      },
    );

    this.setState({
      patientInfo: {
        ...currentPatient,
      },
      insuranceInfo: updatedInsuranceInfo,
      phicureResponse: phicureResponse[currentPatientIndex] ?? [],
      typeOfServiceCodes: selectedServices.map(
        ({ service }) => service.typeOfServiceCode,
      ),
      noInsuranceInfo: noInsuranceInfo[currentPatientIndex] ?? {},
      isRequiredFormDataFilled,
      paymentType: this.getSelectedPaymentType(
        paymentType,
        insuranceInfo[this.getCurrentInsuranceIndex()].paymentType,
        selectedServices.some(
          ({ subService }) => subService.isInsuranceEnabled,
        ),
        selectedServices.every(
          ({ subService }) => subService.isNoInsuranceEnabled,
        ),
        selectedServices.every(
          ({ service }) => service.isOutOfPocketEnabled,
        ),
        selectedClinic?.smvs_insurance,
        selectedClinic?.smvs_no_insurance,
        selectedClinic?.smvs_pay_at_the_time_of_service,
      ),
      insuranceType: updatedInsuranceType,
      totalNoOfInsurances: insuranceLength > 0 ? insuranceLength : 1,
      appLanguageCode,
      patientId,
      isInsuranceEligible: false,
      insuranceEditParam: [],
    });
  }

  /**
   * Get the default or selected payment type.
   *
   * @param {number} paymentTypeInAppointment
   * @param {number} userSelectedPaymentType
   * @param {number} insuranceStatusInSelectedVaccine
   *
   * @returns : selected payment type
   */
  getSelectedPaymentType(
    paymentTypeInAppointment,
    userSelectedPaymentType,
    insuranceStatusInSelectedVaccine,
    noInsuranceStatusInSelectedVaccine,
    payAtTimeOfServiceStatusInSelectedVaccine,
    insuranceStatusInClinic,
    noInsuranceStatusInClinic,
    payAtTimeOfServiceStatusInClinic,
  ) {
    if (paymentTypeInAppointment) {
      return paymentTypeInAppointment;
    }

    if (userSelectedPaymentType) {
      return userSelectedPaymentType;
    }

    if (insuranceStatusInSelectedVaccine && insuranceStatusInClinic !== false) {
      return PaymentOptions.Insurance;
    }

    if (
      noInsuranceStatusInSelectedVaccine &&
      noInsuranceStatusInClinic !== false
    ) {
      return PaymentOptions.NoInsurance;
    }

    if (
      payAtTimeOfServiceStatusInSelectedVaccine &&
      payAtTimeOfServiceStatusInClinic !== false
    ) {
      return PaymentOptions.PayAtTimeOfService;
    }

    const { noInsuranceEnabled } = this.props.getAppSettings();

    if (noInsuranceEnabled) {
      return PaymentOptions.NoInsurance;
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.details.appLanguageCode !==
        this.props.details.appLanguageCode &&
      !objectUtils.isEmpty(this.state.errors)
    ) {
      this.checkFormValidity(true);
    }
  }

  setCurrentInsuranceIndex = (index) => {
    const currentIndex = clamp(
      AvailableInsuranceCoverageIndices.primaryInsuranceIndex,
      AvailableInsuranceCoverageIndices.maxOtherInsuranceIndex,
      index,
    );

    this.setState({ currentInsuranceIndex: currentIndex });
  };

  setFieldError = (key, value) => {
    this.setState({
      errors: { ...this.state.errors, [key]: value },
    });
  };

  /**
   * See if a find operation for the patient record has been executed
   * Keeps track of whether the patient's information has changed from the last
   * find operation executed.
   */
  isPatientRecordVerified = () => {
    return this.state.isRecordVerified;
  };

  isInsuranceValidationInProgress = () => {
    return this.state.isInsuranceValidationLoading;
  };

  /**
   * Handle payment type change (i.e. Insurance, no insurance, out ot pocket)
   * @param {event} e : Input change event
   */
  onPaymentTypeChange = (e) => {
    const paymentTypeValue = e.target.value;

    const emptyNoInsuranceInfo = this.resetNoInsuranceInfo();
    const { errors, noInsuranceInfo } = this.state;

    const noInsuranceError = this.resetErrorBulk(Object.keys(noInsuranceInfo));
    const newErrors = {
      ...noInsuranceError,
      insuranceDataErrors: [],
      insurancePolicyNumberVerificationErrors: [],
      insurancePatientVerificationErrors: [
        ...errors.insurancePatientVerificationErrors,
      ],
    };

    if (paymentTypeValue) {
      this.setState({
        paymentType: paymentTypeValue,
        insuranceInfo: this.getInitialInsuranceInfoState(),
        insuranceType: this.getInitialInsuranceTypeState(),
        noInsuranceInfo: emptyNoInsuranceInfo,
        errors: newErrors,
        isOutOfPocketRequiredForPayment: false,
        phicureResponse: [],
        insuranceCoverageList: [],
        isRequiredFormDataFilled: [],
        eligibilityDisabledPayerExists: false,
        isEligibilityApiError: false,
        eligibilityInfoMessageAfterTimer: false,
      });
    }
  };

  /**
   * Handle payment type change (e.g. Medicare, Commercial, Medicaid etc)
   * @param {event} e : Input change event
   */
  onInsuranceTypeChange = (e) => {
    const selectedInsuranceType = e.target.value;

    const { insuranceInfo: allInsuranceInfo, insuranceType: allInsuranceType } =
      this.state;
    const currentInsuranceIndex = this.getCurrentInsuranceIndex();
    const insuranceInfo = allInsuranceInfo[currentInsuranceIndex];

    const newErrors = this.resetCurrentInsuranceErrorBulk(
      Object.keys(insuranceInfo),
    );

    if (selectedInsuranceType) {
      this.props.fetchPayerList(selectedInsuranceType, currentInsuranceIndex);
      this.setState({
        insuranceType: replaceArrayElementByIndex(
          allInsuranceType,
          currentInsuranceIndex,
          selectedInsuranceType,
        ),
        insuranceInfo: replaceArrayElementByIndex(
          allInsuranceInfo,
          currentInsuranceIndex,
          this.getInsuranceInfoDefaultValue(),
        ),
        isRequiredFormDataFilled: replaceArrayElementByIndex(
          this.state.isRequiredFormDataFilled,
          currentInsuranceIndex,
          false,
        ),
        insuranceCoverageList: replaceArrayElementByIndex(
          this.state.insuranceCoverageList,
          currentInsuranceIndex,
          [],
        ),
        eligibilityInfoMessageAfterTimer: false,
        errors: {
          ...newErrors,
          insurancePolicyNumberVerificationErrors: replaceArrayElementByIndex(
            this.state.errors.insurancePolicyNumberVerificationErrors,
            currentInsuranceIndex,
            null,
          ),
        },
        phicureResponse: replaceArrayElementByIndex(
          this.state.phicureResponse,
          currentInsuranceIndex,
          [],
        ),
      });
    }
  };

  /**
   * Format input if needed, based on the keys
   * If key has an invalid input, returns null
   * @param {string} key
   * @param {string} value
   */
  formatFormInput = (key, value) => {
    switch (key) {
      case 'birthYear':
        if (isNaN(value)) return;
        break;
      case 'memberID':
        value = value.trimStart();
        value = value.toUpperCase();
        value = value.replace(/ +/g, '');
        value = limitChars(value, 100);
        break;
      case 'groupNumber':
        value = limitChars(value, 100);
        break;
      case 'firstName':
        value = value.trimStart();
        value = value.replace(/  +/g, ' ');
        value = limitChars(value, 30);
        break;
      case 'middleName':
        value = value.trimStart();
        value = value.replace(/  +/g, ' ');
        value = limitChars(value, 30);
        break;
      case 'lastName':
        const startTrimed = value.trimStart();
        value = startTrimed.replace(/  +/g, ' ');
        value = limitChars(value, 30);
        break;
      case 'rxBin':
        if (!Regex.isNumber(value)) return;
        value = limitChars(value, 6);
        break;
      default:
        break;
    }

    return value;
  };

  /**
   *   Description: Check if required details are valid
   *  @param  {object} patientData: Patient form inputs that are to be validated
   *  @return {object} errors : object with keys for all field inputs provided and respective error values
   *  @return {bool} isValid : true if all inputs are valid else false
   */
  validatePatientAndPaymentData = (patientData, insuranceIndex) => {
    // let { errors } = ValidatePatientInfo(patientData);
    let errors = {};

    // only run insurance validation if showPayment is set to true
    const { showPayment } = this.props.getAppSettings();

    const { selectedServices, selectedClinic } = this.props;

    const validateInsuranceForService =
      selectedServices.some(
        ({ subService }) => subService.isInsuranceEnabled,
      ) ||
      selectedServices.every(
        ({ subService }) => subService.isNoInsuranceEnabled,
      ) ||
      selectedClinic.smvs_insurance !== false ||
      selectedClinic.smvs_no_insurance !== false ||
      selectedClinic.smvs_pay_at_the_time_of_service !== false;

    const {
      paymentType,
      noInsuranceInfo,
      insuranceInfo: allInsuranceInfo,
      insuranceType: allInsuranceTypes,
    } = this.state;

    const currentInsuranceIndex =
      insuranceIndex ?? this.getCurrentInsuranceIndex(); // from here
    const insuranceInfo = allInsuranceInfo[currentInsuranceIndex];
    const insuranceType = allInsuranceTypes[currentInsuranceIndex];
    const collectRxDetails = checkMultiserviceRxDetails(selectedServices);

    const organizationObj = this.props.details.organizationObj;

    if (organizationObj && organizationObj.isPrepaidByOrganization) {
      // do nothing
    } else {
      if (
        this.state.paymentType !== PaymentOptions.PayAtTimeOfService &&
        validateInsuranceForService &&
        showPayment === PAYMENT_BOOLEAN.TRUE
      ) {
        if (
          this.state.paymentType === PaymentOptions.Insurance &&
          selectedClinic.smvs_insurance !== false
        ) {
          const { errors: insuranceValidationError } =
            InsuranceDetailsValidation(
              { ...insuranceInfo, paymentType, insuranceType },
              collectRxDetails, 
            );
          errors = {
            ...errors,
            insuranceDataErrors: { ...insuranceValidationError },
          };
        }

        if (
          paymentType &&
          paymentType === PaymentOptions.NoInsurance &&
          selectedClinic.smvs_no_insurance !== false
        ) {
          const { errors: noInsuranceErrors } =
            this.validateNoInsuranceDetails();

          if (!noInsuranceInfo.noInsuranceAttempted) {
            errors = {
              ...errors,
              ...noInsuranceErrors,
            };
          }
        }
      }
    }

    return { errors };
  };

  validateNoInsuranceDetails = () => {
    const { noInsuranceIdNumber, noInsuranceIdType, noInsuranceState } =
      this.state.noInsuranceInfo;
    const noInsuranceDetailsObj = {
      noInsuranceIdNumber,
      noInsuranceIdType,
      noInsuranceState,
    };
    const { errors } = NoInsuranceDetailsValidation(noInsuranceDetailsObj);
    return {
      errors,
    };
  };

  /**
   * Animation to error
   */
  animateToFormError = () => {
    setTimeout(() => {
      const errorField = document.getElementsByClassName('form-error')[0];

      if (errorField) {
        window.scrollTo({
          behavior: 'smooth',
          left: 0,
          top: errorField.offsetTop - 200,
        });
      }
    });
  };

  /**
   * Clear error for input field with that key
   */
  resetError = (key) => {
    const newError = { ...this.state.errors };
    newError[key] = null;
    return newError;
  };

  resetErrorBulk = (keyList) => {
    const newError = { ...this.state.errors };
    keyList.forEach((key) => {
      newError[key] = null;
    });
    return newError;
  };

  resetCurrentInsuranceErrorBulk = (keyList) => {
    const resetErrors = keyList.reduce((acc, eachKey) => {
      acc[eachKey] = null;
      return acc;
    }, {});

    const currentInsuranceIndex = this.getCurrentInsuranceIndex();
    const currentInsuranceErrors =
      this.state.errors.insuranceDataErrors?.[currentInsuranceIndex] ?? {};

    const { insuranceDataErrors: allInsuranceDataErrors, ...stateErrors } =
      this.state.errors;
    return {
      ...stateErrors,
      insuranceDataErrors: replaceArrayElementByIndex(
        allInsuranceDataErrors,
        this.getCurrentInsuranceIndex(),
        {
          ...currentInsuranceErrors,
          ...resetErrors,
        },
      ),
    };
  };

  isNameOrDobInvalidAfterEligibilityVerification = () => {
    return this.state.errors.insurancePatientVerificationErrors.length > 0;
  };

  isPolicyNumberInvalidAfterEligibilityVerification = () => {
    return (
      this.state.errors.insurancePolicyNumberVerificationErrors.filter(
        (e) => e !== null,
      ).length > 0
    );
  };

  updatePatientRecordIfExists = async () => {
    const { patientInfo } = this.state;
    const { patientRecordInfo: globalPatientRecordInfo, currentPatientIndex } =
      this.props.details;
    const currentPatientRecordInfo =
      globalPatientRecordInfo[currentPatientIndex];
    const isPatientRecordNotVerified =
      !currentPatientRecordInfo.isRecordVerified;

    if (
      this.isNameOrDobInvalidAfterEligibilityVerification() &&
      isPatientRecordNotVerified
    ) {
      try {
        this.setState({
          isPatientRecordVerifying: true,
        });

        if (!this.props.isFollowup) {
          const patientRecordMeta = await verifyPatientRecord(patientInfo);
          const { isExistingPatient, patientId } = patientRecordMeta;
          if (isExistingPatient) {
            //next page logic is handled by merge modal in this case
            this.setState({
              showMergeModal: true,
              patientId,
            });
          } else {
            const updatedPatientRecordInfo = replaceArrayElementByIndex(
              globalPatientRecordInfo,
              currentPatientIndex,
              {
                ...currentPatientRecordInfo,
                isExistingPatient: false,
                patientId: null,
                isRecordVerified: true,
                firstName: '',
                lastName: '',
                middleName: '',
                birthMonth: '',
                birthDay: '',
                birthYear: '',
              },
            );

            this.props.setDetails({
              patientRecordInfo: updatedPatientRecordInfo,
            });
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.setState({
          isPatientRecordVerifying: false,
        });
      }
    }
  };

  isInsuranceVerified = (serviceIndex) => {
    return this.state.insuranceCoverageList.some(
      (coverageList) => coverageList[serviceIndex] === true,
    );
  };

  isInsuranceUnverified = (serviceIndex) => {
    // unverified is different from invalid insurance i.e. the status is false
    // if insurance payment is disabled for the service, then the status is undefined
    return this.state.insuranceCoverageList.some(
      (coverageList) => coverageList[serviceIndex] === null,
    );
  };

  /**
   *
   * @param serviceIndex{number} - the service(1st/2nd) in a multiservice appointment
   * @returns {*}
   */
  getInsuranceDataWithInsuranceAndCoverageTypes = (serviceIndex) => {
    const { insuranceType, insuranceInfo, insuranceCoverageList } = this.state;

    const verifiedPrimaryInsuranceForServiceExists = insuranceCoverageList.some(
      (coverageList) => coverageList[serviceIndex] === true,
    );
    const unverifiedPrimaryInsuranceForServiceExists =
      insuranceCoverageList.some(
        (coverageList) => coverageList[serviceIndex] === null,
      );

    let isPrimaryInsuranceDetermined = false;

    return insuranceType.map((eachInsuranceType, insuranceIndex) => {
      if (!eachInsuranceType) return null;

      let coverageType =
        InsuranceCoverageTypes[
          AvailableInsuranceCoverageIndices.otherInsuranceIndexStart
        ];
      if (
        !isPrimaryInsuranceDetermined &&
        verifiedPrimaryInsuranceForServiceExists &&
        insuranceCoverageList[insuranceIndex][serviceIndex] === true
      ) {
        coverageType =
          InsuranceCoverageTypes[
            AvailableInsuranceCoverageIndices.primaryInsuranceIndex
          ];
        isPrimaryInsuranceDetermined = true;
      } else if (
        !isPrimaryInsuranceDetermined &&
        !verifiedPrimaryInsuranceForServiceExists &&
        unverifiedPrimaryInsuranceForServiceExists &&
        insuranceCoverageList[insuranceIndex][serviceIndex] === null
      ) {
        coverageType =
          InsuranceCoverageTypes[
            AvailableInsuranceCoverageIndices.primaryInsuranceIndex
          ];
        isPrimaryInsuranceDetermined = true;
      } else if (
        !(
          isPrimaryInsuranceDetermined ||
          verifiedPrimaryInsuranceForServiceExists ||
          unverifiedPrimaryInsuranceForServiceExists
        )
      ) {
        coverageType =
          InsuranceCoverageTypes[
            AvailableInsuranceCoverageIndices.primaryInsuranceIndex
          ];
        isPrimaryInsuranceDetermined = true;
      }

      // idx has a range from primaryInsuranceIndex && maxOtherInsuranceIndex
      return {
        ...insuranceInfo[insuranceIndex],
        insuranceType: insuranceType[insuranceIndex],
        insuranceCoverageType: coverageType,
      };
    });
  };

  /**
   * Permissable denote either details verified or for insurances with eligibility not required/disabled.
   * @returns {boolean}
   */
  isAtleastOneInsurancePermissableForAtleastOneService = () => {
    let isInsuranceInfoAvailable = false;

    for (const [serviceIndex, _] of this.props.selectedServices.entries()) {
      isInsuranceInfoAvailable ||=
        this.isInsuranceVerified(serviceIndex) ||
        this.isInsuranceUnverified(serviceIndex);
      if (isInsuranceInfoAvailable) return true;
    }

    return false;
  };

  isDuplicateInsuranceAvailable = () => {
    const { insuranceType, insuranceInfo, isRequiredFormDataFilled } =
      this.state;

    const insuranceWithDuplicateDataExists = insuranceType.some(
      (eachInsurance, insuranceIndex) => {
        return !!(
          insuranceType[insuranceIndex] !== null &&
          isRequiredFormDataFilled[insuranceIndex] &&
          this.findInsuranceWithSameValue(
            insuranceInfo[insuranceIndex],
            insuranceType[insuranceIndex],
            insuranceIndex,
          )
        );
      },
    );

    return insuranceWithDuplicateDataExists;
  };

  handleSubmit = async (isAutoTrigger) => {
    const isFormInvalid = await this.checkFormValidity(isAutoTrigger);
    if (isFormInvalid) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      return;
    }

    const { paymentType, noInsuranceInfo, insuranceInfo } = this.state;
    const {
      selectedServices,
      selectedClinic,
      details: { organizationObj },
    } = this.props;
    const isPaidByOrganization = organizationObj?.isPrepaidByOrganization;

    const enabledPaymentOptions = this.getEnabledPaymentOptions(
      selectedServices,
      selectedClinic,
    );
    const isPaymentEnabled =
      enabledPaymentOptions.insurance ||
      enabledPaymentOptions.noInsurance ||
      enabledPaymentOptions.payAtTimeOfService;
    const paymentTypeValue =
      isPaymentEnabled && !isPaidByOrganization ? paymentType : null;
    const patientInfo = [{ ...this.state.patientInfo }];

    if (
      paymentTypeValue === PaymentOptions.NoInsurance ||
      paymentTypeValue === PaymentOptions.PayAtTimeOfService
    ) {
      this.props.setDetails({
        patientInfo,
        commonAppointmentDetails: [
          {
            paymentType: paymentTypeValue,
            insuranceData: [{ ...noInsuranceInfo, insuranceType: null }],
          },
        ],
        appointmentDetails: selectedServices.map(() => {
          return {
            paymentType: paymentTypeValue,
            insuranceData: [{ ...noInsuranceInfo, insuranceType: null }],
          };
        }),
        phicureResponse: [],
        noInsuranceInfo: [{ ...noInsuranceInfo }],
        pageComplete: {
          ...this.props.pageComplete,
          [CovidPageNames.PaymentInfo]: true,
        },
      });

      this.gotoNextPage();
      return;
    }

    // for insurances
    await Promise.allSettled([
      this.updatePatientRecordIfExists(),
      this.validateAllInsuranceEligibilities(),
    ]);

    if (
      (this.isPatientRecordVerified() || this.props.isFollowup) && // followup doesn't need patient verification
      !this.isInsuranceValidationInProgress() &&
      !this.state.insuranceWithDuplicateDataExists &&
      (this.isAtleastOneInsurancePermissableForAtleastOneService() ||
        this.isNoInsuranceVerificationErrorsAvailable()) &&
      !this.state.isEligibilityApiError &&
      !this.state.isOutOfPocketRequiredForPayment
    ) {
      const updatedInsuranceData = insuranceInfo.map(
        (eachInsuranceInfo, idx) => {
          return {
            ...eachInsuranceInfo,
            insuranceType: this.state.insuranceType[idx],
          };
        },
      );

      this.props.setDetails({
        patientInfo,
        commonAppointmentDetails: [
          {
            consentedBy: this.state.consentedBy,
            paymentType: paymentTypeValue,
            insuranceData: [...updatedInsuranceData],
          },
        ],

        appointmentDetails: selectedServices.map(
          ({ subService }, serviceIndex) => {
            if (
              enabledPaymentOptions.insurance ||
              subService.isInsuranceEnabled
            ) {
              // in multi service insurance data is saved even when only one service has payment type enabled
              return {
                paymentType: paymentTypeValue,
                insuranceData:
                  this.getInsuranceDataWithInsuranceAndCoverageTypes(
                    serviceIndex,
                  ),
              };
            }

            return {
              paymentType: null,
              insuranceData: [{ insuranceType: null }],
            };
          },
        ),
        insuranceCoverageList: [this.state.insuranceCoverageList],
        phicureResponse: [this.state.phicureResponse],
        insuranceInfo: [...updatedInsuranceData],
        pageComplete: {
          ...this.props.pageComplete,
          [CovidPageNames.PaymentInfo]: true,
        },
      });

      this.gotoNextPage();
      return;
    }

    if (
      Math.floor(new Date().getTime() - this.lastEligibilityRequestStartTime) >
      DISABLING_TIMER_VALUE
    ) {
      this.eligibilityRequestCount = 0;
    }

    this.lastEligibilityRequestStartTime = new Date().getTime();

    if (
      ++this.eligibilityRequestCount === INSURANCE_ELIGIBILITY_VALIDATION_COUNT
    ) {
      this.eligibilityRequestCount = 0;
      this.setState({ startEligibilityRequestLimitingTimer: true });
    }

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); // on errors, scroll to the top of the page
  };

  /**
   * Check if form data is valid,
   * If isValid is true, proceeds to the confirm and submit page
   * If isValid is false, errors are set, doesnt route to next page and returns
   */
  checkFormValidity = async (isAutoTrigger) => {
    // check only form errors
    const { paymentType, patientInfo, errors: allErrors } = this.state;

    let errors = { insuranceDataErrors: [] };
    getIterable(this.getTotalNoOfInsurancesAdded()).forEach(
      (_, insuranceIndex) => {
        const { errors: patientAndPaymentDataErrors } =
          this.validatePatientAndPaymentData(
            { ...patientInfo, paymentType },
            insuranceIndex,
          );

        errors = {
          ...allErrors,
          ...patientAndPaymentDataErrors,
          insuranceDataErrors: replaceArrayElementByIndex(
            errors.insuranceDataErrors,
            insuranceIndex,
            patientAndPaymentDataErrors.insuranceDataErrors,
          ),
        };
      },
    );

    const errorList = Object.keys(errors).filter(
      (key) => errors[key] && !this.insuranceErrorKeys.includes(key),
    ); // non-insurance errors e.g. age, dob
    const insuranceErrorList = errors.insuranceDataErrors.map(
      (eachInsuranceDataError) => {
        const insuranceDataError = eachInsuranceDataError || {};
        return Object.keys(insuranceDataError).filter(
          (key) => insuranceDataError[key],
        );
      },
    );

    const insuranceWithDuplicateDataExists =
      paymentType === PaymentOptions.Insurance
        ? this.isDuplicateInsuranceAvailable()
        : false;

    this.setState({
      errors,
      insuranceWithDuplicateDataExists,
    });

    if (
      errorList.length > 0 ||
      insuranceErrorList.flat().length > 0 ||
      insuranceWithDuplicateDataExists
    ) {
      this.props.setSelfAndNextPageIncomplete();
      if (!isAutoTrigger) {
        this.animateToFormError();
      }
      return true;
    }

    return false;
  };

  gotoNextPage = () => {
    this.props.toNextPage();
    this.props.setThisPageCompleteStatus(true);
  };

  /**
   * Update Insurance info.
   * For a key value pair,
   * - Format input if specified
   * - Clear error state for the key
   *
   * @param {string} key
   * @param {string} value
   */
  onPaymentInfoChange = (key, value) => {
    if (key == null || value == null) return;

    if (key === 'memberIDSuffix' && value.length > 2) {
      return;
    }

    const currentInsuranceIndex = this.getCurrentInsuranceIndex();
    const { noInsuranceInfo, insuranceInfo, paymentType } = this.state;
    value = this.formatFormInput(key, value);

    const isInsurancePayment = paymentType === PaymentOptions.Insurance;
    const paymentData = isInsurancePayment
      ? insuranceInfo[currentInsuranceIndex]
      : noInsuranceInfo;
    const insuranceDetails = { ...paymentData, [key]: value };

    let errors = {};

    // TODO - simplify nested if/else logic
    if (isInsurancePayment) {
      if (key === 'company') {
        errors = this.resetCurrentInsuranceErrorBulk([
          'otherInsuranceCompany',
          'insurancePlanType',
          'rxBin',
          'rxPCN',
          'rxGroup',
          'suffix',
          key,
        ]);
      } else if (
        key === 'subscriberRelationship' &&
        value !== SubscriberRelationshipCode.Self
      ) {
        errors = this.resetCurrentInsuranceErrorBulk([
          'cardHolderFirstName',
          key,
          'cardHolderLastName',
        ]);
      } else {
        errors = this.resetCurrentInsuranceErrorBulk([key]);
      }

      errors = {
        ...errors,
        insurancePolicyNumberVerificationErrors: removeElementFromArray(
          currentInsuranceIndex,
          this.state.errors.insurancePolicyNumberVerificationErrors,
          false,
        ),
      };
    } else {
      if (key === 'noInsuranceAttempted' && value === true) {
        const remainingErrors = this.resetErrorBulk(NoInsuranceInfoKeys);
        errors = {
          ...remainingErrors,
        };
      } else {
        errors = { [key]: null };
      }
    }

    const paymentInfoUpdate = isInsurancePayment
      ? {
          insuranceInfo: replaceArrayElementByIndex(
            insuranceInfo,
            currentInsuranceIndex,
            insuranceDetails,
          ),
        }
      : { noInsuranceInfo: insuranceDetails };

    this.setState(
      {
        ...paymentInfoUpdate,
        isOutOfPocketRequiredForPayment: false,
        errors: {
          ...this.state.errors,
          ...errors,
        },
        insuranceCoverageList: replaceArrayElementByIndex(
          this.state.insuranceCoverageList,
          currentInsuranceIndex,
          [],
        ),
        phicureResponse: replaceArrayElementByIndex(
          this.state.phicureResponse,
          currentInsuranceIndex,
          [],
        ),
        isEligibilityApiError: false,
        eligibilityInfoMessageAfterTimer: false,
      },
      () => {
        this.setState({
          isRequiredFormDataFilled: isInsurancePayment
            ? this.getRequiredInsuranceInformationFilledStatus(
                currentInsuranceIndex,
              )
            : [],
          insuranceWithDuplicateDataExists: false,
        });
      },
    );

    this.props.setDetails({
      pageComplete: {
        ...this.props.pageComplete,
        [CovidPageNames.PaymentInfo]: false,
      },
    });
  };

  resetNoInsuranceInfo = () => {
    const emptyNoInsuranceInfo = {};

    NoInsuranceInfoKeys.forEach((key) => {
      if (key === 'noInsuranceState') {
        emptyNoInsuranceInfo[key] = 'WA';
        return;
      }
      emptyNoInsuranceInfo[key] = '';
    });
    return emptyNoInsuranceInfo;
  };

  getPayerForPayerId = (payerlist, companyId) => {
    if (!companyId) return;
    const selectedPayer = payerlist.find(({ guid }) => guid === companyId);
    return selectedPayer;
  };

  getSelectedPayer = (
    insuranceIndex = AvailableInsuranceCoverageIndices.primaryInsuranceIndex,
  ) => {
    return this.getPayerForPayerId(
      this.props.getPayerList(insuranceIndex),
      this.state.insuranceInfo[insuranceIndex]?.company,
    );
  };

  /**
   * Map payer guid to id
   */
  getCompanyIDFromGUID = (id_val, insuranceIndex) => {
    const payerlist = this.props.getPayerList(insuranceIndex);

    const payer = payerlist.find((company) => company.guid === id_val);

    let id = null;

    if (payer) {
      id = payer.id;
    }

    return id;
  };

  getCompanyNameForCompanyId = (companyId, insuranceIndex) => {
    if (!companyId) return '';

    const payerlist = this.props.getPayerList(insuranceIndex);

    const selectedPayer = payerlist.find(({ guid }) => guid === companyId);
    return selectedPayer?.label || '';
  };

  mapAppointmentToInsuranceEligibilityValidationParams = (
    insuranceIndex = AvailableInsuranceCoverageIndices.primaryInsuranceIndex,
  ) => {
    const {
      firstName,
      middleName,
      lastName,
      birthMonth,
      birthDay,
      birthYear,
      homeAddress,
      city,
      state,
      zipCode,
      gender,
    } = this.state.patientInfo;

    const {
      company,
      memberID,
      memberIDPrefix,
      memberIDSuffix,
      subscriberRelationship,
      cardHolderFirstName,
      cardHolderLastName,
    } = this.state.insuranceInfo?.[insuranceIndex] || {};

    const companyID = this.getCompanyIDFromGUID(company, insuranceIndex);
    const genderID = gender === '1' ? 'M' : gender === '2' ? 'F' : 'U';
    let bMonth =
      birthMonth?.toString().length === 1 ? `0${birthMonth}` : birthMonth;
    let bDay = birthDay?.toString().length === 1 ? `0${birthDay}` : birthDay;

    const param = {
      payerEligibilityID: companyID,
      patientLastName: lastName,
      patientMiddleName: middleName,
      patientFirstName: firstName,
      patientAddress: homeAddress,
      patientCity: city,
      patientState: state,
      patientZip: zipCode,
      patientDateOfBirth: `${bMonth}/${bDay}/${birthYear}`,
      patientDateofService: DateTimeUtils.toSlashFormat(Date.now()),
      payerID: companyID,
      // dont allow if memberID is missing
      patientPolicyNumber: memberID
        ? memberIDPrefix +
          memberID +
          (memberIDSuffix === '00' ? '' : memberIDSuffix)
        : '',
      patientGender: genderID,
      insuredRelationship: Object.keys(SubscriberRelationshipCode).find(
        (key) =>
          SubscriberRelationshipCode[key] === parseInt(subscriberRelationship),
      ),
    };

    if (param.insuredRelationship !== 'Self') {
      param['insuredFirstName'] = cardHolderFirstName;
      param['insuredLastName'] = cardHolderLastName;
    }

    return param;
  };

  isMultiServiceAppointment = () => {
    return this.props.selectedServices.length > 1;
  };

  isNoInsuranceVerificationErrorsAvailable = () => {
    return (
      this.state.errors.insurancePolicyNumberVerificationErrors?.length +
        this.state.errors.insurancePatientVerificationErrors?.length <
      1
    );
  };

  areAllInsurancesUnverified = () => {
    if (
      !this.state.insuranceCoverageList ||
      this.state.insuranceCoverageList.length === 0 ||
      this.state.insuranceCoverageList.every((e) => e.length === 0)
    )
      return false; // verification not done yet

    // skips insurances with eligibility checks disabled i.e. coverage type value for such payers are null
    for (let coverageList of this.state.insuranceCoverageList) {
      const [firstServiceCoverage, secondServiceCoverage] = coverageList;
      if (
        isFalseOrUndefined(firstServiceCoverage) &&
        isFalseOrUndefined(secondServiceCoverage)
      )
        return false;
    }

    return true;
  };

  validateInsuranceEligibilityOfAppointment = async (
    insuranceIndex = AvailableInsuranceCoverageIndices.primaryInsuranceIndex,
  ) => {
    let finalResponse = [];
    let eligibilityApiError = false;
    const params =
      this.mapAppointmentToInsuranceEligibilityValidationParams(insuranceIndex);

    const payerlist = this.props.getPayerList(insuranceIndex);
    const companyId = this.state.insuranceInfo[insuranceIndex].company; // NOTE, no need to consider `Other` insurance company as they cannot be verified
    if (!companyId) return '';
    const selectedPayer =
      payerlist.find(({ guid }) => guid === companyId) || {};
    const totalSelectedServices = this.props.selectedServices.length;

    if (
      this.state.phicureResponse[insuranceIndex]?.length > 0 &&
      this.props.pageComplete[CovidPageNames.PaymentInfo]
    ) {
      return {
        isPayerEligibilityEnabled: !!selectedPayer.enableEligibility,
        insuranceEditParam: [],
        phicureResponse: this.state.phicureResponse[insuranceIndex],
        insuranceCoverageList: this.state.insuranceCoverageList[insuranceIndex],
        eligibilityApiError: false,
      };
    }

    if (!selectedPayer.enableEligibility) {
      // billing insurance configuration setting to disable payer wise eligibility
      return {
        isPayerEligibilityEnabled: false,
        insuranceEditParam: [],
        phicureResponse: getIterable(totalSelectedServices, null),
        insuranceCoverageList: getIterable(totalSelectedServices, null),
        eligibilityApiError: false,
      };
    }

    // for services with same service code, 2 separate requests are not sent in case of multi service
    const eligibilityRequestPayloads = this.props.selectedServices.map(
      ({ service, subService }) => {
        if (!subService.isInsuranceEnabled) {
          return null;
        }
        return {
          ...params,
          typeOfServiceCode: service.typeOfServiceCode,
          insuranceOrganizationName: selectedPayer.label || '',
        };
      },
    );

    try {
      finalResponse = await validateInsuranceEligibilityForMultiple(
        eligibilityRequestPayloads,
      );
    } catch (e) {
      eligibilityApiError = true;
      const emptyResponse = {
        isEligible: undefined,
        editInfo: [],
        phicureInsuredInfo: null,
      };
      finalResponse = this.props.selectedServices.map((_) => {
        return { ...emptyResponse };
      });
    }

    const insuranceCoverageList = finalResponse.map((item) => item?.isEligible);

    //get a unique list of editInfo of each response, so that there won't be duplicate values
    const editInfoArray = Array.from(
      new Set(finalResponse.map((item) => item?.editInfo ?? []).flat()),
    );

    const phicureResponse = finalResponse.map((item) => {
      return item?.phicureInsuredInfo
        ? {
            payload: item?.phicureInsuredInfo?.encryptedData,
          }
        : null;
    });

    return {
      isPayerEligibilityEnabled: true,
      insuranceEditParam: editInfoArray,
      phicureResponse,
      insuranceCoverageList,
      eligibilityApiError,
    };
  };

  validateAllInsuranceEligibilities = async () => {
    const isMultiServiceAppointment = this.isMultiServiceAppointment();
    const insuranceIndicesWithInvalidPolicyNumbers = [];
    let invalidInsurancePatientDetails = [];
    const phicureResponse = [];
    const insuranceCoverageList = [];
    let allPayersHaveEligibilityEnabled = true;
    let isOutOfPocketRequiredForPayment = [];
    let eligibilityErrorStatus = [];

    this.setState({ isInsuranceValidationLoading: true });

    for (
      let insuranceIndex =
        AvailableInsuranceCoverageIndices.primaryInsuranceIndex;
      insuranceIndex < this.getTotalNoOfInsurancesAdded();
      insuranceIndex++
    ) {
      const insuranceValidationResults =
        await this.validateInsuranceEligibilityOfAppointment(insuranceIndex);
      const insuranceVerifiedData =
        insuranceValidationResults.insuranceCoverageList.filter((e) => e);

      insuranceCoverageList.push(
        insuranceValidationResults.insuranceCoverageList,
      );
      phicureResponse.push(insuranceValidationResults.phicureResponse);
      if (
        insuranceValidationResults.insuranceEditParam.includes(
          this.validationAlertKeys.policyNumber,
        )
      ) {
        insuranceIndicesWithInvalidPolicyNumbers.push(insuranceIndex);
      }
      if (
        insuranceValidationResults.insuranceEditParam.includes(
          this.validationAlertKeys.patientName,
        )
      ) {
        invalidInsurancePatientDetails.push(
          this.patientErrorDisplayValues[this.validationAlertKeys.patientName],
        );
      }
      if (
        insuranceValidationResults.insuranceEditParam.includes(
          this.validationAlertKeys.dob,
        )
      ) {
        invalidInsurancePatientDetails.push(
          this.patientErrorDisplayValues[this.validationAlertKeys.dob],
        );
      }

      const isInsuranceEligibileToBePrimary = isMultiServiceAppointment
        ? insuranceVerifiedData.length ===
          this.totalNoOfServicesInMultiServiceApt
        : insuranceVerifiedData.length ===
          this.totalNoOfServicesInNonMultiServiceApt;

      allPayersHaveEligibilityEnabled &&=
        insuranceValidationResults.isPayerEligibilityEnabled; // find if atleast one insurance so far has it's eligibility verification disabled

      isOutOfPocketRequiredForPayment.push(
        insuranceValidationResults.insuranceEditParam.length < 1 &&
          insuranceValidationResults.insuranceCoverageList.every(
            (coverage) => !isTrueOrNull(coverage),
          ),
      );

      eligibilityErrorStatus.push(
        insuranceValidationResults.eligibilityApiError,
      );
      if (isInsuranceEligibileToBePrimary) break; // if a primary eligible insurance is found, no need to further process
    }

    const haveAllInsurancesFailed = eligibilityErrorStatus.every(
      (e) => e === true,
    );

    if (haveAllInsurancesFailed) {
      toast.error({
        title: '',
        message: getString('eligibilityAPIErrorMessage'),
      });
    }

    /**
     * @description Due to the upgrade of our React version from 17 to 18, we encountered issues with batch state updates. This resulted in delays in state updates for the following setState calls. To address this issue, we are calling flushSync here.
     *
     * flushSync is utilized to prevent automatic batching introduced in React 18.
     */
    flushSync(() => {
      this.setState({
        isInsuranceValidationLoading: false,
        insuranceCoverageList,
        phicureResponse,
        // ECBYPASS: Temporary change to allow the users to bypass EC
        // Remove the condition to remove bypass
        isOutOfPocketRequiredForPayment: config.allowEligibilityBypass
          ? false
          : isOutOfPocketRequiredForPayment.every((e) => e === true),
        isEligibilityApiError: haveAllInsurancesFailed,
        eligibilityDisabledPayerExists: !allPayersHaveEligibilityEnabled, // payer that does not require eligibility verification (manager app -> billing configuration)
        errors: {
          ...this.state.errors,
          insurancePatientVerificationErrors: Array.from(
            new Set(invalidInsurancePatientDetails),
          ),
          insurancePolicyNumberVerificationErrors: [
            ...insuranceIndicesWithInvalidPolicyNumbers,
          ],
        },
      });
    });

    return false;
  };

  getPatientDetailVerificationErrorMsg = () => {
    if (
      this.state.errors.insurancePatientVerificationErrors.filter((e) => e)
        .length < 1
    )
      return '';

    return getString(
      'patientDetailIncorrect',
      formatMultipleWordsWithAnd(
        unique(this.state.errors.insurancePatientVerificationErrors),
      ).toLowerCase(),
    );
  };

  getPolicyNumberVerificationErrorMsg = () => {
    const invalidatedPayers =
      this.state.errors.insurancePolicyNumberVerificationErrors
        .filter((e) => e !== null)
        .map((insuranceIndex) =>
          this.getCompanyNameForCompanyId(
            this.getInsuranceInfo(insuranceIndex)?.company,
          ),
        );

    return getString(
      'patientPolicyNumberIncorrect',
      formatMultipleWordsWithAnd(unique(invalidatedPayers)),
    );
  };

  getEnabledPaymentOptions = memoize((selectedServices, selectedClinic) => {
    const enabledPaymentOptions = {
      insurance:
        selectedServices.some(
          ({ subService }) => subService.isInsuranceEnabled,
        ) && selectedClinic.insurance !== false,
      noInsurance:
        selectedServices.every(
          ({ subService }) => subService.isNoInsuranceEnabled,
        ) && selectedClinic.noInsurance !== false,
      payAtTimeOfService:
        selectedServices.every(
          ({ service }) => service.isOutOfPocketEnabled, 
        ),
    };

    return enabledPaymentOptions;
  });

  resetCurrentInsuranceInfoAndTypeState = () => {
    const currentInsuranceIndex = this.getCurrentInsuranceIndex();
    this.setState({
      insuranceInfo: replaceArrayElementByIndex(
        this.state.insuranceInfo,
        currentInsuranceIndex,
        this.getInsuranceInfoDefaultValue(),
      ),
      insuranceType: replaceArrayElementByIndex(
        this.state.insuranceType,
        this.getCurrentInsuranceIndex(),
        null,
      ),
      currentInsuranceIndex: currentInsuranceIndex,
      errors: {
        ...this.state.errors,
        insuranceDataErrors: replaceArrayElementByIndex(
          this.state.errors.insuranceDataErrors,
          currentInsuranceIndex,
          null,
        ),
      },
    });
  };

  getInsuranceInfoDefaultValue = () => {
    return {
      id: getRandomNDigitDecimalNumber(this.insuranceIdLength),
      ...InitialInsuranceState,
      insuranceType: null,
    };
  };

  getInitialInsuranceInfoState = (
    numberOfItemsToFill = TotalNoOfInsuranceCoverages,
  ) => {
    return Array.from({ length: numberOfItemsToFill }, () => {
      return this.getInsuranceInfoDefaultValue();
    });
  };

  getInitialInsuranceTypeState = () => {
    return Array.from({ length: TotalNoOfInsuranceCoverages }, () => null);
  };

  getCurrentInsuranceIndex = () => {
    return this.state.currentInsuranceIndex;
  };

  checkInsuranceFormValidity = (insuranceIndex, selectedServices) => {
    const paymentType = this.state.paymentType;
    const insuranceInfo = this.state.insuranceInfo[insuranceIndex];
    const insuranceType = this.state.insuranceType[insuranceIndex];
    const collectRxDetails = checkMultiserviceRxDetails(selectedServices);

    if (!insuranceType) {
      const errorObj = { insuranceType: getString('insuranceTypeRequired') };

      this.setState({
        errors: {
          ...this.state.errors,
          insuranceDataErrors: replaceArrayElementByIndex(
            this.state.errors.insuranceDataErrors,
            insuranceIndex,
            errorObj,
          ),
        },
      });

      return {
        insuranceDataError: { ...errorObj },
        isErrorAvailableInInsuranceData: true,
        errorKeys: ['insuranceType'],
      };
    }

    const { errors: insuranceValidationError } = InsuranceDetailsValidation(
      {
        ...insuranceInfo,
        paymentType: paymentType,
        insuranceType: insuranceType,
      },
      collectRxDetails, 
    );

    const errors = Object.keys(insuranceValidationError).filter(
      (key) => insuranceValidationError[key],
    );

    this.setState({
      errors: {
        ...this.state.errors,
        insuranceDataErrors: replaceArrayElementByIndex(
          this.state.errors.insuranceDataErrors,
          insuranceIndex,
          insuranceValidationError,
        ),
      },
    });

    return {
      insuranceDataError: insuranceValidationError,
      isErrorAvailableInInsuranceData: errors.length > 0,
      errorKeys: errors,
    };
  };

  isErrorAvailableInInsuranceData = (insuranceCoverageTypeIndex) => {
    const allErrorStatesOfInsuranceIndex =
      this.state.errors.insuranceDataErrors?.[insuranceCoverageTypeIndex] || {};
    return (
      Object.keys(allErrorStatesOfInsuranceIndex).filter(
        (eachKey) => allErrorStatesOfInsuranceIndex[eachKey],
      ).length > 0
    );
  };

  isPaymentTypeInsuranceAdded = () => {
    if (this.state.paymentType !== PaymentOptions.Insurance) return true; // skip check if insurance is not the payment type

    if (
      !this.getInsuranceType(
        AvailableInsuranceCoverageIndices.primaryInsuranceIndex,
      )
    ) {
      // return false when primary insurance is not added

      return false;
    }

    return true;
  };

  canSubmitDataForConfirmation = () => {
    const { patientInfo } = this.state;
    const isPatientDataPresent = patientInfo.firstName && patientInfo.lastName; // since birthDate are dropdown values they must always be present, skipping check
    if (!isPatientDataPresent) return false;

    let canProceed = !this.state.startEligibilityRequestLimitingTimer;
    if (
      this.state.paymentType === PaymentOptions.NoInsurance ||
      this.state.paymentType === PaymentOptions.PayAtTimeOfService
    ) {
      return true;
    }

    for (
      let insuranceIndex =
        AvailableInsuranceCoverageIndices.primaryInsuranceIndex;
      insuranceIndex < this.getTotalNoOfInsurancesAdded();
      insuranceIndex++
    ) {
      canProceed &&=
        !this.isErrorAvailableInInsuranceData(insuranceIndex) &&
        this.state.isRequiredFormDataFilled[insuranceIndex] &&
        !this.state.isInsuranceValidationLoading;

      if (!canProceed) return false;
    }

    return canProceed;
  };

  getRequiredFormDataFilledInformation = (
    paymentType,
    insuranceInfo,
    insuranceType,
  ) => {
    const isInsurancePayment = paymentType === PaymentOptions.Insurance;
    if (!isInsurancePayment) return [];

    return insuranceType.map((eachInsuranceType, insuranceIndex) => {
      return this.getRequiredInsuranceInformationFilledStatus(insuranceIndex);
    });
  };

  getInsuranceType = (insuranceIndex) => {
    return this.state.insuranceType[insuranceIndex];
  };

  getInsuranceInfo = (insuranceIndex) => {
    return this.state.insuranceInfo[insuranceIndex];
  };

  /**
   * Assume slot to be empty is an insurance type has not been selected
   */
  findFirstEmptyInsuranceSlot = (startPosition = 0) => {
    return this.state.insuranceType.indexOf(null, startPosition);
  };

  onRemoveInsuranceDetail = (insuranceIndex) => {
    const allErrors = this.state.errors;

    this.setState({
      errors: {
        ...allErrors,
        insuranceDataErrors: removeArrayElementByIndex(
          allErrors.insuranceDataErrors,
          insuranceIndex,
          true,
          null,
        ),
        insurancePolicyNumberVerificationErrors: removeArrayElementByIndex(
          this.state.errors.insurancePolicyNumberVerificationErrors,
          insuranceIndex,
          false,
        ),
      },
      insuranceType: removeArrayElementByIndex(
        this.state.insuranceType,
        insuranceIndex,
        true,
        null,
      ),
      insuranceInfo: removeArrayElementByIndex(
        this.state.insuranceInfo,
        insuranceIndex,
        true,
        this.getInsuranceInfoDefaultValue(),
      ),
      insuranceCoverageList: removeArrayElementByIndex(
        this.state.insuranceCoverageList,
        insuranceIndex,
        false,
      ),
      phicureResponse: removeArrayElementByIndex(
        this.state.phicureResponse,
        insuranceIndex,
        false,
      ),
      totalNoOfInsurances: this.getTotalNoOfInsurancesAdded() - 1,
      insuranceWithDuplicateDataExists: false,
      isEligibilityApiError: false,
      eligibilityInfoMessageAfterTimer: false,
    });
  };

  canAddMoreInsurances = () => {
    return (
      this.state.paymentType === PaymentOptions.Insurance &&
      this.getTotalNoOfInsurancesAdded() < MaxNoOfInsuranceCoverages
    );
  };

  isPayloadChanged = (
    insuranceIndex = AvailableInsuranceCoverageIndices.primaryInsuranceIndex,
  ) => {
    return !(
      JSON.stringify(
        this.mapAppointmentToInsuranceEligibilityValidationParams(
          insuranceIndex,
        ),
      ) === JSON.stringify(this.state.previousValidationPayload)
    );
  };

  getTotalNoOfInsurancesAdded = () => {
    return this.state.totalNoOfInsurances;
  };

  onAddInsuranceData = () => {
    this.setState({
      totalNoOfInsurances: this.getTotalNoOfInsurancesAdded() + 1,
    });
  };

  isPatientInfoUpdated = () => {
    const { firstName, middleName, lastName, birthDay, birthMonth, birthYear } =
      this.state.patientInfo;
    const { patientRecordInfo: globalPatientRecordInfo, currentPatientIndex } =
      this.props.details;
    const currentPatientRecordInfo =
      globalPatientRecordInfo[currentPatientIndex];
    if (
      compareStrings(currentPatientRecordInfo.firstName, firstName) &&
      compareStrings(currentPatientRecordInfo.middleName, middleName) &&
      compareStrings(currentPatientRecordInfo.lastName, lastName) &&
      currentPatientRecordInfo.birthYear === birthYear &&
      currentPatientRecordInfo.birthMonth === birthMonth &&
      currentPatientRecordInfo.birthDay === birthDay
    ) {
      const updatedPatientRecordInfo = replaceArrayElementByIndex(
        globalPatientRecordInfo,
        currentPatientIndex,
        {
          ...globalPatientRecordInfo[currentPatientIndex],
          isRecordVerified: true,
        },
      );

      this.props.setDetails({
        patientRecordInfo: updatedPatientRecordInfo,
      });

      return false;
    } else {
      const updatedPatientRecordInfo = replaceArrayElementByIndex(
        globalPatientRecordInfo,
        currentPatientIndex,
        {
          ...globalPatientRecordInfo[currentPatientIndex],
          isRecordVerified: false,
        },
      );
      this.props.setDetails({
        patientRecordInfo: updatedPatientRecordInfo,
      });

      return true;
    }
  };

  saveAndMergePatientData = (isMergeAllowed) => {
    const { patientInfo } = this.state;

    const patientRecord = {
      firstName: patientInfo.firstName,
      lastName: patientInfo.lastName,
      middleName: patientInfo.middleName,
      birthMonth: patientInfo.birthMonth,
      birthDay: patientInfo.birthDay,
      birthYear: patientInfo.birthYear,
      mobileNo: patientInfo.mobileNo,
      email: patientInfo.email,
    };

    const { patientRecordInfo: globalPatientRecordInfo, currentPatientIndex } =
      this.props.details;

    if (isMergeAllowed) {
      const updatedPatientRecordInfo = replaceArrayElementByIndex(
        globalPatientRecordInfo,
        currentPatientIndex,
        {
          isExistingPatient: true,
          patientId: this.state.patientId,
          isRecordVerified: true,
          ...patientRecord,
        },
      );
      this.props.setDetails({
        patientRecordInfo: updatedPatientRecordInfo,
      });
    } else {
      const updatedPatientRecordInfo = replaceArrayElementByIndex(
        globalPatientRecordInfo,
        currentPatientIndex,
        {
          ...this.props.details.patientRecordInfo[currentPatientIndex],
          isExistingPatient: false,
          patientId: null,
          isRecordVerified: true,
        },
      );
      this.props.setDetails({
        patientRecordInfo: updatedPatientRecordInfo,
      });
    }

    this.setState({ showMergeModal: false });
  };

  onPatientInfoChange = (key, value, event) => {
    if (this.state.checkingEligibility) return;

    if (key == null || value == null) return;

    const nameKeys = ['firstName', 'lastName', 'middleName'];
    const isNameBeingChanged = nameKeys.includes(key);

    if (
      isNameBeingChanged &&
      value !== '' &&
      !value.match(regexPattern.nameField)
    )
      return;

    const { patientInfo } = this.state;

    value = this.formatFormInput(key, value);

    // Case: Character Limit:
    // Do not process further if new value equals to old value
    if (this.state.patientInfo[key] === value) return;

    let errors = this.resetError(key);

    const newChange = { [key]: value };

    // on date change check patient is over 18 years
    const oldIsUnderNineteen = this.state.isUnderNineteen;

    const insurancePatientVerificationErrors = [
      ...this.state.errors.insurancePatientVerificationErrors,
    ];
    this.setState(
      {
        patientInfo: { ...patientInfo, ...newChange },
        insuranceCoverageList: [],
        phicureResponse: [],
        errors: {
          ...errors,
          insuranceDataErrors: [],
          insurancePolicyNumberVerificationErrors: [],
          insurancePatientVerificationErrors: getIterable(
            unique(insurancePatientVerificationErrors).length,
            null,
          ),
        },
      },
      () => {
        const {
          patientInfo: { birthDay, birthMonth, birthYear },
        } = this.state;

        const { selectedServices } = this.props;

        if (birthDay && birthMonth && birthYear) {
          const birthDate = `${birthMonth}-${birthDay}-${birthYear}`;
          const isUnderMinimumAge = selectedServices.some(({ subService }) =>
            DateTimeUtils.isUnderMinimumAge(
              birthDate,
              subService.minAgeRequirement,
            ),
          );
          const isAboveMaximumAge = selectedServices.some(({ subService }) =>
            DateTimeUtils.isAboveMaximumAge(
              birthDate,
              subService.maxAgeRequirement,
            ),
          );
          const isUnderEighteen = DateTimeUtils.isUnderEighteen(birthDate);
          const isUnderNineteen = DateTimeUtils.isUnderNineteen(birthDate);
          // if dob is changed to be above/below 19, re-select this
          const consentedBy =
            oldIsUnderNineteen === isUnderNineteen
              ? this.state.consentedBy
              : isUnderNineteen
                ? ConsentedBy.GUARDIAN
                : ConsentedBy.PATIENT;
          this.setState({
            isUnderMinimumAge,
            isAboveMaximumAge,
            isUnderEighteen,
            isUnderNineteen,
            consentedBy,
          });
        }
        const { pageComplete } = this.props;
        if (pageComplete[CovidPageNames.PaymentInfo]) {
          this.checkFormValidity(true);
        }

        // Check if saved record matches new record
        const hasPatientRecordUpdated = this.isPatientInfoUpdated();

        if (hasPatientRecordUpdated) {
          this.props.setDetails({
            pageComplete: {
              ...pageComplete,
              [CovidPageNames.PaymentInfo]: false,
            },
          });
        }
      },
    );
  };

  isOtherInsuranceLabel = (selectedLabel) => {
    if (!selectedLabel) return false;
    return (
      selectedLabel.toLowerCase() === 'other' ||
      selectedLabel.toLowerCase() === 'others'
    );
  };

  isOtherInsuranceNameFilled = (selectedCompanyLabel, insuranceIndex) => {
    return this.isOtherInsuranceLabel(selectedCompanyLabel)
      ? !!this.state.insuranceInfo[insuranceIndex]?.otherInsuranceCompany
      : true;
  };

  getSingleInsuranceInformationFilledStatus = (
    insuranceIndex,
    insuranceInfo = {},
    insuranceType = '',
  ) => {
    if (!insuranceType) return null;

    const {
      company,
      memberID,
      subscriberRelationship,
      cardHolderFirstName,
      cardHolderLastName,
    } = insuranceInfo;
    const insuredRelationship = this.allSubscriberRelationshipKeys.find(
      (key) =>
        SubscriberRelationshipCode[key] === parseInt(subscriberRelationship),
    );

    const validFilledStatusOfCardHolderName =
      insuredRelationship === SubscriberRelationshipKeys.self
        ? true
        : cardHolderFirstName && cardHolderLastName;
    const validFilledStatusOfInsurancePlanType =
      insuranceType === InsuranceType.Commercial
        ? !!insuranceInfo?.insurancePlanType
        : true;

    return !!(
      insuranceType &&
      company &&
      memberID &&
      subscriberRelationship &&
      this.isOtherInsuranceNameFilled(
        this.getSelectedPayer(insuranceIndex)?.label,
        insuranceIndex,
      ) &&
      validFilledStatusOfCardHolderName &&
      validFilledStatusOfInsurancePlanType
    );
  };

  getRequiredInsuranceInformationFilledStatus = (insuranceIndex) => {
    return replaceArrayElementByIndex(
      this.state.isRequiredFormDataFilled,
      insuranceIndex,
      this.getSingleInsuranceInformationFilledStatus(
        insuranceIndex,
        this.state.insuranceInfo[insuranceIndex],
        this.state.insuranceType[insuranceIndex],
      ),
    );
  };

  disableEligibilityRequestTimeout = () => {
    this.setState({
      startEligibilityRequestLimitingTimer: false,
      eligibilityInfoMessageAfterTimer: true,
    });
  };

  findInsuranceWithSameValue = (insurance, insuranceType, insuranceIndex) => {
    return this.state.insuranceInfo.find((compareWithInsurance, idx) => {
      return (
        idx !== insuranceIndex &&
        this.state.insuranceType[insuranceIndex] === insuranceType &&
        compareWithInsurance.cardHolderFirstName ===
          insurance.cardHolderFirstName &&
        compareWithInsurance.cardHolderMiddleName ===
          insurance.cardHolderMiddleName &&
        compareWithInsurance.cardHolderLastName ===
          insurance.cardHolderLastName &&
        compareWithInsurance.company === insurance.company &&
        compareWithInsurance.groupNumber === insurance.groupNumber &&
        compareWithInsurance.insurancePlanType ===
          insurance.insurancePlanType &&
        compareWithInsurance.medicareID === insurance.medicareID &&
        compareWithInsurance.medicareIssuer === insurance.medicareIssuer &&
        compareWithInsurance.memberID === insurance.memberID &&
        compareWithInsurance.memberIDPrefix === insurance.memberIDPrefix &&
        compareWithInsurance.memberIDSuffix === insurance.memberIDSuffix &&
        compareWithInsurance.subscriberRelationship ===
          insurance.subscriberRelationship &&
        compareWithInsurance.rxBin === insurance.rxBin &&
        compareWithInsurance.rxGroup === insurance.rxGroup &&
        compareWithInsurance.otherInsuranceCompany ===
          insurance.otherInsuranceCompany &&
        compareWithInsurance.rxPCN === insurance.rxPCN
      );
    });
  };

  render() {
    const { getPayerList, details, primarySelection, selectedClinic } =
      this.props;

    if (!details.firstPageLoaded) return null;

    const { organizationObj, availableInsuranceTypes } = details;

    const { showPayment } = this.props.getAppSettings();

    const {
      paymentType,
      patientInfo,
      errors,
      noInsuranceInfo,
      isPatientRecordVerifying,
      insuranceType: allInsuranceType,
      insuranceInfo: allInsuranceInfo,
    } = this.state;

    const currentInsuranceIndex = this.getCurrentInsuranceIndex();
    const insuranceType = allInsuranceType[currentInsuranceIndex];

    const { selectedServices } = this.props;

    // Conditions for showing payment options on radio button
    const enabledPaymentOptions = this.getEnabledPaymentOptions(
      selectedServices,
      selectedClinic,
    );

    const isInsurancePayment =
      paymentType &&
      paymentType === PaymentOptions.Insurance &&
      enabledPaymentOptions.insurance;

    const isNoInsurancePayment =
      paymentType &&
      paymentType === PaymentOptions.NoInsurance &&
      enabledPaymentOptions.noInsurance;

    const isPayAtTimeOfServicePayment =
      paymentType &&
      paymentType === PaymentOptions.PayAtTimeOfService &&
      enabledPaymentOptions.payAtTimeOfService;

    const showPaymentContainer =
      enabledPaymentOptions.insurance ||
      enabledPaymentOptions.noInsurance ||
      enabledPaymentOptions.payAtTimeOfService;

    const errorSet = {};

    return (
      <>
        <BackToPage
          text={getString('backToPatientInfoPage')}
          onClick={this.props.goBack}
        />
        <ServicesInfoContainer
          confirmEditService={this.props.confirmEditService}
          disableEdit={this.props.disableEdit}
        />
        <div className="patient-info">
          {this.isNameOrDobInvalidAfterEligibilityVerification() && (
            <>
              <AlertMessage
                type="danger"
                message={this.getPatientDetailVerificationErrorMsg()}
                className="my-5x"
                isVisible={this.getPatientDetailVerificationErrorMsg()}
                dataqa="insurance-patient-verification-errors"
              />

              <AlertMessage
                type="danger"
                message={primarySelection.subService.minAgeMessage}
                className="my-5x"
                isVisible={
                  this.state.isUnderMinimumAge || this.state.isAboveMaximumAge
                }
                dataqa="is-under-min-age-alert"
              />

              {!this.props.isFollowup && (
                <PatientBasicInfo
                  isInsuranceValidationLoading={
                    this.state.isInsuranceValidationLoading
                  }
                  patientInfo={patientInfo}
                  onChangeInput={this.onPatientInfoChange}
                  isIISConsentEnabled={
                    this.props.getAppSettings().isIISConsentEnabled
                  }
                  validateEmailonBlur={ignoreCallback}
                  validateConfirmEmailonBlur={ignoreCallback}
                  disabledFieldStatus={this.props.disabledFieldStatus}
                  displayOnlyNameAndDob={true}
                  errors={errors}
                />
              )}
            </>
          )}

          {showPaymentContainer && showPayment === PAYMENT_BOOLEAN.TRUE && (
            <>
              <section className="form-payment-type section__margin">
                {isInsurancePayment &&
                  this.state.insuranceWithDuplicateDataExists && (
                    <AlertMessage
                      type="danger"
                      message={getString('duplicateInsuranceInformation')}
                      className="mb-3x"
                      isVisible={true}
                      dataqa="duplicate-info-alert"
                    />
                  )}

                {this.state.startEligibilityRequestLimitingTimer && (
                  <CountDownTimer
                    className={'mb-3x'}
                    countDownTime={Math.floor(DISABLING_TIMER_VALUE / 1000)}
                    timeoutCallback={this.disableEligibilityRequestTimeout}
                  />
                )}

                <AlertMessage
                  type="info"
                  className={'col-12 mt-5x p-3x mb-3x'}
                  message={
                    <span>
                      {getString(
                        'timeUpInsuranceMessage',
                        <span>
                          <b>{getString('continue').toLowerCase()}</b>
                        </span>,
                      )}
                    </span>
                  }
                  isVisible={this.state.eligibilityInfoMessageAfterTimer}
                  dataqa="is-eligible-insurance-message-after-timer"
                />

                {this.isPolicyNumberInvalidAfterEligibilityVerification() && (
                  <AlertMessage
                    type="danger"
                    message={this.getPolicyNumberVerificationErrorMsg()}
                    className="my-5x"
                    isVisible={true}
                    dataqa="insurance-patient-verification-errors"
                  />
                )}
                {this.state.isOutOfPocketRequiredForPayment &&
                  !this.state.isEligibilityApiError &&
                  this.isNoInsuranceVerificationErrorsAvailable() && (
                    <AlertMessage
                      type="danger"
                      message={
                        <span>
                          {getString(
                            'isNotEligibleInsuranceMessage',
                            <b>{getString('payAtTimeOfService')}</b>,
                          )}
                        </span>
                      }
                      className="my-5x"
                      isVisible={true}
                      dataqa="insurance-patient-verification-errors"
                    />
                  )}
                <h2>{getString('paymentType')}</h2>
                <div className="patient-form-group border border-radius">
                  {organizationObj?.isPrepaidByOrganization ? (
                    <AlertMessage
                      type="info"
                      message={getString(
                        'prepaidByOrganization',
                        organizationObj?.name,
                      )}
                      isVisible={true}
                      dataqa="prepaid-by-org-alert"
                    />
                  ) : (
                    <>
                      <PaymentType
                        onPaymentTypeChange={this.onPaymentTypeChange}
                        value={paymentType}
                        isInsuranceEnabled={enabledPaymentOptions?.insurance}
                        noInsuranceEnabled={enabledPaymentOptions?.noInsurance}
                        payAtTimeOfServiceEnabled={
                          enabledPaymentOptions?.payAtTimeOfService
                        }
                        insuranceType={insuranceType}
                      />

                      {isInsurancePayment && (
                        <>
                          <AlertMessage
                            type="info"
                            message={getString(
                              'addMedicalInsuranceInformation',
                            )}
                            isVisible={true}
                            dataqa="add-medical-information"
                            className="my-1x mb-6x"
                          />
                          <InsurancesList
                            errors={errors}
                            getPayerlist={getPayerList}
                            patientInfo={patientInfo}
                            paymentType={paymentType}
                            setFieldError={this.setFieldError}
                            isInsurancePayment={isInsurancePayment}
                            onRemoveInsuranceDetail={
                              this.onRemoveInsuranceDetail
                            }
                            totalNoOfInsurances={this.getTotalNoOfInsurancesAdded()}
                            onPaymentInfoChange={this.onPaymentInfoChange}
                            onInsuranceTypeChange={this.onInsuranceTypeChange}
                            getSelectedPayer={this.getSelectedPayer}
                            insuranceInfo={allInsuranceInfo}
                            insuranceType={allInsuranceType}
                            isInsuranceValidationLoading={
                              this.state.isInsuranceValidationLoading
                            }
                            availableInsuranceTypes={availableInsuranceTypes}
                            setCurrentInsuranceIndex={
                              this.setCurrentInsuranceIndex
                            }
                            currentInsuranceIndex={currentInsuranceIndex}
                            selectedServices={selectedServices}
                          />
                        </>
                      )}

                      {isInsurancePayment && (
                        <>
                          <hr className="my-5x" />
                          <div className={styles.addInsuranceInformation}>
                            {getString('addInsuranceInformation')}
                          </div>
                          {this.canAddMoreInsurances() && (
                            <Button
                              isEnabled={true}
                              label={getString('addInsurance')}
                              onClick={this.onAddInsuranceData}
                              className={cls(
                                styles.addInsuranceBtn,
                                'pl-2x pr-2x btn btn-primary',
                              )}
                            />
                          )}
                        </>
                      )}

                      {isNoInsurancePayment && (
                        <NoInsuranceForm
                          onChange={this.onPaymentInfoChange}
                          noInsuranceInfo={noInsuranceInfo}
                          errors={errors}
                        />
                      )}
                    </>
                  )}

                  {isPayAtTimeOfServicePayment && (
                    <PayAtTimeOfService selections={selectedServices} />
                  )}
                </div>
              </section>
            </>
          )}

          <FormErrors errors={errorSet} />

          {!isPayAtTimeOfServicePayment && (
            <AlertMessage
              dataqa="details-confirm-alert"
              type="info"
              message={getString('detailsConfirmAlert')}
              className="my-5x"
              isVisible={true}
            />
          )}

          <div className="row">
            <div className="col-12-sm order-2-sm">
              <LinkToPage
                onClick={this.handleSubmit}
                label={
                  this.state.isInsuranceValidationLoading ? (
                    <div className={styles.continueToConfirmationPage}>
                      {getString('insuranceEligibilityLoading')}
                      <ActivityIndicator
                        marginBottom={false}
                        loaderClass={styles.activityLoaderClass}
                        loaderLoopClass={styles.activityLoaderLoopClass}
                        loaderContainerClass={styles.activityLoaderContainer}
                      />
                    </div>
                  ) : (
                    getString('continue')
                  )
                }
                title={getString('continueTitle')}
                enabled={this.canSubmitDataForConfirmation()}
                loading={
                  this.props.details.loading ||
                  this.state.isInsuranceValidationLoading ||
                  isPatientRecordVerifying
                }
                dataqa="go-to-clinic-timing"
              />
            </div>
          </div>
          {this.state.showMergeModal && (
            <VerifyPatientRecord
              saveAndMergePatientData={this.saveAndMergePatientData}
            />
          )}
        </div>
      </>
    );
  }
}

function withAddressSuggestionProps(Component) {
  return function WrappedComponent(props) {
    const { suggest } = useAddressSearch();
    return <Component {...props} suggest={suggest} />;
  };
}

export default withAddressSuggestionProps(PaymentInfo);
