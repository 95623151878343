import React from 'react';
import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer';

import {
  printFontFamily,
  printFontBoldCDN,
  printFontRegularCDN,
} from 'constants/dme';
import {
  iconCheckbokCheckedBlack,
  iconCheckbokUncheckedBlack,
} from 'assets/images';
import { getString } from 'util/lang';
import { getTrailingCommaValue } from 'util/string';
import { useStyles } from './TermsAndConditionsPdf.styles';
import {
  BeneficiaryRelation,
  BeneficiaryRelationValue,
} from 'constants/productOrder';
import { contactMasking, zipCodeMasking } from 'util/contactFormat';

const TermsAndConditionsPdfView = ({ appData = {}, signedDocumentDetail }) => {
  const styles = useStyles;
  Font.register({
    family: printFontFamily,
    fonts: [
      {
        src: printFontRegularCDN,
        fontWeight: 400,
      },
      {
        src: printFontBoldCDN,
        fontWeight: 700,
      },
    ],
  });

  const {
    organizationContactPhone,
    organizationName,
    billingAddress,
    billingCity,
    billingZipCode,
    billingState,
  } = appData;

  const formattedOrganizationContact = contactMasking(organizationContactPhone);
  const formattedBillingZipCode = zipCodeMasking(billingZipCode);
  const isDocumentSigned =
    signedDocumentDetail.tncFullName || signedDocumentDetail.tncSignature;

  const renderSignatureView = () => {
    signedDocumentDetail.tncFullName ? (
      <Text style={styles.signName}>{signedDocumentDetail.tncFullName}</Text>
    ) : (
      <Image src={signedDocumentDetail.tncSignature} style={styles.signImage} />
    );
  };

  return (
    <Document>
      <Page style={styles.page}>
        <Text style={styles.title}>
          {getString('dme.documentation.termsAndConditions.title')}
        </Text>

        {getString('dme.documentation.termsAndConditions.terms').map(
          (section, index) => (
            <View key={index} style={styles.textWrap}>
              <Text style={styles.text}>
                {getString(
                  `dme.documentation.termsAndConditions.terms.${index}.title`,
                )}
              </Text>
              <Text style={styles.subText}>
                {getString(
                  `dme.documentation.termsAndConditions.terms.${index}.content`,
                  {
                    pharmacy: organizationName,
                    contact: formattedOrganizationContact,
                  },
                )}{' '}
                <Text style={styles.subTextBold}>
                  {getString(
                    `dme.documentation.termsAndConditions.terms.${index}.meta`,
                  )}
                </Text>
              </Text>
            </View>
          ),
        )}

        <View style={styles.agreeWrap}>
          <View style={styles.img}>
            {isDocumentSigned ? (
              <Image src={iconCheckbokCheckedBlack} alt="checkbox-checked" />
            ) : (
              <Image src={iconCheckbokUncheckedBlack} alt="checkbox-checked" />
            )}
          </View>

          <Text style={styles.agreeText}>
            {getString(
              'dme.documentation.termsAndConditions.agreeTermsAndConditions',
            )}
          </Text>
        </View>

        <View style={styles.signWrap}>
          <View style={styles.signLeft}>
            <Text style={styles.signTitle}>
              {getString(
                'dme.documentation.termsAndConditions.beneficiary.signature',
              )}
            </Text>
            <>{renderSignatureView()}</>
            <Image
              src={signedDocumentDetail.tncSignature}
              style={styles.signImage}
            />
          </View>
          <View style={styles.signRight}>
            <Text style={styles.signTitle}>
              {getString(
                'dme.documentation.termsAndConditions.beneficiary.date',
              )}
            </Text>
            <Text style={styles.signName}>{signedDocumentDetail.tncDate}</Text>
          </View>
        </View>

        <View style={styles.addressWrap}>
          <View style={styles.addressLeft}>
            <Text style={styles.signTitle}>
              {getString(
                'dme.documentation.termsAndConditions.beneficiary.relationship',
              )}
            </Text>
            <Text style={styles.signName}>
              {
                BeneficiaryRelation.find(
                  (relation) =>
                    relation.value === signedDocumentDetail.tncRelation,
                ).label
              }
            </Text>
          </View>
          {signedDocumentDetail?.tncRelation !==
            BeneficiaryRelationValue.SELF && (
            <View style={styles.addressRight}>
              <Text style={styles.signTitle}>
                {getString(
                  'dme.documentation.termsAndConditions.beneficiary.address',
                )}
              </Text>
              <Text style={styles.signName}>
                {signedDocumentDetail.tncAddress}
              </Text>
            </View>
          )}
        </View>
        {signedDocumentDetail?.tncRelation !==
          BeneficiaryRelationValue.SELF && (
          <View style={styles.reasonWrap}>
            <Text style={styles.signTitle}>
              {getString(
                'dme.documentation.termsAndConditions.beneficiary.reason',
              )}
            </Text>
            <Text style={styles.reason}>
              {' '}
              {signedDocumentDetail.tncReasonUnableToSign}
            </Text>
          </View>
        )}

        <View style={styles.footerWrap}>
          <Text style={styles.footerTextBold}>
            {getTrailingCommaValue(organizationName)}
          </Text>
          <Text style={styles.footerText}>
            {getTrailingCommaValue(billingAddress)}
            {getTrailingCommaValue(billingCity)}
            {getTrailingCommaValue(billingState)}
            {getTrailingCommaValue(formattedBillingZipCode)}
            {formattedOrganizationContact}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default TermsAndConditionsPdfView;
