import { AnyObjectSchema, ValidationError } from 'yup';

/**
 *
 * @param formValues The form Data which is to be validated
 * @param YupSchema The schema against which form data is to be validated
 * @returns
 */
export const validateFormData = <
  T extends Record<string, string | number | boolean>,
>(
  formValues: T,
  YupSchema: () => Record<keyof T, AnyObjectSchema>,
) => {
  let errors: Partial<Record<keyof T, string | null>> = {};
  let isValid = true;

  (Object.keys(formValues) as Array<keyof T>).forEach((key) => {
    const value = formValues[key];

    try {
      YupSchema()[key].validateSync(value); //schema validation with individual form data
      errors[key] = null;
    } catch (error) {
      isValid = false;
      errors[key] = (error as Error).message;
    }
  });

  return {
    errors,
    isValid,
  };
};

/**
 * Validates form data against a Yup schema.
 *
 * @template T - The type of the form values, which should be an object with string, number, or boolean values.
 * @param {T} formValues - The form values to validate.
 * @param {AnyObjectSchema} YupSchema - The Yup schema to validate against.
 * @returns {{ errors: Partial<Record<keyof T, string>>, isValid: boolean }} An object containing validation errors and a boolean indicating if the form is valid.
 *
 * @example
 * const formValues = { name: 'John', age: 30 };
 * const schema = Yup.object().shape({
 *   name: Yup.string().required(),
 *   age: Yup.number().required(),
 * });
 * const result = validateFormDataWithSchema(formValues, schema);
 * console.log(result.errors); // { name: 'Name is required', age: 'Age is required' }
 * console.log(result.isValid); // false
 */
export const validateFormDataWithSchema = <
  T extends Record<string, string | number | boolean>,
>(
  formValues: T,
  YupSchema: AnyObjectSchema,
) => {
  let errors: Partial<Record<keyof T, string>> = {};
  let isValid = true;

  try {
    YupSchema.validateSync(formValues, { abortEarly: false }); //schema validation with whole form data
  } catch (error) {
    isValid = false;

    if (error instanceof ValidationError) {
      error.inner.forEach((err) => {
        if (err.path) {
          errors[err.path as keyof T] = err.message;
        }
      });
    }
  }

  return {
    errors,
    isValid,
  };
};
