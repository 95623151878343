import { Document, Page, StyleSheet, View, Font } from '@react-pdf/renderer';

import DmeHeaderPdfView from '../presentation/common/DmeHeader/DmeHeaderPdfView';
import PharmacyInfoPdfView from '../presentation/common/PharmaInfo/PharmaInfoPdfView';
import PatientInfoPdfView from '../presentation/common/PatientInfo/PatientInfoPdfView';
import ItemTablePdfView from '../presentation/DeliveryTicket/ItemTable/ItemTablePdfView';
import DeliveryInfoPdfView from '../presentation/common/DeliveryInfo/DeliveryInfoPdfView';
import HomeAssessmentAndSafetyPdfView from '../presentation/DeliveryTicket/HomeAssessmentAndSafety/HomeAssessmentAndSafetyPdfView';

import { getString } from 'util/lang';
import { joinValues } from 'util/string';
import { contactMasking } from 'util/contactFormat';
import {
  printFontBoldCDN,
  printFontFamily,
  printFontItalicCDN,
} from 'constants/dme';
import ProductSettingAndTestingFormPdfView from '../presentation/DeliveryTicket/ProductSettingAndTestingForm/ProductSettingAndTestingFormPdfView';

const LABEL_PREFIX = 'dme';

const DeliveryAssessmentPdfView = ({
  logoUrl,
  organizationName,
  organizationContactPhone,
  billingAddress,
  billingCity,
  billingState,
  billingZipCode,
  deliveryTicketResponse,
}) => {
  Font.register({
    family: printFontFamily,
    fonts: [
      {
        src: printFontBoldCDN,
        fontWeight: 400,
      },
      {
        src: printFontBoldCDN,
        fontWeight: 700,
      },
      {
        src: printFontItalicCDN,
        fontWeight: 400,
      },
    ],
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <DmeHeaderPdfView
          logoUrl={logoUrl}
          organizationName={organizationName}
          title={getString(`${LABEL_PREFIX}.deliveryAssessment.title`)}
        />
        <View style={{ marginTop: 8 }} />
        <PharmacyInfoPdfView
          name={organizationName}
          address={joinValues(
            [billingAddress, billingCity, billingState, billingZipCode],
            ', ',
          )}
          contact={contactMasking(organizationContactPhone)}
        />
        <View style={styles.row}>
          <PatientInfoPdfView
            name={deliveryTicketResponse?.patientData?.name}
            dob={deliveryTicketResponse?.patientData?.dob}
            phone={deliveryTicketResponse?.patientData?.phone}
          />
          <DeliveryInfoPdfView data={deliveryTicketResponse?.deliveryInfo} />
        </View>
        <ItemTablePdfView tableData={deliveryTicketResponse?.orderItemData} />
        <ProductSettingAndTestingFormPdfView
          settings={deliveryTicketResponse?.settingAndTestingData?.settings}
          tests={deliveryTicketResponse?.settingAndTestingData?.tests}
        />
        <HomeAssessmentAndSafetyPdfView
          homeAssessmentData={deliveryTicketResponse?.homeAssessmentData}
        />
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    display: 'block',
    height: '100%',
    width: '100%',
    padding: '20px',
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '16px',
  },
});

export default DeliveryAssessmentPdfView;
